import {store} from 'MainApp';
import {getAppNameFromPath, getOriginalSelectedRecord} from 'util/B2Utils';

import {
    PROCESS_B2_EVENT,
    CLOSE_B2_MESSAGE,
    ON_APP_TOGGLE_DRAWER,
    LOAD_B2_APP,
    SHOW_RECORD_DETAILS,
    ON_CHANGE_APP_PAGE,
    ON_RECORD_SELECTED,
    ON_TOGGLE_BOOKMARK_RECORD,
    ON_RECORD_FIELD_VALUE_CHANGED,
    ON_RECORD_ATTRIBUTE_UPDATED,
    SAVE_RECORD,
    ON_INSERT_MAIN_RECORD,
    EXECUTE_QUERY,
    ON_APP_SEARCH_FIELD_VALUE_CHANGED,
    ON_APP_SEARCH_PERFORMED,
    ON_LIST_SORT,
    PROCESS_B2_SECURITY_EVENT,
    SIGNIN_USER,
    SIGNUP_USER,
    SET_INIT_URL,
    PROCESS_B2_DOWNLOAD_ATTACHMENT_EVENT
} from '../constants/B2ActionTypes';

import {
    PROCESS_SAFEE_DOWNLOAD_EWD_EVENT,
    DOWNLOAD_EWD,
    DOWNLOAD_UNLIMITED_EWD,
    RESEND_ACTIVE_LINK,
    PROCESS_SAFEE_DOWNLOAD_FORM_EVENT,
    DOWNLOAD_FORM
} from '../constants/SafeeActionTypes';

export const processB2Event = (eventRequest) => {
    const eventResponse = interceptB2Event(eventRequest);

    if (eventResponse){
        return eventResponse;
    }

    return {
        type: PROCESS_B2_EVENT,
        eventRequest: eventRequest
    }
}

export const processB2SecurityEvent = (eventRequest) => {
    const eventResponse = interceptB2SecurityEvent(eventRequest);

    if (eventResponse){
        return eventResponse;
    }

    return {
        type: PROCESS_B2_SECURITY_EVENT,
        eventRequest: eventRequest
    }
}

export const processDownloadAttachmentB2Event = (eventRequest)=> {
    return {
        type: PROCESS_B2_DOWNLOAD_ATTACHMENT_EVENT,
        eventRequest: eventRequest
    }
}

export const processDownloadEWDSafeeEvent = (eventRequest)=> {
    const eventResponse = interceptSafeeEvent(eventRequest);

    if (eventResponse){
        return eventResponse;
    }

    return {
        type: PROCESS_SAFEE_DOWNLOAD_EWD_EVENT,
        eventRequest: eventRequest
    }
}

export const processDownloadFormSafeeEvent = (eventRequest)=> {
    const eventResponse = interceptSafeeEvent(eventRequest);

    if (eventResponse){
        return eventResponse;
    }

    return {
        type: PROCESS_SAFEE_DOWNLOAD_FORM_EVENT,
        eventRequest: eventRequest
    }
}

export const sagaActionResponseCallBack = (eventResponse) => {
    //console.log("inside sagaActionResponseCallBack");
    //sconsole.log(eventResponse);
    return eventResponse;
};

// interceptor function is designed to create a shortcut if data is available and need not to be requested from server
// return either null (no interception) or eventResponse as if it is coming from server
function interceptB2Event(eventRequest){
    switch (eventRequest.type) {
        case LOAD_B2_APP:{
//            if (!eventRequest.eventDetails.forcefulNavigation){
//                if(store.getState().b2State.app){
//                    const appFromPath = getAppNameFromPath(store.getState().router.location.pathname);
//
//                    if (store.getState().b2State.app.attributes &&
//                        store.getState().b2State.app.attributes.appName &&
//                        store.getState().b2State.app.attributes.appName.value &&
//                        appFromPath){
//                        if (store.getState().b2State.app.attributes.appName.value.toUpperCase() === appFromPath.toUpperCase()){
//                            return {
//                                "appName":appFromPath,
//                                "type":LOAD_B2_APP,
//                                "actionResponse":{
//                                }
//                            }
//                        }
//                    }
//                }
//                else if (store.getState().b2State.b2Message){
//                    const appFromPath = getAppNameFromPath(store.getState().router.location.pathname);
//                    return {
//                        "appName":appFromPath,
//                        "type":LOAD_B2_APP,
//                        "actionResponse":{
//                        }
//                    }
//                }
//            }
            break;
        }
        case CLOSE_B2_MESSAGE:{
            return {
                "type":CLOSE_B2_MESSAGE,
                "actionResponse":{}
            }
        }
        case SHOW_RECORD_DETAILS:{
            if(store.getState().b2State.beans[eventRequest.beanId]){
                if (store.getState().b2State.dataSource[store.getState().b2State.beans[eventRequest.beanId].datasource]){
                    if (store.getState().b2State.dataSource[store.getState().b2State.beans[eventRequest.beanId].datasource].selectedRecord){
                        if (store.getState().b2State.dataSource[store.getState().b2State.beans[eventRequest.beanId].datasource].selectedRecord.id===eventRequest.recordId
                        ){
                            return {
                                "appName": store.getState().b2State.app.appName,
                                "type": SHOW_RECORD_DETAILS,
                                "actionResponse":{}
                            }
                        }
                    }
                }
            }
            break;
        }
        case ON_RECORD_SELECTED:{
            if(store.getState().b2State.beans[eventRequest.beanId]){
                if (store.getState().b2State.dataSource[store.getState().b2State.beans[eventRequest.beanId].datasource]){
                    if (store.getState().b2State.dataSource[store.getState().b2State.beans[eventRequest.beanId].datasource].selectedRecord){
                        if (store.getState().b2State.dataSource[store.getState().b2State.beans[eventRequest.beanId].datasource].selectedRecord.id===eventRequest.recordId
                        ){
                            return {
                                "appName": store.getState().b2State.app.appName,
                                "type": ON_RECORD_SELECTED,
                                "actionResponse":{}
                            }
                        }
                    }
                }
            }
            break;
        }
        case ON_CHANGE_APP_PAGE:{
            // Important Note: If we shortcut this action, onBlur action of text field overlaps and causes issues
           /* if(store.getState().b2State.app){
                if (store.getState().b2State.beans[store.getState().b2State.app.appBody.id].currentPage){
                    if (store.getState().b2State.beans[store.getState().b2State.app.appBody.id].currentPage === eventRequest.eventDetails.page){
                        return {
                            "type":ON_CHANGE_APP_PAGE,
                            "actionResponse":{
                            }
                        }
                    }else if (  eventRequest.eventDetails.page === 'list' &&
                                typeof(store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified) !== 'undefined' &&
                                store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified==="true" &&
                                !eventRequest.eventDetails.confirmation){

                         return {
                             "type":ON_CHANGE_APP_PAGE,
                             "actionResponse":{
                                 actionPendingConfirmation: eventRequest
                             },
                             "errors":[
                                 {
                                     "code": "500045",
                                     "confirmationType": "Cancel_Discard and Continue",
                                     "display":"popup",
                                     "message":"There are changes that are not yet saved, do you want to discard those changes and continue?",
                                     "type":"Warning"
                                 }
                             ]
                         }
                    }
                }
//                  else if (  typeof(store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified) !== 'undefined' &&
//                            store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified==="true"){
//                    return {
//                        "type":CONFIRM_DISCARD_RECORD,
//                        "actionResponse":{
//                            discardPendingAction: eventRequest
//                        }
//                    }
//                }
            }*/
            break;
        }
        case ON_INSERT_MAIN_RECORD:{
            // Important Note: If we shortcut this action, onBlur action of text field overlaps and causes issues
            /*if (    store.getState().b2State.app &&
                    typeof(store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified) !== 'undefined' &&
                    store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified==="true"){

                return {
                     "type":ON_INSERT_MAIN_RECORD,
                     "actionResponse":{
                         actionPendingConfirmation: eventRequest
                     },
                     "errors":[
                         {
                             "code": "500045",
                             "confirmationType": "Cancel_Discard and Continue",
                             "display":"popup",
                             "message":"There are changes that are not yet saved, do you want to discard those changes and continue?",
                             "type":"Warning"
                         }
                     ]
                 }
            }*/
            break;
        }
        case ON_APP_TOGGLE_DRAWER:{
            return {
                "type":ON_APP_TOGGLE_DRAWER,
                "actionResponse":{
                }
            }
        }
        case ON_RECORD_FIELD_VALUE_CHANGED:{
            return {
                "type":ON_RECORD_FIELD_VALUE_CHANGED,
                "actionResponse":{
                    datasource: eventRequest.eventDetails.datasource,
                    attribute: eventRequest.eventDetails.attribute,
                    isPhantom: eventRequest.eventDetails.isPhantom,
                    value: eventRequest.eventDetails.value
                }
            }
        }
        case ON_APP_SEARCH_FIELD_VALUE_CHANGED: {
            return {
                "type":ON_APP_SEARCH_FIELD_VALUE_CHANGED,
                "actionResponse":{
                    value: eventRequest.eventDetails.searchValue
                }
            }
        }
        case ON_RECORD_ATTRIBUTE_UPDATED:{
            if (!eventRequest.eventDetails.isPhantom){
                let originalSelectedRecord = getOriginalSelectedRecord(eventRequest.eventDetails.datasourceId);
                if (eventRequest.eventDetails.value){
                    if (originalSelectedRecord.attributes[eventRequest.eventDetails.attribute].value === eventRequest.eventDetails.value){
                        return {
                             "type":ON_RECORD_ATTRIBUTE_UPDATED,
                             "actionResponse":{
                             }
                         }
                    }
                }else if (eventRequest.eventDetails.lookupEntityId){
                    // interception happens in the component itself
                    // added this "if" for clarity
                }
            }
            break;
        }
        case SAVE_RECORD:{
            /*if(store.getState().b2State.app){
                if (typeof(store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified) === 'undefined' ||
                    store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified === 'false'){
                    return {
                         "type":SAVE_RECORD,
                         "actionResponse":{
                         }
                     }
                }
            }*/
            break;
        }
        case ON_TOGGLE_BOOKMARK_RECORD:{
            // Important Note: If we shortcut this action, onBlur action of text field overlaps and causes issues
            /*
            if (    typeof(store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified) !== 'undefined' &&
                    store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified==="true" &&
                    !eventRequest.eventDetails.confirmation){
                return {
                    "type":ON_TOGGLE_BOOKMARK_RECORD,
                    "actionResponse":{
                        actionPendingConfirmation: eventRequest
                    },
                    "errors":[
                        {
                            "code": "500044",
                            "confirmationType": "Cancel_Save and Continue",
                            "display":"popup",
                            "message":"This action requires that the record is saved first, record will be saved automatically?",
                            "type":"Warning"
                        }
                    ]
                }
            }*/
            break;
        }
        case EXECUTE_QUERY:{
            // Important Note: If we shortcut this action, onBlur action of text field overlaps and causes issues
            /*if (    typeof(store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified) !== 'undefined' &&
                    store.getState().b2State.beans[store.getState().b2State.app.appBody.id].modified==="true"&&
                    !eventRequest.eventDetails.confirmation){
                return {
                    "type":EXECUTE_QUERY,
                    "actionResponse":{
                        actionPendingConfirmation: eventRequest
                    },
                    "errors":[
                        {
                            "code": "500045",
                            "confirmationType": "Cancel_Discard and Continue",
                            "display":"popup",
                            "message":"There are changes that are not yet saved, do you want to discard those changes and continue?",
                            "type":"Warning"
                        }
                    ]
                }
            }*/
            break;
        }
        case ON_APP_SEARCH_PERFORMED: {
            if (    typeof(eventRequest.eventDetails.searchValue) === 'undefined' ||
                    eventRequest.eventDetails.searchValue === null ||
                    eventRequest.eventDetails.searchValue === '' ) {
                    return {
                        "type":ON_APP_SEARCH_PERFORMED,
                        "actionResponse":{
                    }
                }
            }
            break;
        }
        case ON_LIST_SORT: {
            // TODO: check if datasource is modified and shortcut error
            break;
        }
        default: {}
    }
    return null;
}

// interceptor function is designed to create a shortcut if data is available and need not to be requested from server
// return either null (no interception) or eventResponse as if it is coming from server
function interceptB2SecurityEvent(eventRequest){
    switch (eventRequest.type){
        case SET_INIT_URL:{
            return {
                "type":SET_INIT_URL,
                "actionResponse":{
                    initURL: eventRequest.eventDetails.initURL
                }
            }
        }
        case CLOSE_B2_MESSAGE:{
            return {
                "type":CLOSE_B2_MESSAGE,
                "actionResponse":{}
            }
        }
        case SIGNIN_USER:{
            if (!eventRequest.eventDetails.loginName){
                return {
                    "type":SIGNIN_USER,
                    "actionResponse":{},
                    "errors": [
                        {
                            "code":500019,
                            "display": "popup",
                            "message": "Username can not be empty",
                            "type": "Error"
                        }
                    ]
                }
            } else if (!eventRequest.eventDetails.password){
                return {
                    "type":SIGNIN_USER,
                    "actionResponse":{},
                    "errors": [
                        {
                            "code":500020,
                            "display": "popup",
                            "message": "Password can not be empty",
                            "type": "Error"
                        }
                    ]
                }
            }
            break;
        }
        case SIGNUP_USER:{
            if (!eventRequest.eventDetails.loginName || eventRequest.eventDetails.loginName === 'demo@example.com'){
                return {
                    "type":SIGNUP_USER,
                    "actionResponse":{},
                    "errors": [
                        {
                            "code":500019,
                            "display": "popup",
                            "message": "Username can not be empty",
                            "type": "Error"
                        }
                    ]
                }
            } else if (!eventRequest.eventDetails.password){
                return {
                    "type":SIGNUP_USER,
                    "actionResponse":{},
                    "errors": [
                        {
                            "code":500020,
                            "display": "popup",
                            "message": "Password can not be empty",
                            "type": "Error"
                        }
                    ]
                }
            }else if (!eventRequest.eventDetails.mobileNumber){
                 return {
                     "type":SIGNUP_USER,
                     "actionResponse":{},
                     "errors": [
                         {
                             "code":500112,
                             "display": "popup",
                             "message": "Mobile number can not be empty",
                             "type": "Error"
                         }
                     ]
                 }
            }else {
                var re = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
                if (!re.test(eventRequest.eventDetails.mobileNumber)){
                    return {
                         "type":SIGNUP_USER,
                         "actionResponse":{},
                         "errors": [
                             {
                                 "code":500113,
                                 "display": "popup",
                                 "message": "Invalid mobile number",
                                 "type": "Error"
                             }
                         ]
                     }
                }
             }
             break;
        }
    }
}

// interceptor function is designed to create a shortcut if data is available and need not to be requested from server
// return either null (no interception) or eventResponse as if it is coming from server
function interceptSafeeEvent(eventRequest){
    switch (eventRequest.type){
        case DOWNLOAD_EWD:{
        }
        case DOWNLOAD_UNLIMITED_EWD:{

            if (eventRequest.eventDetails.format != null && eventRequest.eventDetails.format.toLowerCase() === "pdf"){
                if (!eventRequest.eventDetails.fromDate){
                    return {
                        "type": eventRequest.type,
                        "actionResponse":{},
                        "errors": [
                            {
                                "code": "",
                                "display": "popup",
                                "message": "From date must be provided",
                                "type": "Error"
                            }
                        ]
                    }
                }


            }else  if (!eventRequest.eventDetails.fromDate || !eventRequest.eventDetails.toDate){
                return {
                    "type": eventRequest.type,
                    "actionResponse":{},
                    "errors": [
                        {
                            "code": "",
                            "display": "popup",
                            "message": "From and to dates must be provided",
                            "type": "Error"
                        }
                    ]
                }
            }

            let fromDate = new Date(eventRequest.eventDetails.fromDate);
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            fromDate.setSeconds(0);

            let currentDate = new Date();
            currentDate.setHours(0);
            currentDate.setMinutes(0);
            currentDate.setSeconds(0);

            if (fromDate > currentDate){
                return {
                    "type": eventRequest.type,
                    "actionResponse":{},
                    "errors": [
                        {
                            "code": "",
                            "display": "popup",
                            "message": "From date should not be in the future",
                            "type": "Error"
                        }
                    ]
                }
            }else if (eventRequest.eventDetails.toDate){
                let toDate = new Date(eventRequest.eventDetails.toDate);
                toDate.setHours(0);
                toDate.setMinutes(0);
                toDate.setSeconds(0);

                if (fromDate> toDate){
                    return {
                        "type": eventRequest.type,
                        "actionResponse":{},
                        "errors": [
                            {
                                 "code": "",
                                 "display": "popup",
                                 "message": "From date should not be after to date",
                                 "type": "Error"
                            }
                        ]
                    }
                }

                if (eventRequest.eventDetails.format && eventRequest.eventDetails.format.toLowerCase() === 'pdf'){
                    if (eventRequest.type === DOWNLOAD_EWD){
                        // To calculate the time difference of two dates
                        let Difference_In_Time = toDate.getTime() - fromDate.getTime();

                        // To calculate the no. of days between two dates
                        let Difference_In_Days = Math.abs(Math.floor(Difference_In_Time / (1000 * 3600 * 24)));

                        if (Difference_In_Days > 27){
                            return {
                                "type": DOWNLOAD_EWD,
                                "actionResponse":{},
                                "errors": [
                                    {
                                         "code": "",
                                         "display": "popup",
                                         "message": "Dates should not be more than 28 days apart for PDF to generate. If you need longer periods, please contact Safee support team by emailing EWD.Support@safee.com.au or calling 0478900144. Alternatively, you may download data in CSV format.",
                                         "type": "Error"
                                    }
                                ]
                            }
                        }
                    }else  if (eventRequest.type === DOWNLOAD_UNLIMITED_EWD){

                        // To calculate the time difference of two dates
                        let Difference_In_Time = toDate.getTime() - fromDate.getTime();

                        // To calculate the no. of days between two dates
                        let Difference_In_Days = Math.abs(Math.floor(Difference_In_Time / (1000 * 3600 * 24)));

                        if (Difference_In_Days > 179){
                            return {
                                "type": DOWNLOAD_EWD,
                                "actionResponse":{},
                                "errors": [
                                    {
                                     "code": "",
                                     "display": "popup",
                                     "message": "Dates should not be more than 180 days apart",
                                     "type": "Error"
                                    }
                                ]
                            }
                        }
                    }
                }
            }
            break;
        }
        case DOWNLOAD_FORM:{
            // any interception or validation can happen here. At the point of writing this code, there was no validation the the placeholder was left
            break;
        }
    }
}