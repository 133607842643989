import {
    PROCESS_B2_EVENT,
    LOAD_B2_APP,
    ON_APP_TOGGLE_DRAWER,
    CLOSE_B2_MESSAGE,
    ON_TABLE_CHANGE_PAGE,
    ON_TABLE_CHANGE_RECORDS_PER_PAGE,
    SHOW_RECORD_DETAILS,
    ON_CHANGE_APP_PAGE,
    ON_RECORD_SELECTED,
    ON_RECORDS_CHECKED,
    ON_RECORDS_UNCHECKED,
    ON_TOGGLE_BOOKMARK_RECORD,
    ON_RECORD_FIELD_VALUE_CHANGED,
    ON_RECORD_ATTRIBUTE_UPDATED,
    SAVE_RECORD,
    EXECUTE_QUERY,
    ON_SELECT_OPENED,
    ON_LOOKUP_OPENED,
    ON_TAB_CHANGED,
    ON_DEL_UNDEL_TABLE_CHECKED_RECORDS,
    ON_APP_SEARCH_FIELD_VALUE_CHANGED,
    ON_TOGGLE_TABLE_FILTER,
    ON_QBE_SEARCH_PERFORMED,
    ON_LIST_SORT,
    PROCESS_B2_SECURITY_EVENT,
    SIGNUP_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    TOKEN_KEY,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD,
    DOWNLOAD_ATTACHMENT,
    PROCESS_B2_DOWNLOAD_ATTACHMENT_EVENT,
    ACTIVATE_ACCOUNT,
    LONG_OPERATION
} from 'constants/B2ActionTypes';

import {
    DOWNLOAD_EWD,
    DOWNLOAD_UNLIMITED_EWD,
    PROCESS_SAFEE_DOWNLOAD_EWD_EVENT,
    RESEND_ACTIVE_LINK
} from 'constants/SafeeActionTypes';

const B2_INIT_STATE = {
    app: null,
    beans: null,
    datasources: null,
    lookups: null,
    queries: null,
    menuActions: null,
    toolbarActions: null,
    dashboards: null,
    systemMenu: null,
    defaultApp: null,
    accessibleApps: null,
    user: null,
    groups: null,
    b2Message: null,
    userToken: localStorage.getItem(TOKEN_KEY),
    actionPendingConfirmation: null,
    appLoader: false,
    queryLoader: false,
    loginLoader: false,
    searchValue: '',
    showConfirmSaveMessage: false,
    showConfirmDiscardMessage: false,
    drawerState: false,
    redirectLink:null,
    longOperation: false,
    longOperationText: ''
}

export default (state = B2_INIT_STATE, action) => {

    switch (action.type) {
        case PROCESS_B2_DOWNLOAD_ATTACHMENT_EVENT:{
            return {...state}
        }
        case PROCESS_SAFEE_DOWNLOAD_EWD_EVENT:{
            return {...state}
        }
        case PROCESS_B2_EVENT:{
            switch (action.eventRequest.type){
                case LOAD_B2_APP: {
                    return {
                        ...state,
                        b2Message: null,
                        appLoader: true
                    }
                }
                case EXECUTE_QUERY: {
                    return {
                        ...state,
                        queryLoader: true
                    }
                }
                default: {}
            }
            return {...state}
        }
        case PROCESS_B2_SECURITY_EVENT:{
            switch (action.eventRequest.type){
                case ACTIVATE_ACCOUNT:{
                }
                case RESET_PASSWORD:{
                }
                case RESET_PASSWORD_REQUEST:{
                }
                case SIGNIN_USER:{
                }
                case SIGNUP_USER:{
                    return {
                        ...state,
                        loginLoader: true
                    }
                }
                default: {}
            }

            return {...state}
        }
        case LONG_OPERATION: {
            let newState = {
                ...state,
                longOperation: action.longOperation,
                longOperationText: action.longOperationText
            };

            return newState;
        }
        case ACTIVATE_ACCOUNT:{
//            let newState = generateNewState(state, action);
//             return newState;

            let b2Message = null;
            if (action.errors){
                if (action.errors.length === 1){
                    let combinedMessage = '';
                    if (action.errors[0].code){
                        combinedMessage += action.errors[0].code + ": ";
                    }
                    combinedMessage += action.errors[0].message;
                    b2Message = {
                        display: action.errors[0].display,
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.errors[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.errors.forEach(function (error) {
                        if (error.code){
                            combinedMessage += error.code + ": ";
                        }
                        combinedMessage += error.message + "\n";
                    });

                    b2Message = {
                        display: action.errors[0].display,
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }
            }

            if (action.infos) {
                if (action.infos.length === 1){
                    let combinedMessage = '';
                    if (action.infos[0].code){
                        combinedMessage += action.infos[0].code + ": ";
                    }
                    combinedMessage += action.infos[0].message;
                    b2Message = {
                        display: action.infos[0].display,
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.infos[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.infos.forEach(function (info) {
                        if (info.code){
                            combinedMessage += info.code + ": ";
                        }
                        combinedMessage += info.message + "\n";
                    });

                    b2Message = {
                        display: action.infos[0].display,
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }

            }else if (action.warnings) {
                if (action.warnings.length === 1){
                    let combinedMessage = '';
                    if (action.warnings[0].code){
                        combinedMessage += action.warnings[0].code + ": ";
                    }
                    combinedMessage += action.warnings[0].message;
                    b2Message = {
                        display: action.warnings[0].display,
                        type: "warning",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.warnings[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.warnings.forEach(function (warning) {
                        if (warning.code){
                            combinedMessage += warning.code + ": ";
                        }
                        combinedMessage += warning.message + "\n";
                    });

                    b2Message = {
                        display: action.warnings[0].display,
                        type: "warning",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }
            }

            let systemMenu = null;
            let accessibleApps = null;
            let dashboards = null;
            let user = null;
            let groups = null;
            let defaultApp = null;

            if (action.actionResponse && action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.systemMenu)!=='undefined' && action.actionResponse.userProfile.systemMenu !== null && action.actionResponse.userProfile.systemMenu.length >0){
                systemMenu = action.actionResponse.userProfile.systemMenu
            }

            if (action.actionResponse && action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.accessibleApps)!=='undefined' && action.actionResponse.userProfile.accessibleApps !== null && action.actionResponse.userProfile.accessibleApps.length >0){
                accessibleApps = action.actionResponse.userProfile.accessibleApps
            }

            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.dashboards)!='undefined' && action.actionResponse.userProfile.dashboards !== null && action.actionResponse.userProfile.dashboards.length >0){
                dashboards = action.actionResponse.userProfile.dashboards
            }

            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.user)!='undefined' && action.actionResponse.userProfile.user !== null){
                user = action.actionResponse.userProfile.user
            }

            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.groups)!='undefined' && action.actionResponse.userProfile.groups !== null){
                groups = action.actionResponse.userProfile.groups
            }


            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.defaultApp)!='undefined' && action.actionResponse.userProfile.defaultApp !== null){
                defaultApp = action.actionResponse.userProfile.defaultApp
            }

            return {
                ...state,
                b2Message: b2Message,
                userToken: action[TOKEN_KEY],
                user: user,
                groups: groups,
                defaultApp: defaultApp,
                systemMenu: systemMenu,
                dashboards: dashboards,
                accessibleApps: accessibleApps,
                loginLoader: false
            }
        }
        case RESET_PASSWORD:{
            if (action.errors){
                let b2Message = null;
                if (action.errors.length === 1){
                    let combinedMessage = '';
                    if (action.errors[0].code){
                        combinedMessage += action.errors[0].code + ": ";
                    }
                    combinedMessage += action.errors[0].message;
                    b2Message = {
                        display: action.errors[0].display,
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.errors[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.errors.forEach(function (error) {
                        if (error.code){
                            combinedMessage += error.code + ": ";
                        }
                        combinedMessage += error.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }

                return {
                    ...state,
                    b2Message: b2Message,
                    userToken: null,
                    loginLoader: false
                }
            }
            else if (action.infos) {
                let b2Message = null;
                if (action.infos.length === 1){
                    let combinedMessage = '';
                    if (action.infos[0].code){
                        combinedMessage += action.infos[0].code + ": ";
                    }
                    combinedMessage += action.infos[0].message;
                    b2Message = {
                        display: action.infos[0].display,
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.infos[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.infos.forEach(function (info) {
                        if (info.code){
                            combinedMessage += info.code + ": ";
                        }
                        combinedMessage += info.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }

                return {
                    ...state,
                    b2Message: b2Message,
                    userToken: null,
                    loginLoader: false
                }
            }else if (action.warnings) {
                 let b2Message = null;
                 if (action.warnings.length === 1){
                     let combinedMessage = '';
                     if (action.warnings[0].code){
                         combinedMessage += action.warnings[0].code + ": ";
                     }
                     combinedMessage += action.warnings[0].message;
                     b2Message = {
                         display: action.warnings[0].display,
                         type: "info",
                         btnText: "Close",
                         confirmBtnBsStyle: "default",
                         title: "System Message",
                         code: action.warnings[0].code,
                         message: combinedMessage
                     }
                 }else{
                     let combinedMessage = '';
                     //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                     // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                     action.warnings.forEach(function (info) {
                         if (info.code){
                             combinedMessage += info.code + ": ";
                         }
                         combinedMessage += info.message + "\n";
                     });

                     b2Message = {
                         display: 'popup',
                         type: "warning",
                         btnText: "Close",
                         confirmBtnBsStyle: "default",
                         title: "System Message",
                         message: combinedMessage
                     }
                 }

                 return {
                     ...state,
                     b2Message: b2Message,
                     userToken: null,
                     loginLoader: false
                 }
             }
        }
        case RESET_PASSWORD_REQUEST:{
            if (action.errors){
                let b2Message = null;
                if (action.errors.length === 1){
                    let combinedMessage = '';
                    if (action.errors[0].code){
                        combinedMessage += action.errors[0].code + ": ";
                    }
                    combinedMessage += action.errors[0].message;
                    b2Message = {
                        display: action.errors[0].display,
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.errors[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.errors.forEach(function (error) {
                        if (error.code){
                            combinedMessage += error.code + ": ";
                        }
                        combinedMessage += error.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }

                return {
                    ...state,
                    b2Message: b2Message,
                    userToken: null,
                    loginLoader: false
                }
            }
            else if (action.infos && action.infos.length > 0){
                let b2Message = null;
                if (action.infos.length === 1){
                    let combinedMessage = '';
                    if (action.infos[0].code){
                        combinedMessage += action.infos[0].code + ": ";
                    }
                    combinedMessage += action.infos[0].message;
                    b2Message = {
                        display: action.infos[0].display,
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.infos[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.infos.forEach(function (info) {
                        if (info.code){
                            combinedMessage += info.code + ": ";
                        }
                        combinedMessage += info.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }

                return {
                    ...state,
                    b2Message: b2Message,
                    userToken: null,
                    loginLoader: false
                }
            }
            else if (action.warnings && action.warnings.length > 0){
                let b2Message = null;
                if (action.warnings.length === 1){
                    let combinedMessage = '';
                    if (action.warnings[0].code){
                        combinedMessage += action.warnings[0].code + ": ";
                    }
                    combinedMessage += action.warnings[0].message;
                    b2Message = {
                        display: action.warnings[0].display,
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.warnings[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.warnings.forEach(function (info) {
                        if (info.code){
                            combinedMessage += info.code + ": ";
                        }
                        combinedMessage += info.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "info",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }

                return {
                    ...state,
                    b2Message: b2Message,
                    userToken: null,
                    loginLoader: false
                }
            }
        }
        case SIGNUP_USER:{
            // same as SIGNIN_USER
        }
        case SIGNIN_USER:{
            if (action.errors){
                let b2Message = null;
                if (action.errors.length === 1){
                    let combinedMessage = '';
                    if (action.errors[0].code){
                        combinedMessage += action.errors[0].code + ": ";
                    }
                    combinedMessage += action.errors[0].message;
                    b2Message = {
                        display: action.errors[0].display,
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.errors[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.errors.forEach(function (error) {
                        if (error.code){
                            combinedMessage += error.code + ": ";
                        }
                        combinedMessage += error.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }

                return {
                    ...state,
                    b2Message: b2Message,
                    userToken: null,
                    loginLoader: false
                }
            }

            let systemMenu = null;
            let accessibleApps = null;
            let dashboards = null;
            let user = null;
            let groups = null;
            let defaultApp = null;

            if (action.actionResponse && action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.systemMenu)!=='undefined' && action.actionResponse.userProfile.systemMenu !== null && action.actionResponse.userProfile.systemMenu.length >0){
                systemMenu = action.actionResponse.userProfile.systemMenu
            }

            if (action.actionResponse && action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.accessibleApps)!=='undefined' && action.actionResponse.userProfile.accessibleApps !== null && action.actionResponse.userProfile.accessibleApps.length >0){
                accessibleApps = action.actionResponse.userProfile.accessibleApps
            }

            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.dashboards)!='undefined' && action.actionResponse.userProfile.dashboards !== null && action.actionResponse.userProfile.dashboards.length >0){
                dashboards = action.actionResponse.userProfile.dashboards
            }

            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.user)!='undefined' && action.actionResponse.userProfile.user !== null){
                user = action.actionResponse.userProfile.user
            }

            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.groups)!='undefined' && action.actionResponse.userProfile.groups !== null){
                groups = action.actionResponse.userProfile.groups
            }

            if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.defaultApp)!='undefined' && action.actionResponse.userProfile.defaultApp !== null){
                defaultApp = action.actionResponse.userProfile.defaultApp
            }

            return {
                ...state,
                b2Message: null,
                userToken: action[TOKEN_KEY],
                user: user,
                groups: groups,
                defaultApp: defaultApp,
                systemMenu: systemMenu,
                dashboards: dashboards,
                accessibleApps: accessibleApps,
                loginLoader: false
            }
        }
        case SIGNOUT_USER: {
            let b2Message = null;

            if (action.errors){
                if (action.errors.length === 1){
                    let combinedMessage = '';
                    if (action.errors[0].code){
                        combinedMessage += action.errors[0].code + ": ";
                    }
                    combinedMessage += action.errors[0].message;
                    b2Message = {
                        display: action.errors[0].display,
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.errors[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.errors.forEach(function (error) {
                        if (error.code){
                            combinedMessage += error.code + ": ";
                        }
                        combinedMessage += error.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }
            }

            return {...state,
                app: null,
                beans: null,
                datasources: null,
                lookups: null,
                queries: null,
                menuActions: null,
                toolbarActions: null,
                dashboards: null,
                systemMenu: null,
                user: null,
                groups: null,
                b2Message: b2Message,
                userToken: null,
                discardPendingAction: null,
                saveRecordPendingAction: null,
                appLoader: false,
                queryLoader: false,
                loginLoader: false,
                searchValue: '',
                showConfirmSaveMessage: false,
                showConfirmDiscardMessage: false,
                drawerState: false,
                redirectLink:null,
                longOperation: false,
                longOperationText: null
            }
        }
        case RESEND_ACTIVE_LINK:{
            let b2Message = null;

            if (action.errors && action.errors.length > 0){
                if (action.errors.length === 1){
                    let combinedMessage = '';
                    if (action.errors[0].code){
                        combinedMessage += action.errors[0].code + ": ";
                    }
                    combinedMessage += action.errors[0].message;
                    b2Message = {
                        display: 'popup',
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.errors[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.errors.forEach(function (error) {
                        if (error.code){
                            combinedMessage += error.code + ": ";
                        }
                        combinedMessage += error.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }
            }else if (action.infos && action.infos.length > 0){
                let combinedMessage = '';

                if (action.infos.length === 1){
                    if (action.infos[0].code){
                        combinedMessage += combinedMessage += action.infos[0].code + ": ";
                    }
                    combinedMessage = action.infos[0].message;
                }else{
                    action.infos.forEach(function (info) {
                        if (info.code){
                            combinedMessage += action.infos.code + ": ";
                        }
                        combinedMessage += info.message + "<br/>";
                    });
                }

                b2Message = {
                    type: "info",
                    display: action.infos[0].display,
                    btnText: "Close",
                    confirmBtnBsStyle: "default",
                    title: "System Message",
                    message: combinedMessage
                }
            }else if (action.warnings && action.warnings.length > 0){
                 let combinedMessage = '';

                 if (action.warnings.length === 1){
                     if (action.warnings[0].code){
                         combinedMessage += combinedMessage += action.warnings[0].code + ": ";
                     }
                     combinedMessage = action.warnings[0].message;
                 }else{
                     action.warnings.forEach(function (info) {
                         if (info.code){
                             combinedMessage += action.warnings.code + ": ";
                         }
                         combinedMessage += info.message + "<br/>";
                     });
                 }

                 b2Message = {
                     type: "warning",
                     display: action.warnings[0].display,
                     btnText: "Close",
                     confirmBtnBsStyle: "default",
                     title: "System Message",
                     message: combinedMessage
                 }
             }

            let newState =  {...state,
                               b2Message: b2Message
                           }

            if (typeof action.callback !== 'undefined' && action.callback !== null){
                action.callback(action);
            }
            return newState;
        }
        case LOAD_B2_APP:{
//            if (!state.appLoader){
//                return {...state}
//            }

            let app = state.app;
            let beans = (action.beans)?action.beans:state.beans;
            let datasources = (action.datasources)?action.datasources:state.datasources;
            let queries = state.queries;
            let menuActions = state.menuActions;
            let toolbarActions = state.toolbarActions;
            let systemMenu = state.systemMenu;
            let accessibleApps = state.accessibleApps;
            let dashboards = state.dashboards;
            let defaultApp = state.defaultApp;
            let user = state.user;
            let groups = state.groups;
            let lookups = state.lookups;
            let b2Message = null;
            let userToken = action[TOKEN_KEY];

            if (action.errors){
                if (action.errors.length === 1){
                    let combinedMessage = '';
                    if (action.errors[0].code){
                        combinedMessage += action.errors[0].code + ": ";
                    }
                    combinedMessage += action.errors[0].message;
                    b2Message = {
                        display: action.errors[0].display,
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        code: action.errors[0].code,
                        message: combinedMessage
                    }
                }else{
                    let combinedMessage = '';
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.errors.forEach(function (error) {
                        if (error.code){
                            combinedMessage += error.code + ": ";
                        }
                        combinedMessage += error.message + "\n";
                    });

                    b2Message = {
                        display: 'popup',
                        type: "error",
                        btnText: "Close",
                        confirmBtnBsStyle: "default",
                        title: "System Message",
                        message: combinedMessage
                    }
                }
            }

            if (action.infos && action.infos.length > 0){
                let combinedMessage = '';

                if (action.infos.length === 1){
                    if (action.infos[0].code){
                        combinedMessage += combinedMessage += action.infos[0].code + ": ";
                    }
                    combinedMessage = action.infos[0].message;
                }else{
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.infos.forEach(function (info) {
                        if (info.code){
                            combinedMessage += action.infos.code + ": ";
                        }
                        combinedMessage += info.message + "<br/>";
                    });
                }

                b2Message = {
                    type: "info",
                    display: action.infos[0].display,
                    message: combinedMessage,
                    btnText: "Close",
                    confirmBtnBsStyle: "default",
                    title: "System Message"
                }
            }

            if (action.warnings && action.warnings.length > 0){
                let combinedMessage = '';

                if (action.warnings.length === 1){
                    if (action.warnings[0].code){
                        combinedMessage += combinedMessage += action.warnings[0].code + ": ";
                    }
                    combinedMessage = action.warnings[0].message;
                }else{
                    //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                    // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                    action.warnings.forEach(function (info) {
                        if (info.code){
                            combinedMessage += action.warnings.code + ": ";
                        }
                        combinedMessage += info.message + "<br/>";
                    });
                }

                b2Message = {
                    type: "warning",
                    display: action.warnings[0].display,
                    message: combinedMessage,
                    btnText: "Close",
                    confirmBtnBsStyle: "default",
                    title: "System Message"
                }
            }

            if (typeof(action.actionResponse)!=='undefined'){
                if (typeof(action.actionResponse.app) !== 'undefined'){
                    app = action.actionResponse.app;
                }

                if (typeof(action.actionResponse.queries)!=='undefined' && action.actionResponse.queries !== null && action.actionResponse.queries.length >0){
                    queries = action.actionResponse.queries
                }else{
                    queries = null
                }

                if (typeof(action.actionResponse.menuActions)!=='undefined' && action.actionResponse.menuActions !== null && action.actionResponse.menuActions.length >0){
                    menuActions = action.actionResponse.menuActions
                }else{
                    menuActions = null
                }

                if (typeof(action.actionResponse.toolbarActions)!=='undefined' && action.actionResponse.toolbarActions !== null && action.actionResponse.toolbarActions.length >0){
                    toolbarActions = action.actionResponse.toolbarActions
                }else{
                    toolbarActions = null
                }

                if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.systemMenu)!=='undefined' && action.actionResponse.userProfile.systemMenu !== null && action.actionResponse.userProfile.systemMenu.length >0){
                    systemMenu = action.actionResponse.userProfile.systemMenu
                }

                if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.accessibleApps)!=='undefined' && action.actionResponse.userProfile.accessibleApps !== null && action.actionResponse.userProfile.accessibleApps.length >0){
                    accessibleApps = action.actionResponse.userProfile.accessibleApps
                }

                if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.dashboards)!=='undefined' && action.actionResponse.userProfile.dashboards !== null && action.actionResponse.userProfile.dashboards.length >0){
                    dashboards = action.actionResponse.userProfile.dashboards
                }

                if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.defaultApp)!='undefined' && action.actionResponse.userProfile.defaultApp !== null){
                    defaultApp = action.actionResponse.userProfile.defaultApp
                }

                if (action.actionResponse.userProfile && typeof(action.actionResponse.userProfile.user)!='undefined' && action.actionResponse.userProfile.user !== null){
                    user = action.actionResponse.userProfile.user
                }else{
                    user = state.user
                }

                if (typeof(action.actionResponse.lookups)!='undefined' && action.actionResponse.lookups !== null){
                    lookups = action.actionResponse.lookups
                }else{
                    lookups = null
                }
            }

            if (userToken && userToken !== ''){
                return {
                    ...state,
                    app: app,
                    beans: beans,
                    datasources: datasources,
                    lookups: lookups,
                    queries: queries,
                    menuActions: menuActions,
                    toolbarActions: toolbarActions,
                    dashboards: dashboards,
                    systemMenu: systemMenu,
                    defaultApp: defaultApp,
                    accessibleApps: accessibleApps,
                    user: user,
                    groups: groups,
                    userToken: userToken,
                    b2Message: b2Message,
                    discardPendingAction: null,
                    saveRecordPendingAction: null,
                    appLoader: false,
                    queryLoader: false,
                    loginLoader: false,
                    searchValue: '',
                    showConfirmSaveMessage: false,
                    showConfirmDiscardMessage: false,
                    drawerState: false,
                    redirectLink:null,
                    longOperation: false,
                    longOperationText: null
                }
            }else{
                return {
                    ...state,
                    app: app,
                    beans: beans,
                    datasources: datasources,
                    lookups: lookups,
                    queries: queries,
                    menuActions: menuActions,
                    toolbarActions: toolbarActions,
                    dashboards: dashboards,
                    systemMenu: systemMenu,
                    defaultApp: defaultApp,
                    accessibleApps: accessibleApps,
                    user: user,
                    groups: groups,
                    b2Message: b2Message,
                    discardPendingAction: null,
                    saveRecordPendingAction: null,
                    appLoader: false,
                    queryLoader: false,
                    loginLoader: false,
                    searchValue: '',
                    showConfirmSaveMessage: false,
                    showConfirmDiscardMessage: false,
                    drawerState: false,
                    redirectLink:null,
                    longOperation: false,
                    longOperationText: null
                }
            }
        }
        case ON_TABLE_CHANGE_PAGE: { // Not really necessary as it has default behaviour, placed just for clarity
           const newState = generateNewState(state, action);
           return newState;
        }case ON_TABLE_CHANGE_RECORDS_PER_PAGE:{ // Not really necessary as it has default behaviour, placed just for clarity
            const newState = generateNewState(state, action);
            return newState;
        }case DOWNLOAD_ATTACHMENT: {
            const newState = generateNewState(state, action);
            return newState;
        }case DOWNLOAD_EWD: {
            const newState = generateNewState(state, action);
            return newState;
        }case DOWNLOAD_UNLIMITED_EWD:{
            const newState = generateNewState(state, action);
            return newState;
        }
        case CLOSE_B2_MESSAGE:{
            if (state.b2Message && (state.b2Message.code === 440 || state.b2Message.code === 401)){
                localStorage.removeItem(TOKEN_KEY);
                window.location='/signin';
                return {...state,
                    app: null,
                    beans: null,
                    datasources: null,
                    lookups: null,
                    queries: null,
                    menuActions: null,
                    toolbarActions: null,
                    dashboards: null,
                    systemMenu: null,
                    user: null,
                    groups: null,
                    userToken: null,
                    b2Message: null,
                    discardPendingAction: null,
                    saveRecordPendingAction: null,
                    appLoader: false,
                    queryLoader: false,
                    loginLoader: false,
                    searchValue: '',
                    showConfirmSaveMessage: false,
                    showConfirmDiscardMessage: false,
                    drawerState: false,
                    redirectLink:null,
                    longOperation: false,
                    longOperationText: null
                }
            }

            return {
                ...state,
                b2Message: null
            }
        }
        case SHOW_RECORD_DETAILS: { // Not really necessary as it has default behaviour, placed just for clarity
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_CHANGE_APP_PAGE: { // Not really necessary as it has default behaviour, placed just for clarity
            const newState = generateNewState(state, action);
            newState.discardPendingAction = null;
            return newState;
        }
        case ON_RECORD_SELECTED: { // Not really necessary as it has default behaviour, placed just for clarity
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_RECORDS_CHECKED: {
             let newState = generateNewState(state, action);
             return newState;
         }
        case ON_RECORDS_UNCHECKED: {
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_APP_TOGGLE_DRAWER:{
            return {
                ...state,
                drawerState: !state.drawerState
            }
        }
        case ON_TOGGLE_BOOKMARK_RECORD:{ // Not really necessary as it has default behaviour, placed just for clarity
            let newState = generateNewState(state, action);
            newState.saveRecordPendingAction = null;
            return newState;
        }
        case ON_RECORD_FIELD_VALUE_CHANGED:{
            // this action is called for each keystrock
            let newState = null;
            if (action.actionResponse.isPhantom){
                action.actionResponse.datasource.entityList.phantomEntity.attributes[action.actionResponse.attribute].value = action.actionResponse.value;
                newState = {
                    ...state,
                    datasources: {...state.datasources}
                };
            }else{
                action.actionResponse.datasource.entityList.selectedRecord.attributes[action.actionResponse.attribute].value = action.actionResponse.value;

                newState = {
                    ...state,
                    datasources: {...state.datasources}
                };

                newState.beans[newState.app.appBody.id].modified = true;
            }

            return newState;
        }
        case ON_RECORD_ATTRIBUTE_UPDATED:{
            // this action is called when tabbed out

            let newState = generateNewState(state, action);
            /*if (action.errors){ // rollback the changed value
                let selectedRecordId = newState.datasources[action.actionResponse.datasourceId].entityList.selectedRecord.attributes.id.value;
                let originalSelectedRecord = null;
                for(let i = 0 ; i < newState.datasources[action.actionResponse.datasourceId].entityList.data.length; i++) {
                    if (newState.datasources[action.actionResponse.datasourceId].entityList.data[i].attributes.id.value === selectedRecordId){
                        newState.datasources[action.actionResponse.datasourceId].entityList.selectedRecord.attributes[action.actionResponse.attribute].value=newState.datasources[action.actionResponse.datasourceId].entityList.data[i].attributes[action.actionResponse.attribute].value;
                        break;
                    }
                }

            }*/

            return newState;
        }
        case SAVE_RECORD:{ // Not really necessary as it has default behaviour, placed just for clarity
             let newState = generateNewState(state, action);
             return newState;
        }
        case EXECUTE_QUERY:{ // Not really necessary as it has default behaviour, placed just for clarity
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_SELECT_OPENED:{ // Not really necessary as it has default behaviour, placed just for clarity
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_LOOKUP_OPENED:{ // Not really necessary as it has default behaviour, placed just for clarity
           let newState = generateNewState(state, action);
           return newState;
        }
        case ON_TAB_CHANGED:{ // Not really necessary as it has default behaviour, placed just for clarity
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_DEL_UNDEL_TABLE_CHECKED_RECORDS:{ // Not really necessary as it has default behaviour, placed just for clarity
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_APP_SEARCH_FIELD_VALUE_CHANGED:{
            // this action is called for each keystrock

            let newState = {
                ...state,
                searchValue: action.actionResponse.searchValue
            };

            return newState;
        }
        case ON_TOGGLE_TABLE_FILTER:{
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_QBE_SEARCH_PERFORMED:{
            let newState = generateNewState(state, action);
            return newState;
        }
        case ON_LIST_SORT:{
            let newState = generateNewState(state, action);
            return newState;
        }
        default:{
            if (action.appName){
                let newState = generateNewState(state, action);

                if (typeof action.callback !== 'undefined' && action.callback !== null){
                    action.callback(action);
                }
                return newState;
            }else{
                if (typeof action.callback !== 'undefined' && action.callback !== null){
                    action.callback(action);
                }
                return {...state}
            }
        }
    }
}

function generateNewState(state, action){
    let newState = {
        ...state,
        b2Message: null,
        actionPendingConfirmation: null,
        discardPendingAction: null,
        saveRecordPendingAction: null,
        appLoader: false,
        queryLoader: false,
        loginLoader: false,
        searchValue: '',
        showConfirmSaveMessage: false,
        showConfirmDiscardMessage: false,
        drawerState: false,
        redirectLink:null,
        longOperation: false,
        longOperationText: null
    };

    if (action.beans){
        let {beans} = state;
        let newBeans =  null;

        if (!beans){
            newBeans = {}
        }else{
            newBeans = {...beans};
        }

        for (var beanId in action.beans) {
            if (Object.prototype.hasOwnProperty.call(action.beans, beanId)) {
                newBeans[beanId] = action.beans[beanId];
            }
        }

        newState.beans = newBeans;
    }else{
        newState.beans = {...state.beans}
    }

    if (action.datasources){
        let {datasources} = state;
        let newDatasources =  null;

        if (!datasources){
            newDatasources = {}
        }else{
            newDatasources = {...datasources};
        }

        for (var datasourceId in action.datasources) {
            if (Object.prototype.hasOwnProperty.call(action.datasources, datasourceId)) {
                newDatasources[datasourceId] = action.datasources[datasourceId];
            }
        }

        newState.datasources = newDatasources;
    }else{
        newState.datasources = {...state.datasources}
    }

    if (action[TOKEN_KEY]){
        newState.userToken = action[TOKEN_KEY];
    }
    processErrorMessages(newState, action);

    return newState;
}

function processErrorMessages(newState, action){
    if (action.errors && action.errors.length > 0){
        let combinedMessage = "";

        if (action.errors.length === 1){
            if (action.errors[0].code){
                combinedMessage += action.errors[0].code + ": ";
            }
            combinedMessage += action.errors[0].message;

            if (action.errors[0].confirmationType && action.actionResponse && action.actionResponse.actionPendingConfirmation){
                const options = action.errors[0].confirmationType.split("_");

                newState.b2Message = {
                    confirmationType:action.errors[0].confirmationType,
                    display: action.errors[0].display,
                    type: action.errors[0].type,
                    confirmBtnText:options[1],
                    confirmBtnBsStyle:action.errors[0].type.toLowerCase(),
                    cancelBtnText:options[0],
                    cancelBtnBsStyle:'primary',
                    title: action.errors[0].type,
                    code: action.errors[0].code,
                    message: combinedMessage
                }

                newState.actionPendingConfirmation = action.actionResponse.actionPendingConfirmation;
            } else {
                newState.b2Message = {
                    display: action.errors[0].display,
                    type: "error",
                    btnText: "Close",
                    confirmBtnBsStyle: "primary",
                    title: "System Message",
                    code: action.errors[0].code,
                    message: combinedMessage
                }
            }

        }else{
            //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
            // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
            action.errors.forEach(function (error) {
                if (error.code){
                    combinedMessage += error.code + ": ";
                }
                combinedMessage += error.message + "\n";
            });

            newState.b2Message = {
                display: 'popup',
                type: "error",
                btnText: "Close",
                confirmBtnBsStyle: "default",
                title: "System Message",
                message: combinedMessage
            }
        }


    }

    if (action.infos && action.infos.length > 0){
        let combinedMessage = '';

        if (action.infos.length === 1){
            if (action.infos[0].code){
                combinedMessage += combinedMessage += action.infos[0].code + ": ";
            }
            combinedMessage = action.infos[0].message;
        }else{
            //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
            // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
            action.infos.forEach(function (info) {
                if (info.code){
                    combinedMessage += action.infos.code + ": ";
                }
                combinedMessage += info.message + "<br/>";
            });
        }

        newState.b2Message = {
            type: "info",
            display: action.infos[0].display,
            message: combinedMessage,
            btnText: "Close",
            confirmBtnBsStyle: "default",
            title: "System Message"
        }
    }

    if (action.warnings && action.warnings.length > 0){
            let combinedMessage = '';

            if (action.warnings.length === 1){
                if (action.warnings[0].code){
                    combinedMessage += combinedMessage += action.warnings[0].code + ": ";
                }
                combinedMessage = action.warnings[0].message;
            }else{
                //ideally there should be one error, in case there are multiple, we are displaying all, but a preferable option would be to show one after the other
                // with user closing first so they get the next and so on. This approach helps discouraging bad practices of throwing multiple errors at the same time
                action.warnings.forEach(function (info) {
                    if (info.code){
                        combinedMessage += action.warnings.code + ": ";
                    }
                    combinedMessage += info.message + "<br/>";
                });
            }

            newState.b2Message = {
                type: "warning",
                display: action.warnings[0].display,
                message: combinedMessage,
                btnText: "Close",
                confirmBtnBsStyle: "default",
                title: "System Message"
            }
        }
}