import B2App from './B2/B2App';
import B2ActionMenu from './B2/B2ActionMenu';
import B2Page from './B2/B2Page';
import B2Dialog from './B2/B2Dialog';
import B2DialogBody from './B2/B2DialogBody';
import B2DialogButtonGroup from './B2/B2DialogButtonGroup';
import B2PageHeader from './B2/B2PageHeader';
import B2Scrollbars from './B2/B2Scrollbars';
import B2Tabs from './B2/B2Tabs';
import B2Tab from './B2/B2Tab';
import B2ToolBar from './B2/B2ToolBar';
import B2Table from './B2/B2Table';
import B2TableHeader from './B2/B2TableHeader';
import B2TableBody from './B2/B2TableBody';
import B2TableButtonGroup from './B2/B2TableButtonGroup';
import B2TableDetails from './B2/B2TableDetails';
import B2Button from './B2/B2Button';
import B2TableCol from './B2/B2TableCol';
import B2Checkbox from './B2/B2Checkbox';
import B2IconButton from './B2/B2IconButton';
import B2Card from './B2/B2Card';
import B2GridCell from './B2/B2GridCell';
import B2Label from './B2/B2Label';
import B2TextField from './B2/B2TextField';
import B2Select from './B2/B2Select';
import B2Row from './B2/B2Row';
import B2AppSearch from './B2/B2AppSearch';
import B2Hyperlink from './B2/B2Hyperlink';
import B2Object from './B2/B2Object';
import B2Image from './B2/B2Image';
import B2ImageView from "./B2/B2ImageView";
import SafeeEWDDownloadButton from './Safee/SafeeEWDDownloadButton';
import SafeeSubscription from './Safee/SafeeSubscription';
import SafeeEWDGraphicalView from './Safee/SafeeEWDGraphicalView';
import SafeeFormDownloadIconButton from './Safee/SafeeFormDownloadIconButton';
import SafeeTZDateTextField from './Safee/SafeeTZDateTextField';
import SafeeTableCol from './Safee/SafeeTableCol';
import SafeeTableBody from './Safee/SafeeTableBody';
import SafeePhotosButton from './Safee/SafeePhotosButton';

export default{
    "B2App": B2App,
    "B2ActionMenu": B2ActionMenu,
    "B2Page": B2Page,
    "B2Dialog": B2Dialog,
    "B2DialogBody": B2DialogBody,
    "B2DialogButtonGroup": B2DialogButtonGroup,
    "B2PageHeader": B2PageHeader,
    "B2Scrollbars": B2Scrollbars,
    "B2Tabs": B2Tabs,
    "B2Tab": B2Tab,
    "B2ToolBar": B2ToolBar,
    "B2Table": B2Table,
    "B2TableHeader": B2TableHeader,
    "B2TableBody": B2TableBody,
    "B2TableButtonGroup": B2TableButtonGroup,
    "B2TableDetails": B2TableDetails,
    "B2TableCol": B2TableCol,
    "B2Checkbox": B2Checkbox,
    "B2IconButton": B2IconButton,
    "B2Card": B2Card,
    "B2GridCell": B2GridCell,
    "B2Label": B2Label,
    "B2TextField": B2TextField,
    "B2Button": B2Button,
    "B2Select": B2Select,
    "B2Row": B2Row,
    "B2AppSearch": B2AppSearch,
    "B2Hyperlink": B2Hyperlink,
    "B2Object": B2Object,
    "B2Image": B2Image,
    "B2ImageView": B2ImageView,
    "SafeeEWDDownloadButton": SafeeEWDDownloadButton,
    "SafeeSubscription": SafeeSubscription,
    "SafeeEWDGraphicalView": SafeeEWDGraphicalView,
    "SafeeFormDownloadIconButton": SafeeFormDownloadIconButton,
    "SafeeTableCol": SafeeTableCol,
    "SafeeTZDateTextField": SafeeTZDateTextField,
    "SafeeTableBody": SafeeTableBody,
    "SafeePhotosButton": SafeePhotosButton
}

