import React from 'react';
import Button from '@material-ui/core/Button';
import {store} from 'MainApp';
import {DOWNLOAD_EWD} from 'constants/SafeeActionTypes';
//import moment from 'moment';
import moment from 'moment-timezone';
import Chip from '@material-ui/core/Chip';

class SafeeEWDGraphicalView extends React.Component {
    static height = 400;
    static width = 800;
    static heightFactor = SafeeEWDGraphicalView.height/71;   // 5.633802816901408
    static widthFactor = SafeeEWDGraphicalView.width/113;    // 7.079646017699115
    static includeNonCategorisedBreaches = true;
    static maxDaysToKeep = 28;

    constructor(props) {
        super(props);

        let today = moment();

        let graphicalViewDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0);

        let newState = this.getNewState(graphicalViewDate);

        this.state = {
            today: today,
            ...newState
        };
    }

    getNewState(datetime){
        let graphicalViewDate = datetime;

        if (graphicalViewDate == null){
            graphicalViewDate = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
        }

        let events = this.props.datasources[this.props.beans[this.props.uiDefinition.eventsDatasource].datasource].entityList.data.filter(
            (event, index) => {
                let eventStartTime = this.buildDateObj(event, "startTime", "timezone", "timeOffset");

                if (this.isSameDay(eventStartTime, graphicalViewDate)){
                    return event;
                }

                return;
            }
        );

        let comments = this.props.datasources[this.props.beans[this.props.uiDefinition.commentsDatasource].datasource].entityList.data.filter(
            (comment, index) => {
                const commentTime = this.buildDateObj(comment, "time", "timezone", "timeOffset");

                if (this.isSameDay(commentTime, graphicalViewDate)){
                    return comment;
                }

                return;
            }
       );

        let annotations = this.props.datasources[this.props.beans[this.props.uiDefinition.annotationsDatasource].datasource].entityList.data.filter(
            (annotation, index) => {
                let annotationFlaggedTime = this.buildDateObj(annotation, "flaggedTime", "timezone", "timeOffset");

                if (this.isSameDay(annotationFlaggedTime, graphicalViewDate)){
                    return annotation;
                }

                return;
            }
        );

        let prevVehicle = null;
        let vehicleInfo = this.props.datasources[this.props.beans[this.props.uiDefinition.vehiclesDatasource].datasource].entityList.data.filter(
            (vehicle, index) => {
                let vehicleTimeOfEffect = this.buildDateObj(vehicle, "timeOfEffect", "timezone", "timeOffset");

                if (this.isSameDay(vehicleTimeOfEffect, graphicalViewDate)){
                    return vehicle;
                }

                if (vehicleTimeOfEffect.isAfter(graphicalViewDate)){
                    return;
                }

                if (prevVehicle === null){
                    prevVehicle = vehicle;
                }else{
                    if (moment(prevVehicle.attributes.timeOfEffect.value).isBefore(vehicleTimeOfEffect)){
                        prevVehicle = vehicle;
                    }
                }

                return;
            }
        );

        if (prevVehicle != null){
            if (vehicleInfo === null || vehicleInfo.length === 0){
                vehicleInfo = [prevVehicle];
            }else{
                let firstElementTimeOfEffect = moment(vehicleInfo[0].attributes.timeOfEffect.value);
                if (parseInt(firstElementTimeOfEffect.format("HH")) != 0 || parseInt(firstElementTimeOfEffect.format("mm")) != 0){
                    vehicleInfo = [prevVehicle].concat(vehicleInfo);
                }
            }
        }

        let prevWorkRestOptionEvent = null;
        let workRestOptionEvents = this.props.datasources[this.props.beans[this.props.uiDefinition.workRestOptionEventsDatasource].datasource].entityList.data.filter(
            (workRestOptionEvent, index) => {
                let workRestOptionEventTimeOfEffect = this.buildDateObj(workRestOptionEvent, "timeOfEffect", "timezone", "timeOffset");

                if (this.isSameDay(workRestOptionEventTimeOfEffect, graphicalViewDate)){
                    return workRestOptionEvent;
                }

                if (workRestOptionEventTimeOfEffect.isAfter(graphicalViewDate)){
                    return;
                }

                if (prevWorkRestOptionEvent === null){
                    prevWorkRestOptionEvent = workRestOptionEvent;
                }else{
                    if (moment(prevWorkRestOptionEvent.attributes.timeOfEffect.value).isBefore(workRestOptionEventTimeOfEffect)){
                        prevWorkRestOptionEvent = workRestOptionEvent;
                    }
                }

                return;
            }
        );

        if (prevWorkRestOptionEvent !== null){
            if (workRestOptionEvents === null || workRestOptionEvents.length === 0){
                workRestOptionEvents = [prevWorkRestOptionEvent];
            }else{
                let firstElementTimeOfEffect = moment(workRestOptionEvents[0].attributes.timeOfEffect.value);
                if (parseInt(firstElementTimeOfEffect.format("HH")) != 0 || parseInt(firstElementTimeOfEffect.format("mm")) != 0){
                    workRestOptionEvents = [prevWorkRestOptionEvent].concat(workRestOptionEvents);
                }
            }
        }

        let prevTwoUpEvent = null;
        let twoUpEvents = this.props.datasources[this.props.beans[this.props.uiDefinition.twoUpEventsDatasource].datasource].entityList.data.filter(
            (twoUpEvent, index) => {
                let twoUpEventTimeOfEffect = this.buildDateObj(twoUpEvent, "timeOfEffect", "timezone", "timeOffset");

                if (this.isSameDay(twoUpEventTimeOfEffect, graphicalViewDate)){
                    return twoUpEvent;
                }

                if (twoUpEventTimeOfEffect.isAfter(graphicalViewDate)){
                    return;
                }

                if (prevTwoUpEvent === null){
                    prevTwoUpEvent = twoUpEvent;
                }else{
                    if (moment(prevTwoUpEvent.attributes.timeOfEffect.value).isBefore(twoUpEventTimeOfEffect)){
                        prevTwoUpEvent = twoUpEvent;
                    }
                }

                return;
            }
        );

        if (prevTwoUpEvent != null){
            if (twoUpEvents === null || twoUpEvents.length === 0){
                twoUpEvents = [prevTwoUpEvent];
            }else{
                let firstElementTimeOfEffect = moment(twoUpEvents[0].attributes.timeOfEffect.value);
                if (parseInt(firstElementTimeOfEffect.format("HH")) != 0 || parseInt(firstElementTimeOfEffect.format("mm")) != 0){
                    twoUpEvents = [prevTwoUpEvent].concat(workRestOptionEvents);
                }
            }
        }

        let potentialNonComplianceList = this.props.datasources[this.props.beans[this.props.uiDefinition.breachesDatasource].datasource].entityList.data.filter(
             (potentialNonCompliance, index) => {
                let breachTime = moment(potentialNonCompliance.attributes.dateTime.value);
                let currentDateMinusMaxDaysToKeep = moment().subtract(SafeeEWDGraphicalView.maxDaysToKeep, 'days');

                if ((SafeeEWDGraphicalView.includeNonCategorisedBreaches || potentialNonCompliance.attributes.riskLevel.value != null) && !breachTime.isBefore(currentDateMinusMaxDaysToKeep)){
                    return potentialNonCompliance;
                }

                return;
            }
        );

        potentialNonComplianceList.sort((a, b) => (moment(a.attributes.dateTime.value).isBefore(b.attributes.dateTime.value))?-1:1);

        let timezonesForTheDay = this.getTimeZones(graphicalViewDate, events, comments, annotations, vehicleInfo, workRestOptionEvents, twoUpEvents); // lastTimeZone to be checked

        return {
            graphicalViewDate: graphicalViewDate,
            events: events,
            comments: comments,
            annotations: annotations,
            vehicleInfo: vehicleInfo,
            workRestOptionEvents: workRestOptionEvents,
            twoUpEvents: twoUpEvents,
            potentialNonComplianceList: potentialNonComplianceList,
            timezonesForTheDay: timezonesForTheDay,
            timezoneIndex: 0
        }
    }

    isSameDay(d1, d2) {
      return (d1.format('YYYY') === d2.format('YYYY')) && d1.format('MM') === d2.format('MM') && d1.format('DD') === d2.format('DD');
    }

    getHeaderData(){
        let fromDate = this.state.graphicalViewDate;

        //let headerArr = [];

        let user = this.props.datasource;
        let userFullName = user.entityList.selectedRecord.attributes.firstName.value;
        if (userFullName != null && userFullName != ''){
            if (user.entityList.selectedRecord.attributes.lastName.value != null && user.entityList.selectedRecord.attributes.lastName.value != ''){
                userFullName += ' ' + user.entityList.selectedRecord.attributes.lastName.value;
            }
        }else{
            if (user.entityList.selectedRecord.attributes.lastName.value != null && user.entityList.selectedRecord.attributes.lastName.value != ''){
                userFullName = user.entityList.selectedRecord.attributes.lastName.value;
            }else{
                userFullName = '';
            }
        }

        let licenses =  this.props.datasources[this.props.beans[this.props.uiDefinition.drivingLicensesDatasource].datasource].entityList.data;

        let license = null;

        if (licenses != null && licenses.length>0){
            for (let i=0; i<licenses.length; i++){
                let licenseDate = moment(licenses[i].attributes["timeOfEffect"].value);

                if (licenseDate < fromDate){
                    license = licenses[i];
                }else if (this.isSameDay(fromDate, moment())){
                    license = licenses[i];
                    break;
                }else if (license === null){
                    license = licenses[i];
                    break;
                }else{
                    break;
                }
            }
        }

        let vehicles =  this.props.datasources[this.props.beans[this.props.uiDefinition.vehiclesDatasource].datasource].entityList.data;

        let vehicle = null;

        if (vehicles != null && vehicles.length>0){
            for (let i=0; i<vehicles.length; i++){
                let vehicleDate = moment(vehicles[i].attributes["timeOfEffect"].value);

                if (vehicleDate < fromDate){
                    vehicle = vehicles[i];
                }else if (this.isSameDay(fromDate, moment())){
                    vehicle = vehicles[i];
                    break;
                }else if (vehicle === null){
                    vehicle = vehicles[i];
                    break;
                }else{
                    break;
                }
            }
        }

        let safeeTimeZone = this.state.timezonesForTheDay[this.state.timezoneIndex];

        let workRestOption = "";

        let eventAfterMajorBreak = this.locateEventAfterMajorBreak(
            this.state.timezonesForTheDay[this.state.timezoneIndex],
            (
                (this.state.timezonesForTheDay.length>this.state.timezoneIndex + 1)?
                this.state.timezonesForTheDay[this.state.timezoneIndex + 1]:
                null
            )
        );

        if (eventAfterMajorBreak != null){
          workRestOption = this.stringifyWorkRestOption(eventAfterMajorBreak.attributes.workRestOption.value);
        }else{
            let safeeTimezone = (this.state.timezonesForTheDay.length >0)?this.state.timezonesForTheDay[this.state.timezoneIndex]:null;

            let dateProcessed = this.getTimeZoneDateForACalendarDate(this.state.graphicalViewDate, safeeTimezone.timezone, safeeTimezone.timeOffset);

            let previousEvent = this.getClosestPreviousEvent(this.getTimeZoneDateForACalendarDate(dateProcessed, this.state.timezonesForTheDay[this.state.timezoneIndex].timezone, this.state.timezonesForTheDay[this.state.timezoneIndex].timeOffset));

            if (previousEvent != null) {
                workRestOption = this.stringifyWorkRestOption(previousEvent.attributes.workRestOption.value);
            }else{
                let workRestOptionObj = this.getWorkRestOption(this.getTimeZoneDateForACalendarDate(dateProcessed, this.state.timezonesForTheDay[this.state.timezoneIndex].timezone, this.state.timezonesForTheDay[this.state.timezoneIndex].timeOffset));
                if (workRestOptionObj != null){
                    workRestOption = this.stringifyWorkRestOption(workRestOptionObj);
                }
            }
        }

        return (
            <tbody>
                <tr>
                    <td style={{width:"16.66%", color: "black", verticalAlign: "top"}}>
                        {userFullName}
                    </td>
                    <td style={{width:"16.66%", color: "black", verticalAlign: "top"}}>
                        {(license != null)?license.attributes["number"].value:''}
                    </td>
                    <td style={{width:"16.66%", color: "black", verticalAlign: "top"}}>
                        {(vehicle != null)?vehicle.attributes["numberPlate"].value:''}
                    </td>
                    <td style={{width:"16.66%", color: "black", verticalAlign: "top"}}>
                       {workRestOption}
                    </td>
                    <td style={{width:"16.66%", color: "black", verticalAlign: "top"}}>
                        {fromDate.format("DD/MM/YY")}
                    </td>
                    <td style={{color: "black", verticalAlign: "top"}}>
                       {safeeTimeZone.timezone + ' (' + safeeTimeZone.timeOffset + ')'}
                    </td>
                </tr>
            </tbody>
        );
    }

    getWorkRestOption(dateTime) {
        let workRestOptionEvents = this.props.datasources[this.props.beans[this.props.uiDefinition.workRestOptionEventsDatasource].datasource].entityList.data;

        if (workRestOptionEvents.isEmpty || workRestOptionEvents.length === 0){
            return null;
        }

        let workRestOptionEvent = workRestOptionEvents.findLast(workRestOptionEvent => moment(workRestOptionEvent.attributes.timeOfEffect.value).isBefore(dateTime));

        if (workRestOptionEvent != null){
            return workRestOptionEvent.attributes.workRestOption.value;
        }

        let nextDate = dateTime.clone().add(1, "days");

        if (parseInt(nextDate.format("HH")) >= 23){
            nextDate.add(1, "hours");
        }else if (parseInt(nextDate.format("HH")) === 1){
            nextDate.subtract(1, "hours");
        }

        workRestOptionEvent = workRestOptionEvents.filter(workRestOptionEvent => moment(workRestOptionEvent.attributes.timeOfEffect.value).isAfter(dateTime) && moment(workRestOptionEvent.attributes.timeOfEffect.value).isBefore(nextDate)) [0];

        if (workRestOptionEvent != null){
            return workRestOptionEvent.attributes.workRestOption.value;
        }

        return null;
    }

    stringifyWorkRestOption(workRestOption){
        if (workRestOption.toLowerCase() === 'standard'){
            return 'Standard';
        }else if (workRestOption.toLowerCase() === 'bfm'){
            return 'BFM';
        }else if (workRestOption.toLowerCase() === 'standardbus'){
            return 'Standard Bus';
        }

        return '';
    }

    locateEventAfterMajorBreak(safeeTimezone, nextSafeeTimezone){
        if (this.state.events.length == 0){
            return null;
        }

        let firstWorkDiaryItem;

        if (!moment(this.state.events[0].attributes.startTime.value).isBefore(safeeTimezone.datetime) && (nextSafeeTimezone == null || moment(this.state.events[0].attributes.startTime.value).isBefore(nextSafeeTimezone.datetime))){
            let previousEvent = this.getClosestPreviousEvent(moment(this.state.events[0].attributes.startTime.value));
            firstWorkDiaryItem = this.state.events[0];

            if (previousEvent == null || ((moment(this.state.events[0].attributes.startTime.value).valueOf() - moment(previousEvent.attributes.startTime.value).valueOf()) >= 25200000)){   // TODO: validate against major break in files
                return this.state.events[0];
            }
        }

        for (let i=1; i<this.state.events.length; i++){
            if (!moment(this.state.events[i].attributes.startTime.value).isBefore(safeeTimezone.datetime) && (nextSafeeTimezone == null || moment(this.state.events[i].attributes.startTime.value).isBefore(nextSafeeTimezone.datetime))){
                if (firstWorkDiaryItem == null){
                    firstWorkDiaryItem = this.state.events[i];
                }
                if ((moment(this.state.events[i].attributes.startTime.value).valueOf() - moment(this.state.events[i-1].attributes.startTime.value).valueOf()) >= 25200000){   // TODO: validate against major break in files
                    return this.state.events[i];
                }
            }
        }

        return firstWorkDiaryItem;
    }

    getAnnotationsAndComments(){
        let annotationsSquares = [];
        let commentsSquares = [];

        for (let i=4; i<=27; i++){
            annotationsSquares.push(
                <rect key={'annotationsSquares_'+i} width={(SafeeEWDGraphicalView.widthFactor*4) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y='0px' rx="0" ry="0" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}}><title>some text</title></rect>
            );

            commentsSquares.push(
                <rect key={'commentsSquares_'+i} width={(SafeeEWDGraphicalView.widthFactor*4) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*5) + 'px'} rx="0" ry="0" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} />
            );
        }

        let previousSafeeTimezone = (this.state.timezoneIndex==0)?null:this.state.timezonesForTheDay[this.state.timezoneIndex - 1];
        let safeeTimezone = (this.state.timezonesForTheDay.length > 0)?this.state.timezonesForTheDay[this.state.timezoneIndex]:null;
        let nextSafeeTimezone = (this.state.timezoneIndex >=this.state.timezonesForTheDay.length-1)?null:this.state.timezonesForTheDay[this.state.timezoneIndex + 1];

        let dateProcessed = this.getTimeZoneDateForACalendarDate(this.state.graphicalViewDate, safeeTimezone.timezone, safeeTimezone.timeOffset);

        let nextDate = dateProcessed.clone().add(1, "days");

        if (parseInt(nextDate.format("HH")) >= 23){
            nextDate.add(1, "hours");
        }else if (parseInt(nextDate.format("HH")) === 1){
            nextDate.subtract(1, "hours");
        }

        let annotations = [];

        if (safeeTimezone != null && this.state.annotations.length > 0){
            let factor = (SafeeEWDGraphicalView.widthFactor > SafeeEWDGraphicalView.heightFactor)? SafeeEWDGraphicalView.heightFactor: SafeeEWDGraphicalView.widthFactor;

            for (let i=0; i<this.state.annotations.length; i++){
                if (moment(this.state.annotations[i].attributes.flaggedTime.value).isBefore(safeeTimezone.datetime)) { // equal or after
                  continue;
                }

                if (nextSafeeTimezone != null){
                  if (!moment(this.state.annotations[i].attributes.flaggedTime.value).isBefore(nextSafeeTimezone.datetime)){
                    break;
                  }
                }else{
                  if (!moment(this.state.annotations[i].attributes.flaggedTime.value).isBefore(nextDate)){
                    break;
                  }
                }

                let left = (SafeeEWDGraphicalView.widthFactor * 16) +
                    ((SafeeEWDGraphicalView.widthFactor/15) * (Math.floor(Math.abs(moment(this.state.annotations[i].attributes.flaggedTime.value).valueOf() - dateProcessed.valueOf()) / 60000))) -
                    factor*1.75/2;

                let annotationContent = "\nAnnotation Details:\n\n";

                if (this.state.annotations[i].attributes.officerName.value != null){
                    annotationContent += "  -  Officer Name: " + this.state.annotations[i].attributes.officerName.value + "\n";
                }

                if (this.state.annotations[i].attributes.officerId.value != null){
                    annotationContent += "  -  Officer Id: " + this.state.annotations[i].attributes.officerId.value + "\n";
                }

                if (this.state.annotations[i].attributes.organization.value != null){
                    annotationContent += "  -  Organisation: " + this.state.annotations[i].attributes.organization.value + "\n";
                }

                if (this.state.annotations[i].attributes.state.value != null){
                    annotationContent += "  -  State: " + this.state.annotations[i].attributes.state.value + "\n";
                }

                if (this.state.annotations[i].attributes.interceptTime.value != null){
                    annotationContent += "  -  Interception Time: " + this.formatDateTime(this.state.annotations[i].attributes.interceptTime.value, this.state.annotations[i].attributes.timezone.value) + "\n";
                }

                if (this.state.annotations[i].attributes.flaggedTime.value != null){
                    annotationContent += "  -  Flagged Time: " + this.formatDateTime(this.state.annotations[i].attributes.flaggedTime.value, this.state.annotations[i].attributes.timezone.value) + "\n";
                }

                if (this.state.annotations[i].attributes.location.value != null){
                    annotationContent += "  -  Location: " + this.state.annotations[i].attributes.location.value + "\n";
                }

                if (this.state.annotations[i].attributes.annotation.value != null){
                    annotationContent += "  -  Annotation: " + this.state.annotations[i].attributes.annotation.value + "\n";
                }

                annotations.push(
                    <circle key={'annotations_'+i}  cx={left + 'px'} cy={(SafeeEWDGraphicalView.heightFactor*2) + 'px'} r={(SafeeEWDGraphicalView.widthFactor * 1.75/2) + 'px'} fill="black">
                        <title>{annotationContent}</title>
                    </circle>
                );
            }
        }

        let comments = [];

        if (safeeTimezone != null && this.state.comments.length > 0){
            let factor = (SafeeEWDGraphicalView.widthFactor > SafeeEWDGraphicalView.heightFactor)? SafeeEWDGraphicalView.heightFactor: SafeeEWDGraphicalView.widthFactor;

            for (let i=0; i<this.state.comments.length; i++){

                if (moment(this.state.comments[i].attributes.time.value).isBefore(safeeTimezone.datetime)) { // equal or after
                  continue;
                }

                if (nextSafeeTimezone != null){
                  if (!moment(this.state.comments[i].attributes.time.value).isBefore(nextSafeeTimezone.datetime)){
                    break;
                  }
                }else{
                  if (!moment(this.state.comments[i].attributes.time.value).isBefore(nextDate)){
                    break;
                  }
                }

                let left = (SafeeEWDGraphicalView.widthFactor * 16) +
                    ((SafeeEWDGraphicalView.widthFactor/15) * (Math.floor(Math.abs(moment(this.state.comments[i].attributes.time.value).valueOf() - dateProcessed.valueOf()) / 60000)));

                let starPath = this.star(left, SafeeEWDGraphicalView.heightFactor*7, 5, 4, 8);

                let commentContent = "";

                if (this.state.comments[i].attributes.editedStartTime.value != null || this.state.comments[i].attributes.editedLocation.value != null){
                    commentContent += "\nComment Details:\n\n";

                    commentContent += "  -  Type: Work/Rest Event Edited\n";

                    if (this.state.comments[i].attributes.comment.value != null){
                        commentContent += "  -  Comment: " + this.state.comments[i].attributes.comment.value + "\n";
                    }

                    commentContent += "  -  Comment Time: " + this.formatDateTime(this.state.comments[i].attributes.time.value, this.state.comments[i].attributes.timezone.value) + "\n";

                    if (this.state.comments[i].attributes.editedStartTime.value != null && (this.state.comments[i].attributes.editedStartTime.value != this.state.comments[i].attributes.newStartTime.value)){
                        commentContent += "  -  Edited Start Time: " + this.formatDateTime(this.state.comments[i].attributes.editedStartTime.value, this.state.comments[i].attributes.timezone.value) + "\n";
                        commentContent += "  -  New Start Time: " + this.formatDateTime(this.state.comments[i].attributes.newStartTime.value, this.state.comments[i].attributes.timezone.value) + "\n";
                    }

                    if (this.state.comments[i].attributes.editedLocation.value != null && (this.state.comments[i].attributes.editedLocation.value != this.state.comments[i].attributes.newLocation.value)){
                        commentContent += "  -  Edited Location: " + this.state.comments[i].attributes.editedLocation.value + "\n";
                        commentContent += "  -  New Location: " + this.state.comments[i].attributes.newLocation.value + "\n";
                    }
                }else{
                    if (this.state.comments[i].attributes.comment.value != null){
                        commentContent = this.state.comments[i].attributes.comment.value;
                    }
                }

                comments.push(
                    <path key={'comments_'+i} d={starPath} fill="rgba(0,0,0, 1)">
                        <title>{commentContent}</title>
                    </path>
                );
            }
        }

        return (
            [
                <g key={'Annotations_squares_block'}>
                    <rect width={(SafeeEWDGraphicalView.widthFactor*16) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x="0px" y="0px" rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 8,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 4 --> */}
                    <text x={SafeeEWDGraphicalView.widthFactor} y={(SafeeEWDGraphicalView.heightFactor*2.75) + 'px'}>Annotations</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 8) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}
                    {annotationsSquares}
                </g>,
                <g key={'Annotations_block'}>
                    {annotations}
                </g>,
                <g key={'Comments_squares_block'}>
                    <rect width={(SafeeEWDGraphicalView.widthFactor*16) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x="0px" y={(SafeeEWDGraphicalView.heightFactor*5) + 'px'} rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 8,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 4 --> */}
                    <text x={SafeeEWDGraphicalView.widthFactor} y={(SafeeEWDGraphicalView.heightFactor*7.75) + 'px'}>Comments</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 13) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}

                    {commentsSquares}
                </g>,
                <g key={'Comments_block'}>
                    {comments}
                </g>,
            ]
        );
    }

    formatDateTime(dateStr, timezone){
        let datetime = moment.tz(dateStr, this.getTimezoneStr(timezone));

        return datetime.format("YYYY-MM-DD HH:mm ") + timezone.toUpperCase();
    }

    star(centerX, centerY, points, innerRadius, outerRadius) {
      const degreeIncrement = 360 / (points * 2);
      const d = new Array(points * 2).fill('foo').map((p, i) => {
        let radius = i % 2 == 0 ? outerRadius : innerRadius;
        let degrees = degreeIncrement * i;
        const point = this.polarToCartesian(centerX, centerY, radius, degrees);
        return `${point.x},${point.y}`;
      });
      return `M${d}Z`;
    }

    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      };
    }

    calculateStarPoints(centerX, centerY, arms, outerRadius, innerRadius) {
        let results = "";

        let angle = Math.PI / arms;

        for (let i = 0; i < 2 * arms; i++) {
            // Use outer or inner radius depending on what iteration we are in.
            let r = (i & 1) == 0 ? outerRadius : innerRadius;

            let currX = centerX + Math.cos(i * angle) * r;
            let currY = centerY + Math.sin(i * angle) * r;

            // Our first time we simply append the coordinates, subsequet times
            // we append a ", " to distinguish each coordinate pair.
            if (i == 0) {
                results = currX + "," + currY;
            } else {
                results += ", " + currX + "," + currY;
            }
        }

        return results;
    }

    getLocationsAndOdometersSquares(){
        let locationsAndOdometersSquares = [];

        for (let i=4; i<=27; i++){
            locationsAndOdometersSquares.push(
               <rect key={'locationsAndOdometersSquares_'+i} width={(SafeeEWDGraphicalView.widthFactor*4) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*37) + 'px'} x={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*10) + 'px'} rx="0" ry="0" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} />
            );
        }

        return (
            <g>
                <rect width={(SafeeEWDGraphicalView.widthFactor*16) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*37) + 'px'} x="0px" y={(SafeeEWDGraphicalView.heightFactor*10) + 'px'} rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 18,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 29 --> */}
               <text x={SafeeEWDGraphicalView.widthFactor} y={(SafeeEWDGraphicalView.heightFactor*12.75) + 'px'}>Location</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 1) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}

                {locationsAndOdometersSquares}
            </g>
        );
    }

    getHourNumbersAndScaleMarkers(){
        let clockNumbers = [];

        for (let i=4, j=0; i<=13; i++, j++){
            clockNumbers.push(
                <text key={'clockNumbers_'+i} x={(SafeeEWDGraphicalView.widthFactor*(4*i-0.6)) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*50) + 'px'}>{j}</text>
            );
        }

        for (let i=14, j=10; i<=28; i++, j++){
            clockNumbers.push(
                <text key={'clockNumbers_'+i} x={(SafeeEWDGraphicalView.widthFactor*(4*i-1.2)) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*50) + 'px'}>{j}</text>
            );
        }

        let clockMarkers = [];

        for (let i=4; i<=28; i += 0.25){
            clockMarkers.push(
                <line key={'clockMarkers_'+i} x1={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y1={(SafeeEWDGraphicalView.heightFactor*((i%1==0)?50.75:51.3)) + 'px'}  x2={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y2={(SafeeEWDGraphicalView.heightFactor*52) + 'px'} style={{stroke: "rgb(224,224,224)", strokeWidth:2}} />
            );
        }

        return (
            [
                <g key={'ClockNumbers_block'}>
                    {clockNumbers}
                </g>,
                <g key={'ClockMarkers_block'}>
                    {clockMarkers}
                </g>
            ]
        );
    }

    getTwoupSquares(){
        let twoupSquares = [];

        for (let i=4; i<=27; i++){
            twoupSquares.push(
                <rect key={'twoupSquares_'+i} width={(SafeeEWDGraphicalView.widthFactor*4) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*3) + 'px'} x={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*52) + 'px'} rx="0" ry="0" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} />
            );
        }

        return (
            <g>
                <rect width={(SafeeEWDGraphicalView.widthFactor*16) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*3) + 'px'} x="0px" y={(SafeeEWDGraphicalView.heightFactor*52) + 'px'} rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 53,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 3 --> */}
                <text x={SafeeEWDGraphicalView.widthFactor} y={(SafeeEWDGraphicalView.heightFactor*54.3)}>2-up</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 13) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}

                {twoupSquares}
            </g>
        );
    }

    getWorkAndRestSquaresAndLines(){
        let workSquares = [];

        for (let i=4; i<=27; i++){
            workSquares.push(
                <rect key={'workSquares_'+i} width={(SafeeEWDGraphicalView.widthFactor*4) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*55) + 'px'} rx="0" ry="0" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} />
            );
        }

        let restSquares = [];

        for (let i=4; i<=27; i++){
            restSquares.push(
                <rect key={'restSquares_'+i} width={(SafeeEWDGraphicalView.widthFactor*4) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x={(SafeeEWDGraphicalView.widthFactor*4*i) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*59) + 'px'} rx="0" ry="0" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} />
            );
        }

        return (
            [
                <g key={'workevents_block'}>
                    <rect width={(SafeeEWDGraphicalView.widthFactor*16) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x="0px" y={(SafeeEWDGraphicalView.heightFactor*55) + 'px'} rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 56,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 4 --> */}
                    <text x={SafeeEWDGraphicalView.widthFactor} y={(SafeeEWDGraphicalView.heightFactor*57.95)}>Work</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 53) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}

                    {workSquares}
                </g>,
                <g key={'restevents_block'}>
                    <rect width={(SafeeEWDGraphicalView.widthFactor*16) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x="0px" y={(SafeeEWDGraphicalView.heightFactor*59) + 'px'} rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 60,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 4 --> */}
                    <text x={SafeeEWDGraphicalView.widthFactor} y={(SafeeEWDGraphicalView.heightFactor*62)}>Rest</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 53) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}

                    {restSquares}
                </g>,
                <g key={'workrestartelements_block'}>
                    {this.getWorkRestLinesGraphicalElements()}
                </g>
            ]
        );
    }

    getWorkRestLinesGraphicalElements() {
        let previousSafeeTimezone = (this.state.timezoneIndex==0)?null:this.state.timezonesForTheDay[this.state.timezoneIndex - 1];
        let safeeTimezone = (this.state.timezonesForTheDay.length > 0)?this.state.timezonesForTheDay[this.state.timezoneIndex]:null;
        let nextSafeeTimezone = (this.state.timezoneIndex >=this.state.timezonesForTheDay.length-1)?null:this.state.timezonesForTheDay[this.state.timezoneIndex + 1];

        let dateProcessed = this.getTimeZoneDateForACalendarDate(this.state.graphicalViewDate, safeeTimezone.timezone, safeeTimezone.timeOffset);

        let nextDate = dateProcessed.clone().add(1, "days");

        if (parseInt(nextDate.format("HH")) >= 23){
            nextDate.add(1, "hours");
        }else if (parseInt(nextDate.format("HH")) === 1){
            nextDate.subtract(1, "hours");
        }

        let graphicalElements = [];

        if (!dateProcessed.isBefore(this.state.today) || (previousSafeeTimezone != null && !safeeTimezone.datetime.isBefore(this.state.today))){
            return graphicalElements;
        }

        if (safeeTimezone == null){      // to be checked if index == -1 or the date of the event is post date parameter
            return this.getNoEventsWorkRestBarsForADateGraphicalElements();
        }else {

            let firstEventEntity = null;
            let currentEventIndex = 0;

            for (let i=0; i<this.state.events.length; i++){
                if (!this.buildDateObj(this.state.events[i], "startTime", "timezone", "timeOffset").isBefore(safeeTimezone.datetime)){ // equal or after
                    firstEventEntity = this.state.events[i];
                    currentEventIndex = i;
                    break;
                }
            }

            if (firstEventEntity == null ||
                (firstEventEntity != null &&
                    (
                        (   !this.buildDateObj(firstEventEntity, "startTime", "timezone", "timeOffset").isBefore(nextDate)) ||
                            (   nextSafeeTimezone!= null && !this.buildDateObj(firstEventEntity, "startTime", "timezone", "timeOffset").isBefore(nextSafeeTimezone.datetime))
                    )
                )
            ){
                return this.getNoEventsWorkRestBarsForADateGraphicalElements();
            }else{

                let i = currentEventIndex;

                if (this.state.events.length > 0) {

                    let firstEvent = true;

                    let lastBarEnd = SafeeEWDGraphicalView.widthFactor * 16;

                    let isDaylightSavingStart=false;

                    if (safeeTimezone != null && safeeTimezone.datetime.isAfter(dateProcessed)){
                        if (previousSafeeTimezone != null) {
                            let diffInMillies = Math.abs(safeeTimezone.datetime.valueOf() - dateProcessed.valueOf());

                            let offsetTransition = safeeTimezone.getTransitionInSeconds(previousSafeeTimezone);

                            if (offsetTransition > 0){
                                isDaylightSavingStart = true;
                            }

                            diffInMillies += offsetTransition * 1000;

                            let timezoneOffset =  (Math.floor(diffInMillies / 3600000) * 4 * SafeeEWDGraphicalView.widthFactor) + (((diffInMillies / 60000) % 60)*(SafeeEWDGraphicalView.widthFactor/15));

                            lastBarEnd += timezoneOffset;
                        }
                    }

                    while (true) {
                        if (i>=this.state.events.length){
                            break;
                        }

                        let event = this.state.events[i];

                        let eventDate = this.buildDateObj(event, "startTime", "timezone", "timeOffset");

                        if (nextSafeeTimezone != null && !eventDate.isBefore(nextSafeeTimezone.datetime)){ // may not be required as detection of nexttimezone will happen as part of previous event ore previous conditions
                            break; // may not be required as detection of nexttimezone will happen as part of previous event ore previous conditions
                        }

                        if (!eventDate.isBefore(nextDate)){
                            break;
                        }

                        let prevEvent = null;
                        let nextEvent = null;

                        if (i>0){
                            prevEvent = this.state.events[i-1];
                        }else{
                            prevEvent = this.getClosestPreviousEvent(eventDate);
                        }

                        if (i<this.state.events.length-1){
                            nextEvent = this.state.events[i+1];
                        }else{
                            nextEvent = this.getClosestNextEvent(eventDate);
                        }

                        if (!eventDate.isBefore(safeeTimezone.datetime)) {   // TODO: may need to be safeeTimeZone.datetime

                            let hour = parseInt(eventDate.format("HH"));
                            let minute = parseInt(eventDate.format("mm"));

                            if (firstEvent) {

                                let firstBarWidth;

                                if (!(hour == 0 && minute == 0)) {
                                    let diffInMillies = 0;
                                    let startHour = 0;
                                    let startMinute = 0;

                                    if (safeeTimezone != null && previousSafeeTimezone != null){
                                        diffInMillies = Math.abs((eventDate.valueOf() - safeeTimezone.datetime.valueOf()));

                                        startHour = parseInt(safeeTimezone.datetime.format("HH"));
                                        startMinute = parseInt(safeeTimezone.datetime.format("mm"));
                                    }else {
                                        diffInMillies = Math.abs(eventDate.valueOf() - safeeTimezone.datetime.valueOf());
                                    }

                                    firstBarWidth = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000);

                                    // 1
                                    let eventTotalTime = Math.floor(diffInMillies / (60*60*1000)).toString().padStart(2, '0')+":" + (Math.floor(diffInMillies /( 60*1000))%60).toString().padStart(2, '0');

                                    graphicalElements.push(
                                        <g key={"eventlinesgraphical_1_"+event.attributes.id.value}>
                                            <rect
                                                key={'eventlines_1_'+event.attributes.id.value}
                                                width={(firstBarWidth) + 'px'}
                                                height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                                                x={lastBarEnd}
                                                y={(event.attributes.eventType.value.toLowerCase() === 'work')?( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55)):(SafeeEWDGraphicalView.heightFactor*55)}
                                                rx="0"
                                                ry="0"
                                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                                {
                                                    (firstBarWidth <= 40) &&
                                                    <title>{eventTotalTime}</title>
                                                }
                                            </rect>
                                            {
                                                (firstBarWidth > 40) &&
                                                <text
                                                    x={(lastBarEnd+(firstBarWidth/2))}
                                                    y={
                                                        ((event.attributes.eventType.value.toLowerCase() === 'work')?
                                                            ((4*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55)):
                                                            (SafeeEWDGraphicalView.heightFactor*55)) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                                    }
                                                    dominantBaseline="middle"
                                                    textAnchor="middle">
                                                    {eventTotalTime}
                                                </text>
                                            }
                                        </g>
                                    );

                                  //if (event.startTime!.isAtSameMomentAs(safeeTimezone.datetime!)) {//XXXXXXXX

                                    // 2
//                                    graphicalElements.push(
//                                        <line
//                                            key={"eventlines_2_"+event.attributes.id.value}
//                                            x1={lastBarEnd + firstBarWidth}
//                                            y1={
//                                                ((SafeeEWDGraphicalView.heightFactor * 57.25) - (SafeeEWDGraphicalView.heightFactor / 2))
//                                            }
//                                            x2={lastBarEnd+firstBarWidth}
//                                            y2={
//                                                ((SafeeEWDGraphicalView.heightFactor * 57.25) - (SafeeEWDGraphicalView.heightFactor / 2)) + (4 * SafeeEWDGraphicalView.heightFactor + (SafeeEWDGraphicalView.heightFactor / 2))
//                                            }
//                                            style={{"stroke": "rgb(255,0,0)", "strokeWidth": (SafeeEWDGraphicalView.heightFactor/2)}}
//                                            onClick={()=>{alert('Event Clicked')}}
//                                        >
//                                        </line>
//                                    );

                                //  }

                                    // new code begin
                                    let previousEvent;

                                    if (i==0) {
                                        previousEvent = this.getClosestPreviousEvent(
                                                            this.getTimeZoneDateForACalendarDate(dateProcessed,
                                                            this.state.timezonesForTheDay[this.state.timezoneIndex].timezone,
                                                            this.state.timezonesForTheDay[this.state.timezoneIndex].timeOffset)
                                                        );
                                    }else{
                                        previousEvent = this.state.events[i-1];
                                    }

                                    if (previousEvent != null && (previousEvent.attributes.isTwoUp.value??false)) {
                                        // 3

                                        graphicalElements.push(
                                            <rect
                                                key={'eventlines_3_'+event.attributes.id.value}
                                                width={(firstBarWidth) + 'px'}
                                                height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                                x={lastBarEnd}
                                                y={SafeeEWDGraphicalView.heightFactor*53}
                                                rx="0"
                                                ry="0"
                                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                            </rect>
                                        );
                                    }else{
                                        // try {
                                        let twoUpEvent = this.lastTwoUpEventBefore(moment(event.attributes.startTime.value));
                                        if (twoUpEvent != null && twoUpEvent.attributes.eventType.value.toLowerCase() === "twoupstart"){
                                            // 4
                                            graphicalElements.push(
                                                <rect
                                                    key={'eventlines_4_'+event.attributes.id.value}
                                                    width={(firstBarWidth) + 'px'}
                                                    height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                                    x={lastBarEnd}
                                                    y={SafeeEWDGraphicalView.heightFactor*53}
                                                    rx="0"
                                                    ry="0"
                                                    style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                                </rect>
                                            );
                                        }
                                        // }catch(e){}
                                    }
                                    // new code end

                                    if (safeeTimezone.datetime.isSame(eventDate) && safeeTimezone.datetime.isAfter(dateProcessed)){
                                        // should not happen
                                    }else{
                                        if (safeeTimezone != null && previousSafeeTimezone != null) {
                                            if ((previousSafeeTimezone.timezone != null && previousSafeeTimezone.timezone.toLowerCase() === safeeTimezone.timezone) && previousSafeeTimezone.getTransitionInSeconds(safeeTimezone) < 0) {
                                                // 5
                                                graphicalElements.push(
                                                    <text
                                                        key={"eventtext_5_"+event.attributes.id.value}
                                                        x={SafeeEWDGraphicalView.widthFactor*16 + (startHour * SafeeEWDGraphicalView.widthFactor * 4) + (startMinute*(SafeeEWDGraphicalView.widthFactor/15)) - (2*SafeeEWDGraphicalView.widthFactor)}
                                                        y={SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2}
                                                        transform={"rotate(270 "+(SafeeEWDGraphicalView.widthFactor*16 + (startHour * SafeeEWDGraphicalView.widthFactor * 4) + (startMinute*(SafeeEWDGraphicalView.widthFactor/15)) - (2*SafeeEWDGraphicalView.widthFactor))+","+(SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2)+")"}
                                                        style={{"stroke": "none", "fill": "#000000", "fontSize": "0.7rem" }}>
                                                            DAYLIGHT SAVING START
                                                    </text>
                                                );
                                            }else{
//                                                if (i>0 && event.attributes.eventType.value.toLowerCase() !== this.state.events[i-1].attributes.eventType.value.toLowerCase() && moment(event.attributes.startTime.value).isSame(safeeTimezone.datetime)) {
//                                                    // 6
//                                                    graphicalElements.push(
//                                                        <line
//                                                            key={"eventlines_6_"+event.attributes.id.value}
//                                                            x1={lastBarEnd}
//                                                            y1={
//                                                                ((SafeeEWDGraphicalView.heightFactor * 56) - (SafeeEWDGraphicalView.heightFactor / 2))
//                                                            }
//                                                            x2={lastBarEnd}
//                                                            y2={
//                                                                ((SafeeEWDGraphicalView.heightFactor * 56) - (SafeeEWDGraphicalView.heightFactor / 2)) +(4 * SafeeEWDGraphicalView.heightFactor)
//                                                            }
//                                                            style={{"stroke": "rgb(255,0,0)", "strokeWidth": (SafeeEWDGraphicalView.heightFactor/2)}}
//                //                                            onClick={
//                //                                                alert('Event Clicked');
//                //                                                    null
//                //                                                    //await Navigator.pushNamed(context, DisplayActivity.routeName, arguments: [event, nextEvent, 'GRAPHICALVIEW']);
//                //                                            }
//                                                        >
//                                                        </line>
//                                                    );
//                                                }
                                            }
                                        }
                                    }
                                    lastBarEnd += firstBarWidth;
                                }
                            }
                            firstEvent = false;
                        }

                        let width;
                        let targetHour = -1;
                        let targetMinute = -1;
                        let postFix1 = "";
                        let postFix2 = "";

                        let diffInMillies = 0;

                        if (i+1>=this.state.events.length) {   /// DO CHECK FOR NEXTDATE vs nexttimezone


                            if (nextSafeeTimezone != null && nextSafeeTimezone.datetime.isBefore(nextDate)) {

                                if (this.state.today.isSameOrAfter(nextSafeeTimezone.datetime)) {
                                    diffInMillies = Math.abs(nextSafeeTimezone.datetime.valueOf() - eventDate.valueOf());
                                    targetHour = nextSafeeTimezone.datetime.format("HH");
                                    targetMinute = nextSafeeTimezone.datetime.format("mm");
                                }else{
                                    diffInMillies =Math.abs(this.state.today.valueOf() - eventDate.valueOf());

                                    targetHour = this.state.today.format("HH");
                                    targetMinute = this.state.today.format("mm");
                                }

                            }else {
                                if (this.state.today.isSameOrAfter(nextDate)) {
                                    diffInMillies = Math.abs(nextDate.valueOf() - eventDate.valueOf());
                                }else{
                                    diffInMillies = Math.abs(this.state.today.valueOf() - eventDate.valueOf());

                                    targetHour = this.state.today.format("HH");
                                    targetMinute = this.state.today.format("mm");
                                }

                            }

                            width = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000);

                        } else {
                            let nextEvent = this.state.events[i + 1];
                            let nextEventDate = moment(nextEvent.attributes.startTime.value);
                            if (nextEventDate.isBefore(nextDate)) {
                                if (nextSafeeTimezone == null || (nextSafeeTimezone != null && nextSafeeTimezone.datetime.isSameOrAfter(nextDate))) {
                                    diffInMillies = Math.abs(nextEventDate.valueOf() - eventDate.valueOf());
                                    width = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000);
                                } else if (nextEventDate.isBefore(nextSafeeTimezone.datetime)) {
                                    diffInMillies = Math.abs(nextEventDate.valueOf() - eventDate.valueOf());
                                    width = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000);
                                } else {

                                    let tzDateTime;

                                    if (this.state.today.isSameOrAfter(nextSafeeTimezone.datetime)) {
                                        diffInMillies = Math.abs(nextSafeeTimezone.datetime.valueOf() - eventDate.valueOf());
                                        tzDateTime = nextSafeeTimezone.datetime;

                                        if (nextSafeeTimezone.timezone != null && nextSafeeTimezone.timezone.toLowerCase() === safeeTimezone.timezone) {
                                            if (nextSafeeTimezone.getTransitionInSeconds(safeeTimezone) < 0){
                                                tzDateTime = tzDateTime.subtract(1, "minutes");
                                                postFix1 = "+1";
                                                postFix2 = "DAYLIGHT SAVING END";
                                            }else if (nextSafeeTimezone.getTransitionInSeconds(safeeTimezone) > 0){
                                                tzDateTime = tzDateTime.subtract(1, "minutes");
                                                postFix1 = "+1";
                                            }
                                        }
                                    }else{
                                        diffInMillies = Math.abs(this.state.today.valueOf() - eventDate.valueOf());
                                        tzDateTime = this.state.today;
                                    }

                                    width = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000);

                                    targetHour = tzDateTime.format("HH");
                                    targetMinute = tzDateTime.format("mm");
                                }
                            } else {

                                if (nextSafeeTimezone != null && nextSafeeTimezone.datetime.isBefore(nextDate)) { // second condition is of possible nexttimezone skipping nextDate because of increase or decrease ot clock but keeping same calendar date
                                    if (this.state.today.isSameOrAfter(nextSafeeTimezone.datetime)) {
                                        diffInMillies = Math.abs(nextSafeeTimezone.datetime.valueOf() - eventDate.valueOf());
                                        targetHour = nextSafeeTimezone.datetime.format("HH");
                                        targetMinute = nextSafeeTimezone.datetime.format("mm");
                                    }else{
                                        diffInMillies = (this.state.today.valueOf() - eventDate.valueOf()).abs();
                                        targetHour = this.state.today.format("HH");
                                        targetMinute = this.state.today.format("mm");
                                    }

                                } else {
                                    if (this.state.today.isSameOrAfter(nextDate)) {
                                        diffInMillies = Math.abs(nextDate.valueOf() - eventDate.valueOf());
                                    }else{
                                        diffInMillies = Math.abs(this.state.today.valueOf() -eventDate.valueOf());
                                        targetHour = this.state.today.format("HH");
                                        targetMinute = this.state.today.format("mm");
                                    }
                                }
                                width = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000);
                            }
                        }

                        if (targetHour > -1 || targetMinute > -1){

                            if (postFix2 !== ""){

                            // 7
                                graphicalElements.push(

                                    <text
                                        key={"eventlines_7_"+event.attributes.id.value}
                                        x={lastBarEnd - (SafeeEWDGraphicalView.widthFactor/2)}
                                        y={SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2}
                                        transform={"rotate(270 "+(lastBarEnd - (SafeeEWDGraphicalView.widthFactor/2))+","+(SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2)+")"}
                                        style={{"stroke": "none", "fill": "#000000", "fontSize": "0.7rem" }}>
                                            {postFix2}
                                    </text>
                                );
                            }
                        }

                        // 8
                        graphicalElements.push(
                            <text
                                key={"eventtext_8_"+event.attributes.id.value}
                                x={lastBarEnd - (SafeeEWDGraphicalView.widthFactor/2)}
                                y={SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2}
                                transform={"rotate(270 "+(lastBarEnd + (SafeeEWDGraphicalView.widthFactor/2))+","+(SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2)+")"}
                                style={{"stroke": "none", "fill": "#000000", "fontSize": "0.7rem" }}>
                                    {this.getLocation(event)}
                                <title>
                                    {this.getFullLocation(event)}
                                </title>
                            </text>
                        );

                        // 9
                        let eventTotalTime = Math.floor(diffInMillies / (60*60*1000)).toString().padStart(2, '0')+ ":" + (Math.floor(diffInMillies /( 60*1000))%60).toString().padStart(2, '0')

                        graphicalElements.push(
                            <g key={"eventlinesgraphical_9_"+event.attributes.id.value}>
                                <rect
                                    key={"eventlines_9_"+event.attributes.id.value}
                                    width={(width) + 'px'}
                                    height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                                    x={lastBarEnd}
                                    y={(event.attributes.eventType.value.toLowerCase() === 'work')?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                                    rx="0"
                                    ry="0"
                                    style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                    {
                                        (width <= 40) &&
                                        <title>{eventTotalTime}</title>
                                    }
                                </rect>
                                {
                                    (width > 40) &&
                                    <text
                                        x={(lastBarEnd+(width/2))}
                                        y={
                                            ((event.attributes.eventType.value.toLowerCase() === 'work')?
                                                (SafeeEWDGraphicalView.heightFactor*55):
                                                ( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                        }
                                        dominantBaseline="middle"
                                        textAnchor="middle">
                                        {eventTotalTime}
                                    </text>
                                }
                            </g>
                        )

                        // new code start

                        if (event.attributes.isTwoUp.value??false){
                            // 10
                            graphicalElements.push(
                                <rect
                                    key={'eventlines_10_'+event.attributes.id.value}
                                    width={(width) + 'px'}
                                    height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                    x={lastBarEnd}
                                    y={SafeeEWDGraphicalView.heightFactor*53}
                                    rx="0"
                                    ry="0"
                                    style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                </rect>

//                                <line
//                                    key={"eventlines_10_"+event.attributes.id.value}
//                                    x1={lastBarEnd}
//                                    y1={
//                                        ((SafeeEWDGraphicalView.heightFactor * 52.25) + (SafeeEWDGraphicalView.heightFactor) + SafeeEWDGraphicalView.heightFactor / 4)
//                                    }
//                                    x2={lastBarEnd+width}
//                                    y2={
//                                        ((SafeeEWDGraphicalView.heightFactor * 52.25) + (SafeeEWDGraphicalView.heightFactor) + SafeeEWDGraphicalView.heightFactor / 4)
//                                    }
//                                    style={{"stroke": "rgb(0,0,0)", "strokeWidth": (SafeeEWDGraphicalView.heightFactor/2)}}
//                                    onClick={ () => {
//                                            alert('Event Clicked');
//                                            //await Navigator.pushNamed(context, DisplayActivity.routeName, arguments: [prevEvent, event, 'GRAPHICALVIEW']);
//                                        }
//                                    }
//                                >
//                                </line>
                            );
                        }

                        // new code end

//                        if (i+1<this.state.events.length && event.attributes.eventType.value.toLowerCase() !== this.state.events[i+1].attributes.eventType.value.toLowerCase() &&
//                            (
//                              nextSafeeTimezone == null ||
//                              !nextSafeeTimezone.datetime.isBefore(moment(this.state.events[i+1].attributes.startTime.value))
//                            )
//
//                        ) {
//                            graphicalElements.push(
//                                <line
//                                    key={"eventlines_11_"+event.attributes.id.value}
//                                    x1={lastBarEnd + width}
//                                    y1={
//                                        ((SafeeEWDGraphicalView.heightFactor * 57.25) - (SafeeEWDGraphicalView.heightFactor / 2))
//                                    }
//                                    x2={lastBarEnd + width }
//                                    y2={
//                                        ((SafeeEWDGraphicalView.heightFactor * 57.25) - (SafeeEWDGraphicalView.heightFactor / 2)) + (4 * SafeeEWDGraphicalView.heightFactor + (SafeeEWDGraphicalView.heightFactor / 2))
//                                    }
//                                    style={{"stroke": "rgb(255,0,0)", "strokeWidth": (SafeeEWDGraphicalView.heightFactor/2)}}
//                                    onClick={ () => {
//
//                                            alert('Event Clicked');
//
//                                            //await Navigator.pushNamed(context, DisplayActivity.routeName, arguments: [prevEvent, event, 'GRAPHICALVIEW']);
//                                        }
//                                    }
//                                >
//                                </line>
//                            );
//                        }

                        lastBarEnd += width;

                        i++;
                    }
                }

                return graphicalElements;
            }
        }
    }

    getNoEventsWorkRestBarsForADateGraphicalElements() {

        let previousSafeeTimezone = (this.state.timezoneIndex==0)?null:this.state.timezonesForTheDay[this.state.timezoneIndex - 1];
        let safeeTimezone = (this.state.timezonesForTheDay.length > 0)?this.state.timezonesForTheDay[this.state.timezoneIndex]:null;
        let nextSafeeTimezone = (this.state.timezoneIndex >=this.state.timezonesForTheDay.length-1)?null:this.state.timezonesForTheDay[this.state.timezoneIndex + 1];

        let dateProcessed = this.getTimeZoneDateForACalendarDate(this.state.graphicalViewDate, safeeTimezone.timezone, safeeTimezone.timeOffset);

        let nextDate = dateProcessed.clone().add(1, "days");

        if (parseInt(nextDate.format("HH")) >= 23){
            nextDate.add(1, "hours");
        }else if (parseInt(nextDate.format("HH")) === 1){
            nextDate.subtract(1, "hours");
        }


        let workOrRest = false;
        // new code start
        let isTwoUp = false;
        // new code end
        if (dateProcessed.isSameOrAfter(this.state.today) || (previousSafeeTimezone != null && this.state.today.isBefore(safeeTimezone.datetime) )){
          return [];
        }

        let currentEventIndex = 0;

        let startEvent;
        let nextEvent;

        if (this.state.events.length > 0) {
            for (let i = 0; i < this.state.events.length; i++) {
                if (moment(this.state.events[i].attributes.startTime.value).isSame(safeeTimezone.datetime)) { // equal or after
                    startEvent = this.state.events[i];
                    currentEventIndex = i;

                    if (i < this.state.events.length - 1) {
                        nextEvent = this.state.events[i + 1];
                    } else {
                        nextEvent = this.getClosestNextEvent(this.state.events[i].startTime);
                    }
                    break;
                } else if (moment(this.state.events[i].attributes.startTime.value).isAfter(safeeTimezone.datetime)) { // equal or after
                    currentEventIndex = i;
                    if (i == 0) {
                        startEvent = this.getClosestPreviousEvent(this.state.events[i].startTime);
                    } else {
                        startEvent = this.state.events[i - 1];
                    }

                    nextEvent = this.state.events[i];

                    break;
                }
            }

            if (startEvent == null && safeeTimezone != null && safeeTimezone.datetime != null) {
                startEvent = this.getClosestPreviousEvent(safeeTimezone.datetime);
                nextEvent = this.getClosestNextEvent(safeeTimezone.datetime);
            }
        }else {
            if (safeeTimezone != null && safeeTimezone.datetime != null) {
                startEvent = this.getClosestPreviousEvent(safeeTimezone.datetime);
                nextEvent = this.getClosestNextEvent(safeeTimezone.datetime);
            }
        }

        if (currentEventIndex > 0) {
            let previousEvent = this.state.events[currentEventIndex - 1];
            workOrRest = (previousEvent.attributes.eventType.value.toLowerCase() === 'work');
            isTwoUp = previousEvent.attributes.isTwoUp.value??false;
        } else {
            let nextEvent = (currentEventIndex >= this.state.events.length)?null:this.state.events[currentEventIndex];
            if (nextEvent != null) {
                workOrRest = (nextEvent.attributes.eventType.value.toLowerCase() === 'rest');

                let previousEvent = this.getClosestPreviousEvent(safeeTimezone.datetime);

                if (previousEvent != null){
                    isTwoUp = previousEvent.attributes.isTwoUp.value??false;
                }else{
                    let twoUpEvent = this.lastTwoUpEventBefore(safeeTimezone.datetime);
                    if (twoUpEvent != null){
                        isTwoUp = (twoUpEvent.attributes.eventType.value.toLowerCase() === "twoupstart");
                    }
                }
            }else{
                let previousEvent = this.getClosestPreviousEvent(safeeTimezone.datetime);
                if (previousEvent != null) {
                    workOrRest = previousEvent.attributes.eventType.value.toLowerCase() === "work";
                    isTwoUp = previousEvent.attributes.isTwoUp.value??false;
                }else{
                    let twoUpEvent = this.lastTwoUpEventBefore(safeeTimezone.datetime);
                    if (twoUpEvent != null){
                        isTwoUp = (twoUpEvent.attributes.eventType.value.toLowerCase() === "twoupstart");
                    }
                }
            }
        }

        if (safeeTimezone == null) {
            if (this.state.today.isBefore(nextDate)) {
                let diffInMillies = Math.abs(this.state.today.valueOf() - dateProcessed.valueOf());

                let eventTotalTime = Math.floor(diffInMillies / (60*60*1000)).toString().padStart(2, '0')+":" + (Math.floor(diffInMillies /( 60*1000))%60).toString().padStart(2, '0');

                return [
                    // 11
                    <g key={"noeventlinesgraphical_11"+safeeTimezone.toString()}>
                        <rect
                            key={"noeventlines_11_"}
                            width={((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) + 'px'}
                            height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                            x={SafeeEWDGraphicalView.widthFactor * 16}
                            y={(workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                            rx="0"
                            ry="0"
                            style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                            {
                                (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) <= 40) &&
                                <title>{eventTotalTime}</title>
                            }
                        </rect>
                        {
                            (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) > 40) &&
                            <text
                                x={((SafeeEWDGraphicalView.widthFactor * 16)+(((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000))/2))}
                                y={
                                    ((workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                }
                                dominantBaseline="middle"
                                textAnchor="middle">
                                {eventTotalTime}
                            </text>
                        }
                    </g>,
                    // 12
                    ... (isTwoUp)?[

                        <rect
                            key={'noeventlines_12_'}
                            width={((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) + 'px'}
                            height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                            x={SafeeEWDGraphicalView.widthFactor * 16}
                            y={SafeeEWDGraphicalView.heightFactor*53}
                            rx="0"
                            ry="0"
                            style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                        </rect>
                    ]:[]
                ];
            } else {
                return [
                    // 13
                    <g key={"noeventlinesgraphical_13"+safeeTimezone.toString()}>
                        <rect
                            key={'noeventlines_13_'}
                            width={(((SafeeEWDGraphicalView.widthFactor/15) * (24 * 60))) + 'px'}
                            height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                            x={SafeeEWDGraphicalView.widthFactor * 16}
                            y={(workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                            rx="0"
                            ry="0"
                            style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                        </rect>
                        {
                            (((SafeeEWDGraphicalView.widthFactor/15) * (24 * 60)) > 40) &&
                            <text
                                x={((SafeeEWDGraphicalView.widthFactor * 16)+(((SafeeEWDGraphicalView.widthFactor/15) * (24 * 60))/2))}
                                y={
                                    ((workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                }
                                dominantBaseline="middle"
                                textAnchor="middle">
                                    24:00
                            </text>
                        }
                    </g>,

                    // 14
                    ... (isTwoUp)?[
                        <rect
                            key={'noeventlines_14_'}
                            width={((SafeeEWDGraphicalView.widthFactor/15) * (24 * 60)) + 'px'}
                            height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                            x={SafeeEWDGraphicalView.widthFactor * 16}
                            y={SafeeEWDGraphicalView.heightFactor*53}
                            rx="0"
                            ry="0"
                            style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                        </rect>
                    ]:[]
                ];
            }

        }else {
            if (previousSafeeTimezone != null) {

                if (nextSafeeTimezone != null && nextSafeeTimezone.datetime.isBefore(nextDate)) {

                    let offsetTransition = safeeTimezone.getTransitionInSeconds(previousSafeeTimezone);

                    let left = (SafeeEWDGraphicalView.widthFactor * 16) + Math.floor((Math.abs(safeeTimezone.datetime.valueOf() - dateProcessed.valueOf()) + (offsetTransition * 1000)) / 60000);

                    let graphicalElements = [];

                    if (safeeTimezone.timezone != null && safeeTimezone.timezone.toLowerCase() === previousSafeeTimezone.timezone) {

                        if (offsetTransition > 0){

                            // 15
                            graphicalElements.push(
                                <text
                                    key={"noeventlines_15_"+safeeTimezone.toString()}
                                    x={left - (2*SafeeEWDGraphicalView.widthFactor)}
                                    y={SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2}
                                    transform={"rotate(270 "+(left - (2*SafeeEWDGraphicalView.widthFactor))+","+(SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2)+")"}
                                    style={{"stroke": "none", "fill": "#000000", "fontSize": "0.7rem" }}>
                                        DAYLIGHT SAVING START
                                </text>
                            );
                        }
                    }

                    let diffInMillies = 0;

                    if (this.state.today.isBefore(nextSafeeTimezone.datetime)) {
                        diffInMillies = Math.abs(this.state.today.valueOf() - safeeTimezone.datetime.valueOf());
                    } else {
                        diffInMillies = Math.abs(nextSafeeTimezone.datetime.valueOf() - safeeTimezone.datetime.valueOf());
                    }

                    let eventTotalTime = Math.floor(diffInMillies / (60*60*1000)).toString().padStart(2, '0')+":" + (Math.floor(diffInMillies /( 60*1000))%60).toString().padStart(2, '0');

                    graphicalElements.push(
                        // 16
                        <g key={"noeventlinesgraphical_16"+safeeTimezone.toString()}>
                            <rect
                                key={'noeventlines_16_'+safeeTimezone.toString()}
                                width={((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) + 'px'}
                                height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                                x={left}
                                y={(workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                                rx="0"
                                ry="0"
                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                {
                                    (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) <= 40) &&
                                    <title>{eventTotalTime}</title>
                                }
                            </rect>
                            {
                                (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) > 40) &&
                                <text
                                    x={(left+(((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000))/2))}
                                    y={
                                        ((workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                    }
                                    dominantBaseline="middle"
                                    textAnchor="middle">
                                    {eventTotalTime}
                                </text>
                            }
                        </g>
                    );

                    if (isTwoUp){
                        // 17
                        graphicalElements.push(
                            <rect
                                key={'noeventlines_17_'+safeeTimezone.toString()}
                                width={(((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000))) + 'px'}
                                height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                x={left}
                                y={SafeeEWDGraphicalView.heightFactor*53}
                                rx="0"
                                ry="0"
                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                            </rect>
                        );
                    }

                    return graphicalElements;

                } else {

                    let offsetTransition = safeeTimezone.getTransitionInSeconds(previousSafeeTimezone);

                    let left = (SafeeEWDGraphicalView.widthFactor * 16) + ((SafeeEWDGraphicalView.widthFactor/15) * Math.floor((Math.abs(safeeTimezone.datetime.valueOf() - dateProcessed.valueOf()) + (offsetTransition * 1000)) / 60000));

                    let graphicalElements = [];

                    if (safeeTimezone.timezone != null && safeeTimezone.timezone.toLowerCase() === previousSafeeTimezone.timezone) {

                        if (offsetTransition > 0){
                            // 18
                            graphicalElements.push(
                                <text
                                    key={"noeventtext_18_"+safeeTimezone.toString()}
                                    x={left - (2*SafeeEWDGraphicalView.widthFactor)}
                                    y={SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2}
                                    transform={"rotate(270 "+(left - (2*SafeeEWDGraphicalView.widthFactor))+","+(SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2)+")"}
                                    style={{"stroke": "none", "fill": "#000000", "fontSize": "0.7rem" }}>
                                        DAYLIGHT SAVING START
                                </text>
                            );
                        }
                    }

                    let diffInMillies = 0;

                    if (this.state.today.isBefore(nextDate)) {
                        diffInMillies = Math.abs(this.state.today.valueOf() - safeeTimezone.datetime.valueOf());
                    }else{
                        diffInMillies = Math.abs(nextDate.valueOf() - safeeTimezone.datetime.valueOf());
                    }

                    // 19
                    let eventTotalTime = Math.floor(diffInMillies / (60*60*1000)).toString().padStart(2, '0')+ ":" + (Math.floor(diffInMillies /( 60*1000))%60).toString().padStart(2, '0');

                    graphicalElements.push(
                        <g key={"noeventlinesgraphical_19"+safeeTimezone.toString()}>
                            <rect
                                key={"noeventlines_19_"+safeeTimezone.toString()}
                                width={((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) + 'px'}
                                height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                                x={left}
                                y={(workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                                rx="0"
                                ry="0"
                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                {
                                    (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) <= 40) &&
                                    <title>{eventTotalTime}</title>
                                }
                            </rect>
                            {
                                (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) > 40) &&
                                <text
                                    x={(left+(((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000))/2))}
                                    y={
                                        ((workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                    }
                                    dominantBaseline="middle"
                                    textAnchor="middle">
                                    {eventTotalTime}
                                </text>
                            }
                        </g>
                    );

                    if (isTwoUp){
                        // 20
                        graphicalElements.push(
                            <rect
                                key={"noeventlines_20_"+safeeTimezone.toString()}
                                width={((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) + 'px'}
                                height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                x={left}
                                y={SafeeEWDGraphicalView.heightFactor*53}
                                rx="0"
                                ry="0"
                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                            </rect>
                        );
                    }

                    return graphicalElements;
                }
            } else { // XXXXXXXX
                if (nextSafeeTimezone != null) {
                    let end = (SafeeEWDGraphicalView.widthFactor * 16) + ((SafeeEWDGraphicalView.widthFactor/15)*Math.floor(Math.abs(nextSafeeTimezone.datetime.valueOf() - safeeTimezone.datetime.valueOf()) / 60000));

                    let graphicalElements = [];

                    let diffInMillies = 0;
                    //String postFix1 = "";
                    let postFix2 = "";

                    if (this.state.today.isBefore(nextSafeeTimezone.datetime)) {
                        diffInMillies = Math.abs(this.state.today.valueOf() - safeeTimezone.datetime.valueOf());
                    } else {
                        diffInMillies = Math.abs(nextSafeeTimezone.datetime.valueOf() - safeeTimezone.datetime.valueOf());

                        if (nextSafeeTimezone.timezone != null && nextSafeeTimezone.timezone.toLowerCase() === safeeTimezone.timezone) {
                            if (nextSafeeTimezone.getTransitionInSeconds(safeeTimezone) < 0) {
                                //postFix1 = "+1";
                                postFix2 = "DAYLIGHT SAVING END";
                            }// else if (nextSafeeTimezone.getTransitionInSeconds(safeeTimezone) > 0){
                        }
                        //   postFix1 = "+1";
                        // }
                    }

                    if (postFix2 != ""){
                        // 21

                        graphicalElements.push(
                            <text
                                key={"noeventlines_21_"+safeeTimezone.toString()}
                                x={end - (2*SafeeEWDGraphicalView.widthFactor)}
                                y={SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2}
                                transform={"rotate(270 "+(end - (2*SafeeEWDGraphicalView.widthFactor))+","+(SafeeEWDGraphicalView.heightFactor*44 + SafeeEWDGraphicalView.heightFactor/2)+")"}
                                style={{"stroke": "none", "fill": "#000000", "fontSize": "0.7rem" }}>
                                    DAYLIGHT SAVING START
                            </text>
                        );
                    }

                    // 22
                    let eventTotalTime = Math.floor(diffInMillies / (60*60*1000)).toString().padStart(2, '0')+":" +(Math.floor(diffInMillies /( 60*1000))%60).toString().padStart(2, '0');

                    graphicalElements.push(
                        <g key={"noeventlinesgraphical_22"+safeeTimezone.toString()}>
                            <rect
                                key={"noeventlines_22_"+safeeTimezone.toString()}
                                width={((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) + 'px'}
                                height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                                x={SafeeEWDGraphicalView.widthFactor * 16}
                                y={(workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                                rx="0"
                                ry="0"
                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                {
                                    (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) <= 40) &&
                                    <title>{eventTotalTime}</title>
                                }
                            </rect>
                            {
                                (((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) > 40) &&
                                <text
                                    x={((SafeeEWDGraphicalView.widthFactor * 16)+((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)/2))}
                                    y={
                                        ((workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                    }
                                    dominantBaseline="middle"
                                    textAnchor="middle">
                                    {eventTotalTime}
                                </text>
                            }
                        </g>
                    );

                    if (isTwoUp){
                        // 23
                        graphicalElements.push(
                            <rect
                                key={"noeventlines_23_"+safeeTimezone.toString()}
                                width={((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(diffInMillies / 60000)) + 'px'}
                                height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                x={SafeeEWDGraphicalView.widthFactor * 16}
                                y={SafeeEWDGraphicalView.heightFactor*53}
                                rx="0"
                                ry="0"
                                style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                            </rect>
                        );
                    }

                    return graphicalElements;

                } else { // XXXXXX
                    if (this.state.today.isBefore(nextDate)) {
                        let width = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(Math.abs(this.state.today.valueOf() - safeeTimezone.datetime.valueOf()) / 60000);

                        let eventTotalTime = Math.floor(Math.abs(this.state.today.valueOf() - safeeTimezone.datetime.valueOf()) / (60*60*1000)).toString().padStart(2, '0')+":" + (Math.floor(Math.abs(this.state.today.valueOf() - safeeTimezone.datetime.valueOf()) /( 60*1000))%60).toString().padStart(2, '0');

                        return [
                            // 24
                            <g key={"noeventlinesgraphical_24"+safeeTimezone.toString()}>

                                <rect
                                    key={"noeventlines_24_"+safeeTimezone.toString()}
                                    width={(width) + 'px'}
                                    height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                                    x={SafeeEWDGraphicalView.widthFactor * 16}
                                    y={(workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                                    rx="0"
                                    ry="0"
                                    style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                    {
                                        (width <= 40) &&
                                        <title>{eventTotalTime}</title>
                                    }
                                </rect>
                                {
                                    (width > 40) &&
                                    <text
                                        x={((SafeeEWDGraphicalView.widthFactor * 16)+(width/2))}
                                        y={
                                            ((workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                        }
                                        dominantBaseline="middle"
                                        textAnchor="middle">
                                        {eventTotalTime}
                                    </text>
                                }
                            </g>,
                            // 25
                            ... (isTwoUp)?[

                                <rect
                                    key={"noeventlines_25_"+safeeTimezone.toString()}
                                    width={(width) + 'px'}
                                    height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                    x={(SafeeEWDGraphicalView.widthFactor * 16)}
                                    y={SafeeEWDGraphicalView.heightFactor*53}
                                    rx="0"
                                    ry="0"
                                    style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                </rect>
                            ]:[]

                        ];

                    } else {
                        let width = (SafeeEWDGraphicalView.widthFactor/15) * Math.floor(Math.abs(nextDate.valueOf() - safeeTimezone.datetime.valueOf()) / 60000);

                        let eventTotalTime = Math.floor(Math.abs(nextDate.valueOf() - safeeTimezone.datetime.valueOf()) / (60*60*1000)).toString().padStart(2, '0')+ ":" + (Math.floor(Math.abs(nextDate.valueOf() - safeeTimezone.datetime.valueOf()) /( 60*1000))%60).toString().padStart(2, '0');

                        return [
                            // 26
                            <g key={"noeventlinesgraphical_26"+safeeTimezone.toString()}>
                                <rect
                                    key={"noeventlines_26_"+safeeTimezone.toString()}
                                    width={(width) + 'px'}
                                    height={(SafeeEWDGraphicalView.heightFactor*3.8) + 'px'}
                                    x={SafeeEWDGraphicalView.widthFactor * 16}
                                    y={(workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))}
                                    rx="0"
                                    ry="0"
                                    style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                    {
                                        (width <= 40) &&
                                        <title>{eventTotalTime}</title>
                                    }
                                </rect>
                                {
                                    (width > 40) &&
                                    <text
                                        x={((SafeeEWDGraphicalView.widthFactor * 16)+(width/2))}
                                        y={
                                            ((workOrRest)?(SafeeEWDGraphicalView.heightFactor*55):( (4.1*SafeeEWDGraphicalView.heightFactor) + (SafeeEWDGraphicalView.heightFactor*55))) + (SafeeEWDGraphicalView.heightFactor*2.1)
                                        }
                                        dominantBaseline="middle"
                                        textAnchor="middle">
                                        {eventTotalTime}
                                    </text>
                                }
                            </g>,
//
                            // 27
                            ... (isTwoUp)?[

                                <rect
                                    key={"noeventlines_27_"+safeeTimezone.toString()}
                                    width={(width) + 'px'}
                                    height={(SafeeEWDGraphicalView.heightFactor*1) + 'px'}
                                    x={SafeeEWDGraphicalView.widthFactor * 16}
                                    y={SafeeEWDGraphicalView.heightFactor*53}
                                    rx="0"
                                    ry="0"
                                    style={{fill: "#bfbfbf", strokeWidth: 0, stroke:"#999999"}}>
                                </rect>
                            ]:[]
                        ];
                    }
                }
            }
        }
    }

    getTotalWorkRestForADate() {
        let workInMinutes = 0;
        let restInMinutes = 0;

        let previousSafeeTimezone = (this.state.timezoneIndex==0)?null:this.state.timezonesForTheDay[this.state.timezoneIndex - 1];
        let safeeTimezone = (this.state.timezonesForTheDay.length > 0)?this.state.timezonesForTheDay[this.state.timezoneIndex]:null;
        let nextSafeeTimezone = (this.state.timezoneIndex >=this.state.timezonesForTheDay.length-1)?null:this.state.timezonesForTheDay[this.state.timezoneIndex + 1];

        let dateProcessed = this.getTimeZoneDateForACalendarDate(this.state.graphicalViewDate, safeeTimezone.timezone, safeeTimezone.timeOffset);

        let nextDate = dateProcessed.clone().add(1, "days");

        if (parseInt(nextDate.format("HH")) >= 23){
            nextDate.add(1, "hours");
        }else if (parseInt(nextDate.format("HH")) === 1){
            nextDate.subtract(1, "hours");
        }

        let firstEventIndex = 0;

        for (let i=0; i<this.state.events.length; i++){
            firstEventIndex = i;
            if (moment(this.state.events[i].attributes.startTime.value).isSameOrAfter(safeeTimezone.datetime)){
                break;
            }
        }

        if (this.state.events.length == 0){
            let prevEvent = this.getClosestPreviousEvent(safeeTimezone.datetime);
            let diffInMillies = 0;
            let workOrRest = false;

            if (prevEvent != null ){
                workOrRest = prevEvent.attributes.eventType.value.toLowerCase() === "work";
            }else{
                let nextEvent = this.getClosestNextEvent(safeeTimezone.datetime);
                workOrRest = (nextEvent != null && nextEvent.attributes.eventType.value.toLowerCase() === "work");
            }

            if (nextSafeeTimezone == null){
                if (this.state.today.isBefore(nextDate)){
                    diffInMillies = this.state.today.valueOf() - safeeTimezone.datetime.valueOf();
                }else{
                    diffInMillies = nextDate.valueOf() - safeeTimezone.datetime.valueOf();
                }
            }else{
                if (this.state.today.isBefore(nextSafeeTimezone.datetime)){
                    diffInMillies = this.state.today.valueOf() - safeeTimezone.datetime.valueOf();
                }else{
                    diffInMillies = nextSafeeTimezone.datetime.valueOf() - safeeTimezone.datetime.valueOf();
                }
            }

            if (workOrRest){
                workInMinutes = Math.floor(diffInMillies / 60000);
            }else{
                restInMinutes = Math.floor(diffInMillies / 60000);
            }
        }else{

            let diffInMillies = 0;

            if (moment(this.state.events[firstEventIndex].attributes.startTime.value).isBefore(safeeTimezone.datetime)){
                let workOrRest = this.state.events[firstEventIndex].attributes.eventType.value.toLowerCase() === "work";

                if (nextSafeeTimezone == null){
                    if (this.state.today.isBefore(nextDate)){
                        diffInMillies = this.state.today.valueOf() - safeeTimezone.datetime.valueOf();
                    }else{
                        diffInMillies = nextDate.valueOf() - safeeTimezone.datetime.valueOf();
                    }
                }else{
                    if (this.state.today.isBefore(nextSafeeTimezone.datetime)){
                        diffInMillies = this.state.today.valueOf() - safeeTimezone.datetime.valueOf();
                    }else{
                        diffInMillies = nextSafeeTimezone.datetime.valueOf() - safeeTimezone.datetime.valueOf();
                    }
                }

                if (workOrRest){
                    workInMinutes = Math.floor(diffInMillies / 60000);
                }else{
                    restInMinutes = Math.floor(diffInMillies / 60000);
                }
            }else if (nextSafeeTimezone != null && moment(this.state.events[firstEventIndex].attributes.startTime.value).isSameOrAfter(nextSafeeTimezone.datetime)) {
                let workOrRest = this.state.events[firstEventIndex].attributes.eventType.value.toLowerCase() === "rest";

                diffInMillies = nextSafeeTimezone.datetime.valueOf() - safeeTimezone.datetime.valueOf();

                if (workOrRest) {
                    workInMinutes = Math.floor(diffInMillies / 60000);
                } else {
                    restInMinutes = Math.floor(diffInMillies / 60000);
                }
            }else if (moment(this.state.events[firstEventIndex].attributes.startTime.value).isSameOrAfter(nextDate)) {
                let workOrRest = this.state.events[firstEventIndex].attributes.eventType.value.toLowerCase() === "rest";
                if (nextSafeeTimezone == null){
                    diffInMillies = nextDate.valueOf() - safeeTimezone.datetime.valueOf();
                }else{
                    diffInMillies = nextSafeeTimezone.datetime.valueOf() - safeeTimezone.datetime.valueOf();
                }

                if (workOrRest){
                    workInMinutes = Math.floor(diffInMillies / 60000);
                }else{
                    restInMinutes = Math.floor(diffInMillies / 60000);
                }
            }else{

                let workOrRest = this.state.events[firstEventIndex].attributes.eventType.value.toLowerCase() === "rest";

                diffInMillies = moment(this.state.events[firstEventIndex].attributes.startTime.value).valueOf() - safeeTimezone.datetime.valueOf();

                if (workOrRest){
                    workInMinutes = Math.floor(diffInMillies / 60000);
                }else{
                    restInMinutes = Math.floor(diffInMillies / 60000);
                }

                for (let i=firstEventIndex; i<this.state.events.length; i++){

                    if (i == this.state.events.length-1){

                        let workOrRest = this.state.events[i].attributes.eventType.value.toLowerCase() === "work";
                        if (nextSafeeTimezone == null){
                            if (this.state.today.isBefore(nextDate)){
                                diffInMillies = this.state.today.valueOf() - moment(this.state.events[i].attributes.startTime.value).valueOf();
                            }else{
                                diffInMillies = nextDate.valueOf() - moment(this.state.events[i].attributes.startTime.value).valueOf();

                                if (workOrRest){
                                    workInMinutes += Math.floor(diffInMillies / 60000);
                                }else{
                                    restInMinutes +=  Math.floor(diffInMillies / 60000);
                                }

                                break;
                            }
                        }else{
                            if (this.state.today.isBefore(nextSafeeTimezone.datetime)){
                                diffInMillies = this.state.today.valueOf() - moment(this.state.events[i].attributes.startTime.value).valueOf();
                            }else{
                                diffInMillies = nextSafeeTimezone.datetime.valueOf() - moment(this.state.events[i].attributes.startTime.value).valueOf();

                                if (workOrRest){
                                    workInMinutes += Math.floor(diffInMillies / 60000);
                                }else{
                                    restInMinutes +=  Math.floor(diffInMillies / 60000);
                                }

                                break;
                            }
                        }

                        if (workOrRest){
                            workInMinutes += Math.floor(diffInMillies / 60000);
                        }else{
                            restInMinutes +=  Math.floor(diffInMillies / 60000);
                        }
                    }else{
                        let workOrRest = this.state.events[i].attributes.eventType.value.toLowerCase() === "work";
                        let nextEvent = this.state.events[i+1];

                        if (nextSafeeTimezone == null){
                            if (moment(nextEvent.attributes.startTime.value).isBefore(nextDate)){
                                diffInMillies = moment(nextEvent.attributes.startTime.value).valueOf() - moment(this.state.events[i].attributes.startTime.value).valueOf();
                            }else{
                                if (this.state.today.isBefore(nextDate)){
                                    diffInMillies += this.state.today.valueOf() - moment(this.state.events[i].attributes.startTime.value).valueOf();
                                }else{
                                    diffInMillies += nextDate.valueOf() - moment(this.state.events[i].attributes.startTime.value).valueOf();

                                    if (workOrRest){
                                        workInMinutes += Math.floor(diffInMillies / 60000);
                                    }else{
                                        restInMinutes +=  Math.floor(diffInMillies / 60000);
                                    }

                                    break;
                                }
                            }

                        }else{
                            if (nextSafeeTimezone.datetime.isBefore(nextDate)){
                                if (moment(nextEvent.attributes.startTime.value).isBefore(nextSafeeTimezone.datetime)) {
                                    diffInMillies = moment(nextEvent.attributes.startTime.value).valueOf() -
                                    moment(this.state.events[i].attributes.startTime.value).valueOf();
                                } else {
                                    diffInMillies = nextSafeeTimezone.datetime.valueOf() -
                                    moment(this.state.events[i].attributes.startTime.value).valueOf();

                                    if (workOrRest){
                                        workInMinutes += Math.floor(diffInMillies / 60000);
                                    }else{
                                        restInMinutes += Math.floor(diffInMillies / 60000);
                                    }

                                    break;
                                }
                            }else{
                                if (moment(nextEvent.attributes.startTime.value).isBefore(nextDate)) {
                                    diffInMillies = moment(nextEvent.attributes.startTime.value).valueOf() -
                                    moment(this.state.events[i].attributes.startTime.value).valueOf();
                                } else {
                                    diffInMillies = nextDate.valueOf() -
                                    moment(this.state.events[i].attributes.startTime.value).valueOf();

                                    if (workOrRest){
                                        workInMinutes += Math.floor(diffInMillies / 60000);
                                    }else{
                                        restInMinutes += Math.floor(diffInMillies / 60000);
                                    }

                                    break;
                                }
                            }
                        }

                        if (workOrRest){
                            workInMinutes += Math.floor(diffInMillies / 60000);
                        }else{
                            restInMinutes += Math.floor(diffInMillies / 60000);
                        }
                    }
                }
            }
        }

        return [workInMinutes, restInMinutes];
    }

    getPotentialNonComplianceGraphicalElements(){
        let previousSafeeTimezone = (this.state.timezoneIndex==0)?null:this.state.timezonesForTheDay[this.state.timezoneIndex - 1];
        let safeeTimezone = (this.state.timezonesForTheDay.length > 0)?this.state.timezonesForTheDay[this.state.timezoneIndex]:null;
        let nextSafeeTimezone = (this.state.timezoneIndex >=this.state.timezonesForTheDay.length-1)?null:this.state.timezonesForTheDay[this.state.timezoneIndex + 1];

        let dateProcessed = this.getTimeZoneDateForACalendarDate(this.state.graphicalViewDate, safeeTimezone.timezone, safeeTimezone.timeOffset);

        let nextDate = dateProcessed.clone().add(1, "days");

        if (parseInt(nextDate.format("HH")) >= 23){
            nextDate.add(1, "hours");
        }else if (parseInt(nextDate.format("HH")) === 1){
            nextDate.subtract(1, "hours");
        }

        if (safeeTimezone == null || this.state.potentialNonComplianceList.length == 0){
            return [];
        }else {

            let graphicalElements = [];

            for (let i=0; i<this.state.potentialNonComplianceList.length; i++){
                if (moment(this.state.potentialNonComplianceList[i].attributes.dateTime.value).isBefore(safeeTimezone.datetime)) {
                    continue;
                }

                if (nextSafeeTimezone!= null){
                    if (moment(this.state.potentialNonComplianceList[i].attributes.dateTime.value).isSameOrAfter(nextSafeeTimezone.datetime)){
                        break;
                    }
                }else{
                    if (moment(this.state.potentialNonComplianceList[i].attributes.dateTime.value).isSameOrAfter(nextDate)){
                        break;
                    }
                }

                let left = (SafeeEWDGraphicalView.widthFactor * 16) + ((SafeeEWDGraphicalView.widthFactor/15) * Math.floor(Math.abs(moment(this.state.potentialNonComplianceList[i].attributes.dateTime.value).valueOf() - dateProcessed.valueOf()) / 60000));
                let tooltip =  "\n" + (this.state.potentialNonComplianceList[i].attributes.workOrRest.value.toLowerCase() === 'work'?'Work':'Rest') + " Breach Analysis:\n\n" +
                                                              "  -  " + this.state.potentialNonComplianceList[i].attributes.totalWorkOrRestLabel.value + ": " + this.state.potentialNonComplianceList[i].attributes.totalWorkOrRest.value + "\n" +
                                                              "  -  " + (this.state.potentialNonComplianceList[i].attributes.workOrRest.value.toLowerCase() === 'work'?'Max Allowed':'Min Required') + ": " + this.state.potentialNonComplianceList[i].attributes.maxOrMinAllowed.value + "\n" +
                                                              "  -  Category: " + this.state.potentialNonComplianceList[i].attributes.riskLevel.value + "\n" +
                                                              "  -  Breach Time: " + this.state.potentialNonComplianceList[i].attributes.dateTimeStr.value + "\n" +
                                                              "  -  Period: " + this.state.potentialNonComplianceList[i].attributes.period.value + "\n" +
                                                              "  -  Started at: " + this.state.potentialNonComplianceList[i].attributes.periodStartedAtStr.value + "\n";
                graphicalElements.push(
                    <line key={"breaches_invisible_"+i+"_"+this.state.potentialNonComplianceList[i].attributes.id.value} x1={left} x2={left} y1={SafeeEWDGraphicalView.heightFactor*52.5} y2={(SafeeEWDGraphicalView.heightFactor*55) + (SafeeEWDGraphicalView.heightFactor*8)} stroke="rgba(0,0,0,0)" strokeWidth="15">
                        <title>
                            { tooltip }
                        </title>
                    </line>
                );
                graphicalElements.push(
                    <line key={"breaches_"+i+"_"+this.state.potentialNonComplianceList[i].attributes.id.value} x1={left} x2={left} y1={SafeeEWDGraphicalView.heightFactor*52.5} y2={(SafeeEWDGraphicalView.heightFactor*55) + (SafeeEWDGraphicalView.heightFactor*8)} stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeDasharray="1, 6">
                        <title>
                            { tooltip }
                        </title>
                    </line>
                );

            }

          return graphicalElements;
        }
    }

    getFullLocation(event) {
        if ((event.attributes.addressStreetName.value != null && event.attributes.addressStreetName.value !== "") || (event.attributes.addressSuburb.value != null && event.attributes.addressSuburb.value !== "")){
            let location = event.attributes.addressStreetName.value??"";
            if (location !== ""){
                if (event.attributes.addressSuburb.value != null && event.attributes.addressSuburb.value !== ""){
                    location += ", " + event.attributes.addressSuburb.value;
                }
            }else{
                location = event.attributes.addressSuburb.value??"";
            }

            return location;
        }else{
            return event.attributes.location.value??"";
        }
    }

    getLocation(event) {
        if ((event.attributes.addressStreetName.value != null && event.attributes.addressStreetName.value !== "") || (event.attributes.addressSuburb.value != null && event.attributes.addressSuburb.value !== "")){
            let location = event.attributes.addressStreetName.value??"";
            if (location !== ""){
                if (event.attributes.addressSuburb.value != null && event.attributes.addressSuburb.value !== ""){
                    location += ", " + event.attributes.addressSuburb.value;
                }
            }else{
                location = event.attributes.addressSuburb.value??"";
            }
            if (location.length < 28){
                return location;
            }else{
                return location.substring(0, 28) + " ...";
            }
        }else{
            let location = event.attributes.location.value??"";

            if (location.length < 28){
                return location;
            }else{
                return location.substring(0, 28) + " ...";
            }
        }
    }

    getTotals(){
        let totalWorkRestInMinutes = this.getTotalWorkRestForADate();

        return (
            <g>
                <rect width={(SafeeEWDGraphicalView.widthFactor*56) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x="0px" y={(SafeeEWDGraphicalView.heightFactor*63) + 'px'} rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 64,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 4 --> */}
                <text x={SafeeEWDGraphicalView.widthFactor} y={(SafeeEWDGraphicalView.heightFactor*65.9)}>Total Work</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 63) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}
                <text x={(SafeeEWDGraphicalView.widthFactor*29.5) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*65.9)}>
                    {
                        Math.floor(totalWorkRestInMinutes[0] / 60).toString().padStart(2, '0') + ":" + (totalWorkRestInMinutes[0] % 60).toString().padStart(2, '0')
                    }
                </text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 63) + (SafeeEWDGraphicalView.heightFactor * 3) , SafeeEWDGraphicalView.widthFactor * 36 --> */}
                <rect width={(SafeeEWDGraphicalView.widthFactor*56) + 'px'} height={(SafeeEWDGraphicalView.heightFactor*4) + 'px'} x={(SafeeEWDGraphicalView.widthFactor*56) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*63) + 'px'} rx="0px" ry="0px" style={{fill: "white", strokeWidth:2, stroke:"rgb(224,224,224)"}} /> {/* <!--top: SafeeEWDGraphicalView.heightFactor * 64,  SafeeEWDGraphicalView.widthFactor * 16, SafeeEWDGraphicalView.heightFactor * 4 --> */}
                <text x={SafeeEWDGraphicalView.widthFactor * 57} y={(SafeeEWDGraphicalView.heightFactor*65.9)}>Total Rest</text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 53) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}
                <text x={(SafeeEWDGraphicalView.widthFactor*85.5) + 'px'} y={(SafeeEWDGraphicalView.heightFactor*65.9)}>
                    {
                        Math.floor(totalWorkRestInMinutes[1] / 60).toString().padStart(2, '0') + ":" + (totalWorkRestInMinutes[1] % 60).toString().padStart(2, '0')
                    }
                </text> {/* <!-- y = (SafeeEWDGraphicalView.heightFactor * 53) + (SafeeEWDGraphicalView.heightFactor * 3) --> */}
            </g>
        );
    }

    getTimeZones(fromDate, events, comments, annotations, vehicleInfo, workRestOptionEvents, twoUpEvents ) {

        let nextDateMoment = moment(fromDate);
        nextDateMoment.add(1, "days");
        let nextDate = moment(nextDateMoment);

        let safeeTimezones = [];

        let safeeTimezone = null;

        if (events != null) {
            for (let k = 0; k < events.length; k++) {
                let item = events[k];
                if (safeeTimezone === null || !safeeTimezone.equalsEntity(item)) {
                    let eventStartTime = this.buildDateObj(item, "startTime", "timezone", "timeOffset");
                    safeeTimezone = new SafeeTimeZone(eventStartTime, item.attributes.timezone.value, item.attributes.timeOffset.value);
                    safeeTimezones.push(safeeTimezone);
                }
            }
        }

        if (twoUpEvents != null) {
            for (let k = 0; k < twoUpEvents.length; k++) {
                let twoUpEvent = twoUpEvents[k];

                let twoUpEventTimeOfEffect = this.buildDateObj(twoUpEvent, "timeOfEffect", "timezone", "timeOffset");

                if (!this.isSameDay(twoUpEventTimeOfEffect, fromDate)){
                    continue;
                }

                if (safeeTimezones.length === 0) {
                    let newSafeeTimezone = new SafeeTimeZone(twoUpEventTimeOfEffect, twoUpEvent.attributes.timezone.value, twoUpEvent.attributes.timeOffset.value);
                    safeeTimezones.push(newSafeeTimezone);
                } else {
                    let i=0;

                    for (let j = 0; j < safeeTimezones.length; j++) {
                        let tz = safeeTimezones[j];

                        if (i===0 && !tz.datetimeLessThenOrEqual(twoUpEventTimeOfEffect)){
                            if (tz.equalsEntity(twoUpEvent)) {
                                safeeTimezones[i].setDatetime(twoUpEventTimeOfEffect);
                                break;
                            } else {
                                let newSafeeTimezone = new SafeeTimeZone(twoUpEventTimeOfEffect, twoUpEvent.attributes.timezone.value, twoUpEvent.attributes.timeOffset.value);
                                safeeTimezones.splice(0, 0, newSafeeTimezone);
                                break;
                            }
                        }else if (tz.datetimeLessThenOrEqual(twoUpEventTimeOfEffect) &&
                            (safeeTimezones.length === i + 1 ||
                            (safeeTimezones.length > i + 1 && !safeeTimezones[i + 1].datetimeLessThenOrEqual(twoUpEventTimeOfEffect)))) {
                            if (tz.equalsEntity(twoUpEvent)) {
                                break;
                            } else if ((safeeTimezones.length > i + 1) && safeeTimezones[i + 1].equalsEntity(twoUpEvent)) {
                                safeeTimezones[i + 1].setDatetime(twoUpEventTimeOfEffect);
                                break;
                            } else {
                                let newSafeeTimezone = new SafeeTimeZone(twoUpEventTimeOfEffect, twoUpEvent.attributes.timezone.value, twoUpEvent.attributes.timeOffset.value);
                                safeeTimezones.splice(i + 1, 0, newSafeeTimezone);
                                break;
                            }
                        }
                    i++;
                }
            }
          }
        }

        if (comments != null) {
            for (let k = 0; k < comments.length; k++) {
                let comment = comments[k];

                let commentTime = this.buildDateObj(comment, "time", "timezone", "timeOffset");

                if (safeeTimezones.length === 0) {
                    let newSafeeTimezone = new SafeeTimeZone(commentTime, comment.attributes.timezone.value, comment.attributes.timeOffset.value);
                    safeeTimezones.push(newSafeeTimezone);
                } else {
                    let i=0;
                    for (let j = 0; j < safeeTimezones.length; j++) {
                        let tz = safeeTimezones[j];

                        if (i===0 && !tz.datetimeLessThenOrEqual(commentTime)){
                            if (tz.equalsEntity(comment)) {
                                safeeTimezones[i].setDatetime(commentTime);
                                break;
                            } else {
                                let newSafeeTimezone = new SafeeTimeZone(commentTime, comment.attributes.timezone.value, comment.attributes.timeOffset.value);
                                safeeTimezones.splice(0, 0, newSafeeTimezone);
                                break;
                            }
                        }else if (tz.datetimeLessThenOrEqual(commentTime) &&
                            (safeeTimezones.length === i + 1 ||
                                (safeeTimezones.length > i + 1 && !safeeTimezones[i + 1].datetimeLessThenOrEqual(commentTime)))) {
                            if (tz.equalsEntity(comment)) {
                                break;
                            } else if ((safeeTimezones.length > i + 1) && safeeTimezones[i + 1].equalsEntity(comment)) {
                                safeeTimezones[i + 1].setDatetime(comment.time);
                                break;
                            } else {
                                let newSafeeTimezone = new SafeeTimeZone(commentTime, comment.attributes.timezone.value, comment.attributes.timeOffset.value);
                                safeeTimezones.splice(i + 1, 0, newSafeeTimezone);
                                break;
                            }
                        }
                        i++;
                    }
                }
            }
        }

        if (annotations != null) {
            for (let k = 0; k < annotations.length; k++) {
                let annotation = annotations[k];
                let flaggedTime = this.buildDateObj(annotation, "flaggedTime", "timezone", "timeOffset");

                if (safeeTimezones.length === 0) {
                    let newSafeeTimezone = new SafeeTimeZone(flaggedTime, annotation.attributes.timezone.value, annotation.attributes.timeOffset.value);
                    safeeTimezones.push(newSafeeTimezone);
                } else {
                    let i=0;
                    for (let j = 0; j < safeeTimezones.length; j++) {
                        let tz = safeeTimezones[j];
                        if (i==0 && !tz.datetimeLessThenOrEqual(flaggedTime)){
                            if (tz.equalsEntity(annotation)) {
                                safeeTimezones[i].setDatetime(flaggedTime);
                                break;
                            } else {
                                let newSafeeTimezone = new SafeeTimeZone(flaggedTime, annotation.attributes.timezone.value, annotation.attributes.timeOffset.value);
                                safeeTimezones.splice(0, 0, newSafeeTimezone);
                                break;
                            }
                        }else if (tz.datetimeLessThenOrEqual(flaggedTime) &&
                            (safeeTimezones.length === i + 1 ||
                                (safeeTimezones.length > i + 1 && !safeeTimezones[i + 1].datetimeLessThenOrEqual(flaggedTime)))) {
                            if (tz.equalsEntity(annotation)) {
                                break;
                            } else if ((safeeTimezones.length > i + 1) && safeeTimezones[i + 1].equalsEntity(annotation)) {
                                safeeTimezones[i + 1].setDatetime(flaggedTime);
                                break;
                            } else {
                                let newSafeeTimezone = new SafeeTimeZone(flaggedTime, annotation.attributes.timezone.value, annotation.attributes.timeOffset.value);
                                safeeTimezones.splice(i + 1, 0, newSafeeTimezone);
                                break;
                            }
                        }
                        i++;
                    }
                }
            }
        }

        if (safeeTimezones.length === 0){
            let timeZoneEvent = this.getTimezoneFromHistory(fromDate);

            if (timeZoneEvent != null) {
                let tzDateTime = moment.tz(fromDate.format('YYYY/MM/DD'), 'YYYY/MM/DD', this.getTimezoneStr(timeZoneEvent.attributes.timezone.value));
//                tzDateTime.set('year', parseInt(fromDate.format('YYYY')));
//                tzDateTime.set('month', parseInt(fromDate.format('MM')));
//                tzDateTime.set('date', parseInt(fromDate.format('DD')));
//                tzDateTime.set('hour', 0);
//                tzDateTime.set('minute', 0);
//                tzDateTime.set('second', 0);
//                tzDateTime.set('millisecond', 0);

                if (parseInt(tzDateTime.format('HH')) !== 0 && parseInt(fromDate.format('DD')) !== parseInt(tzDateTime.format('DD'))){
                    let minutesDiff = 1440 - (parseInt(tzDateTime.format('HH')) * 60 + parseInt(tzDateTime.format('mm'))); //1440 is 24*60
                    tzDateTime.add(minutesDiff, "minutes");
                }else if (parseInt(tzDateTime.format('HH')) !== 0 && parseInt(fromDate.format('DD')) === parseInt(tzDateTime.format('DD'))){
                    let minutesDiff = (parseInt(tzDateTime.format('HH')) * 60 + parseInt(tzDateTime.format('mm')));
                    tzDateTime.subtract(minutesDiff, "minutes");
                }

                let safeeTimeZone = new SafeeTimeZone(tzDateTime, timeZoneEvent.attributes.timezone.value, this.getTimezoneOffset(tzDateTime.utcOffset()));
                safeeTimezones.splice(0, 0, safeeTimeZone);

            }else{
                let lastSafeeTimeZone = this.getDefaultSafeeTimezone(fromDate);
                safeeTimezones.splice(0, 0, lastSafeeTimeZone);
            }
        }else{
            let transitionSafeeTimeZone = this.getPreviousTransition(safeeTimezones[0]);
            if (transitionSafeeTimeZone != null) {
                if (this.getSafeeTimeZoneTimezoneDateForACalendarDate(fromDate, safeeTimezones[0]).isBefore(transitionSafeeTimeZone.datetime)) {
                    if (transitionSafeeTimeZone.equalsTimeZone(safeeTimezones[0])){
                        safeeTimezones[0].setDatetime(transitionSafeeTimeZone.datetime);
                    }else {
                        safeeTimezones.splice(0, 0, transitionSafeeTimeZone);
                    }

                    let transitionSafeeTimeZoneClone = transitionSafeeTimeZone.clone();
                    transitionSafeeTimeZoneClone.datetime = transitionSafeeTimeZoneClone.datetime.subtract(1, "seconds");

                    let prevTransitionSafeeTimeZone = this.getPreviousTransition(transitionSafeeTimeZoneClone);
                    let date = this.getSafeeTimeZoneTimezoneDateForACalendarDate(fromDate, prevTransitionSafeeTimeZone);
                    prevTransitionSafeeTimeZone.setDatetime(date);
                    safeeTimezones.splice(0, 0, prevTransitionSafeeTimeZone);
                }else {
                    let nextTransitionSafeeTimeZone = this.getNextTransition(transitionSafeeTimeZone);
                    if (nextTransitionSafeeTimeZone !== null && nextTransitionSafeeTimeZone.datetime.isSame(safeeTimezones[0].datetime)){
                        let lastWorkDiaryItem = this.getClosestPreviousEvent(safeeTimezones[0].datetime);
                        if (lastWorkDiaryItem === null) {
                            let date = this.getSafeeTimeZoneTimezoneDateForACalendarDate(fromDate, transitionSafeeTimeZone);
                            transitionSafeeTimeZone.setDatetime(date);
                            safeeTimezones.splice(0, 0, transitionSafeeTimeZone);
                        }else{
                            if (transitionSafeeTimeZone.equalsEntity(lastWorkDiaryItem)){
                                let date = this.getSafeeTimeZoneTimezoneDateForACalendarDate(fromDate, transitionSafeeTimeZone);
                                transitionSafeeTimeZone.setDatetime(date);
                                safeeTimezones.splice(0, 0, transitionSafeeTimeZone);
                            }else{
                                let lastSafeeTZ = new SafeeTimeZone(this.getEntityTimeZoneDateForACalendarDate(fromDate, lastWorkDiaryItem), lastWorkDiaryItem.attributes.timezone.value, lastWorkDiaryItem.attributes.timeOffset.value);
                                safeeTimezones.splice(0, 0, lastSafeeTZ);
                            }
                        }
                    }else {
                        let lastWorkDiaryItem = this.getClosestPreviousEvent(safeeTimezones[0].datetime);
                        if (lastWorkDiaryItem === null) {
                            safeeTimezones[0].setDatetime(
                                this.getSafeeTimeZoneTimezoneDateForACalendarDate(
                                    fromDate, safeeTimezones[0]));
                        }else{
                            if (safeeTimezones[0].equalsEntity(lastWorkDiaryItem)){
                                safeeTimezones[0].setDatetime(
                                    this.getSafeeTimeZoneTimezoneDateForACalendarDate(
                                    fromDate, safeeTimezones[0]));
                            }else{
                                if (safeeTimezones[0].timezone === lastWorkDiaryItem.attributes.timezone.value){
                                    safeeTimezones[0].setDatetime(
                                        this.getSafeeTimeZoneTimezoneDateForACalendarDate(
                                            fromDate, safeeTimezones[0]));
                                }else {
                                    let lastSafeeTZ = new SafeeTimeZone(
                                        this.getEntityTimeZoneDateForACalendarDate(
                                            fromDate, lastWorkDiaryItem),
                                        lastWorkDiaryItem.attributes.timezone.value, lastWorkDiaryItem.attributes.timeOffset.value);
                                    safeeTimezones.splice(0, 0, lastSafeeTZ);
                                }
                            }
                        }
                    }
                }
            }else{
                let lastWorkDiaryItem = this.getClosestPreviousEvent(safeeTimezones[0].datetime);
                if (lastWorkDiaryItem === null) {
                    safeeTimezones[0].setDatetime(
                        this.getSafeeTimeZoneTimezoneDateForACalendarDate(
                            fromDate, safeeTimezones[0]));
                }else{
                    if (safeeTimezones[0].equalsEntity(lastWorkDiaryItem)){
                        safeeTimezones[0].setDatetime(
                            this.getSafeeTimeZoneTimezoneDateForACalendarDate(
                                fromDate, safeeTimezones[0]));
                    }else{
                        let lastSafeeTZ = new SafeeTimeZone(this.getEntityTimeZoneDateForACalendarDate(fromDate, lastWorkDiaryItem), lastWorkDiaryItem.attributes.timezone.value, lastWorkDiaryItem.attributes.timeOffset.value);
                        safeeTimezones.splice(0, 0, lastSafeeTZ);
                    }
                }
            }
        }

        for (let i=0; i<safeeTimezones.length; i++){
            let timeZone = safeeTimezones[i];

            let transitionSafeeTimeZone = this.getNextTransition(timeZone);

            if (transitionSafeeTimeZone != null) {

                if (i < (safeeTimezones.length - 1)) {

                    let nextTimeZone = safeeTimezones[i+1];

                    if (nextTimeZone.equalsTimeZone(transitionSafeeTimeZone)){
                        if (transitionSafeeTimeZone.datetime.isBefore(nextTimeZone.datetime)){
                            nextTimeZone.setDatetime(transitionSafeeTimeZone.datetime);
                        }
                    }else{
                        if (transitionSafeeTimeZone.datetime.isBefore(nextTimeZone.datetime)){
                            safeeTimezones.splice(i+1, 0, transitionSafeeTimeZone);
                        }
                    }
                }else{
                    if (transitionSafeeTimeZone.datetime.isBefore(this.getSafeeTimeZoneTimezoneDateForACalendarDate(nextDate, timeZone))){
                        safeeTimezones.splice(i+1, 0, transitionSafeeTimeZone);
                    }
                }
            }
        }

        return safeeTimezones;
    }

    getClosestPreviousEvent(dateTimeValue) {

        let events = this.props.datasources[this.props.beans[this.props.uiDefinition.eventsDatasource].datasource].entityList.data;

        if (events.isEmpty || events.length === 0){
            return null;
        }

        if (!this.buildDateObj(events[0], "startTime", "timezone", "timeOffset").isBefore(dateTimeValue)){
            return null;
        }

        let event = events.findLast(event => this.buildDateObj(event, "startTime", "timezone", "timeOffset").isBefore(dateTimeValue));

        if (event == null){  // to cover undefined scenario which is not exactly null so we have to explicit return null in this case. Double equal signs will consider undefined as equal to null. Triple equal signs won't
            return null;
        }

        return event;
    }


    getClosestNextEvent(dateTimeValue) {

        let events = this.props.datasources[this.props.beans[this.props.uiDefinition.eventsDatasource].datasource].entityList.data;

        if (events.isEmpty || events.length === 0){
            return null;
        }

        let event = events.find(event => dateTimeValue.isBefore(this.buildDateObj(event, "startTime", "timezone", "timeOffset")));

        if (event == null){  // to cover undefined scenario which is not exactly null so we have to explicit return null in this case. Double equal signs will consider undefined as equal to null. Triple equal signs won't
            return null;
        }

        return event;
    }

    lastTwoUpEventBefore(dateTimeValue) {     // Two Up

        let events = this.props.datasources[this.props.beans[this.props.uiDefinition.twoUpEventsDatasource].datasource].entityList.data;

        if (events.length === 0){
            return null;
        }

        if (!this.buildDateObj(events[0], "timeOfEffect", "timezone", "timeOffset").isBefore(dateTimeValue)){
            return null;
        }

        let event = events.findLast(event => this.buildDateObj(event, "timeOfEffect", "timezone", "timeOffset").isBefore(dateTimeValue));

        if (event == null){  // to cover undefined scenario which is not exactly null so we have to explicit return null in this case. Double equal signs will consider undefined as equal to null. Triple equal signs won't
            return null;
        }

        return event;
    }

    getEntityTimeZoneDateForACalendarDate(dateWithNoTZOrOffset, entity) {
        let timezone = entity.attributes.timezone.value;
        let timeOffset = entity.attributes.timeOffset.value;

        return this.getTimeZoneDateForACalendarDate(dateWithNoTZOrOffset, timezone, timeOffset);
    }

    getSafeeTimeZoneTimezoneDateForACalendarDate(dateWithNoTZOrOffset, safeeTimeZone) {
        let timezone = (safeeTimeZone===null)?null : safeeTimeZone.timezone;
        let timeOffset = (safeeTimeZone===null)?null : safeeTimeZone.timeOffset;

        return this.getTimeZoneDateForACalendarDate(dateWithNoTZOrOffset, timezone, timeOffset);
    }

    getTimeZoneDateForACalendarDate(dateWithNoTZOrOffset, timezone, timeOffset) {

        if (timezone !== null) {
            let tzDateTime = moment.tz(dateWithNoTZOrOffset.format('YYYY/MM/DD'), 'YYYY/MM/DD', this.getTimezoneStr(timezone));
//            tzDateTime.set('year', parseInt(dateWithNoTZOrOffset.format('YYYY')));
//            tzDateTime.set('month', parseInt(dateWithNoTZOrOffset.format('MM')));
//            tzDateTime.set('date', parseInt(dateWithNoTZOrOffset.format('DD')));
//            tzDateTime.set('hour', 0);
//            tzDateTime.set('minute', 0);
//            tzDateTime.set('second', 0);
//            tzDateTime.set('millisecond', 0);

            if (parseInt(tzDateTime.format('HH')) !== 0 && parseInt(tzDateTime.format('DD')) !== parseInt(dateWithNoTZOrOffset.format('DD'))){
                let minutesDiff = 1440 - (parseInt(tzDateTime.format('HH')) * 60 + parseInt(tzDateTime.format('mm'))); //1440 is 24*60
                tzDateTime.add(minutesDiff, "minutes");
            }else if (parseInt(tzDateTime.format('HH')) !== 0 && dateWithNoTZOrOffset.format("DD") === parseInt(tzDateTime.format('DD'))){
                let minutesDiff = (parseInt(tzDateTime.format('HH')) * 60 + parseInt(tzDateTime.format('mm')));
                tzDateTime.subtract(minutesDiff, "minutes");
            }

            return tzDateTime;

        }else{
            let isoDate = dateWithNoTZOrOffset.format('YYYY') + "-" + dateWithNoTZOrOffset.format('MM') + "-" + dateWithNoTZOrOffset.format('DD') + "T00:00:00" + timeOffset;

            let dateTime = moment(isoDate);

            let tzDateTime = this.getBestTZBasedDateTime(dateTime, timeOffset);

            if (parseInt(tzDateTime.format('HH')) !== 0 && parseInt(dateWithNoTZOrOffset.format('DD')) !== parseInt(tzDateTime.format('DD'))){
                let minutesDiff = 1440 - (parseInt(tzDateTime.format('HH')) * 60 + parseInt(tzDateTime.format('mm'))); //1440 is 24*60
                tzDateTime.add(minutesDiff, "minutes");
            }else if (parseInt(tzDateTime.format('HH')) !== 0 && parseInt(dateWithNoTZOrOffset.format("DD")) === parseInt(tzDateTime.format('DD'))){
                let minutesDiff = (parseInt(tzDateTime.format('HH')) * 60 + parseInt(tzDateTime.format('mm')));
                tzDateTime.subtract(minutesDiff, "minutes");
            }

            return tzDateTime;
        }
    }

    getBestTZBasedDateTime(dateTime, offset) {
        let possibleTZs = this.getPossibleTimezones(offset);

        if (possibleTZs.length === 1){
            return moment(dateTime.valueOf()).tz(possibleTZs[0]);
        }

        let offsetDuration = this.getOffsetDuration(offset);

        let adjustedDateTime = dateTime.clone().utc().add(offsetDuration, "minutes");

        let hour = parseInt(adjustedDateTime.format("HH"));

        for (let timezoneIndex in possibleTZs){
          let tzDateTime = moment(dateTime).tz(possibleTZs[timezoneIndex]);
          if (parseInt(tzDateTime.format("HH")) === hour){
            return tzDateTime;
          }
        }

        return moment(dateTime).tz("Australia/Melbourne");
      }

    getOffsetDuration(duration){
        if (duration.length < 5 || duration.length > 6){
          return 0;
        }

        if (duration.startsWith("+")){
          return ((parseInt(duration.substring(1,3)) * 60) + parseInt(duration.substring(4,6)));
        }else if (duration.startsWith("-")){
          return -1 * (((parseInt(duration.substring(1,3)) * 60) + parseInt(duration.substring(4,6))));
        }else{
          return ((parseInt(duration.substring(0,2)) * 60) + parseInt(duration.substring(3,5)));
        }
    }

    getPossibleTimezones(offset){
        if (offset === "+08:00"){
            return ['Australia/Perth'];
        }

        if (offset === "+10:30"){
            return ['Australia/Adelaide'];
        }

        if (offset === "+09:30"){
            return ['Australia/Adelaide', 'Australia/Darwin'];
        }

        if (offset === "+10:00"){
            return ['Australia/Sydney', 'Australia/Melbourne', 'Australia/Brisbane', 'Australia/Canberra', 'Australia/Hobart'];
        }

        if (offset === "+11:00"){
            return ['Australia/Sydney', 'Australia/Melbourne', 'Australia/Canberra', 'Australia/Hobart'];
        }

        return ['Australia/Melbourne']; // default. System to adjust calculations
    }

    getTimezoneFromHistory(dateProcessed){
        let timezoneEvents = this.props.datasources[this.props.beans[this.props.uiDefinition.timeZoneEventsDatasource].datasource].entityList.data;

        let timezoneEvent = null;

        for (let tzEventIndex in timezoneEvents){
            let timezoneTime = moment(timezoneEvents[tzEventIndex].attributes.timestamp.value);

            if (timezoneTime.isAfter(dateProcessed)){
                break;
            }

            timezoneEvent = timezoneEvents[tzEventIndex];
        }

        return timezoneEvent;
    }

    getNextTransition(safeeTimeZone){
        let realTimeZone = this.getRealTimezone(safeeTimeZone.timezone, safeeTimeZone.timeOffset);

        if (realTimeZone === null || !realTimeZone.startsWith("Australia")){
          return null;
        }

        let nearestNextTransitionEpoch = this.getNearestNextTransition(moment.tz.zone(realTimeZone).untils, safeeTimeZone.datetime.valueOf());

        if (nearestNextTransitionEpoch !== null){
            let transitionMoment = moment(nearestNextTransitionEpoch).tz(realTimeZone);
            let transitionSafeeTimeZone = new SafeeTimeZone(
                transitionMoment,
                safeeTimeZone.timezone,
                this.getTimezoneOffset(transitionMoment.utcOffset())
            );

            return transitionSafeeTimeZone;
        }

        return null;
    }

    getPreviousTransition(safeeTimeZone){

        let realTimeZone = this.getRealTimezone(safeeTimeZone.timezone, safeeTimeZone.timeOffset);

        if (realTimeZone === null || !realTimeZone.startsWith("Australia")){
            return null;
        }

        let nearestPreviousTransitionEpoch = this.getNearestPreviousTransition(moment.tz.zone(realTimeZone).untils, safeeTimeZone.datetime.valueOf());

        if (nearestPreviousTransitionEpoch !== null){
            let transitionMoment = moment(nearestPreviousTransitionEpoch).tz(realTimeZone);
            let transitionSafeeTimeZone = new SafeeTimeZone(
                transitionMoment,
                safeeTimeZone.timezone,
                this.getTimezoneOffset(transitionMoment.utcOffset())
            );

            return transitionSafeeTimeZone;
        }

        return null;
    }

    getNearestPreviousTransition(transitions, dateTime){
        let prevEpoch = null;

        for (let index in transitions){
            if (transitions[index] > dateTime){
                if (transitions[index] !== Infinity){
                    break;
                }
                prevEpoch = transitions[index];
            }
        }

        return prevEpoch;
    }

    getNearestNextTransition(transitions, dateTime){
        for (let index in transitions){
            if (transitions[index] !== Infinity && transitions[index] > dateTime){
        		return transitions[index];
        	}
        }

        return null;
    }

    render() {
        let currentTime = moment();

        let previousButtonVisible = (
                                        currentTime.clone().subtract(28, "days").isBefore(this.state.graphicalViewDate.clone().subtract(1, "days")) ||
                                        this.state.timezoneIndex > 0
                                    );

        let nextButtonVisible = (
                                    currentTime.isAfter(this.state.graphicalViewDate.clone().add(1, "days")) ||
                                    this.state.timezoneIndex < (this.state.timezonesForTheDay.length - 1)
                                );

        return (
            <div style={{width: "100%", textAlign: "center", overflow: "auto"}} id="divcontainer">
                <div style={{width: SafeeEWDGraphicalView.width + 'px', height: (SafeeEWDGraphicalView.heightFactor*10) + 'px', margin: '0 auto', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between'}}>
                    <div style={{textAlign: 'right', width: '100%', height: (SafeeEWDGraphicalView.heightFactor*10)}}>
                        {
                            previousButtonVisible &&
                            <Chip label={'Prev'}
                                onClick={
                                    ()=>{
                                        if (this.state.timezoneIndex > 0){
                                            this.setState({
                                                ...this.state,
                                                timezoneIndex: this.state.timezoneIndex - 1
                                            });
                                        }else{
                                            let newGraphicalViewDate = this.state.graphicalViewDate.clone().subtract(1, "days");
                                            if (parseInt(newGraphicalViewDate.format("HH")) == 23){
                                                newGraphicalViewDate.add(1, "hours");
                                            }else if (parseInt(newGraphicalViewDate.format("HH")) == 1){
                                                newGraphicalViewDate.subtract(1, "hours");
                                            }

                                            let newState = this.getNewState(newGraphicalViewDate);

                                            this.setState({
                                                today: this.state.today,
                                                ...newState,
                                                graphicalViewDate: newGraphicalViewDate,
                                                timezoneIndex: newState.timezonesForTheDay.length -1
                                            });
                                        }
                                    }
                                }
                                className="mx-2" style={{fontSize: '0.9rem', backgroundColor: '#55739E', color: 'white', width: '100px'}}
                            />
                        }
                    </div>
                    <div style={{whiteSpace: "pre-wrap", lineHeight: '2.0rem', verticalAlign: 'middle', fontSize: '0.9rem', fontWeight: 'bold', color: 'black', minWidth: SafeeEWDGraphicalView.width/3 + 'px', height: (SafeeEWDGraphicalView.heightFactor*10)}}>{(this.state.graphicalViewDate.format("dddd,   YYYY-MM-DD"))}</div>
                    <div style={{textAlign: 'left', width: '100%', height: (SafeeEWDGraphicalView.heightFactor*10)}}>
                        {
                            nextButtonVisible &&
                            <Chip label={'Next'}
                                onClick={
                                    ()=>{
                                        if (this.state.timezoneIndex < this.state.timezonesForTheDay.length -1){
                                            this.setState({
                                                ...this.state,
                                                timezoneIndex: this.state.timezoneIndex + 1
                                            });
                                        }else{
                                            let newGraphicalViewDate = this.state.graphicalViewDate.clone().add(1, "days");
                                            if (parseInt(newGraphicalViewDate.format("HH")) == 23){
                                                newGraphicalViewDate.add(1, "hours");
                                            }else if (parseInt(newGraphicalViewDate.format("HH")) == 1){
                                                newGraphicalViewDate.subtract(1, "hours");
                                            }

                                            let newState = this.getNewState(newGraphicalViewDate);
                                            this.setState({
                                                today: this.state.today,
                                                ...newState,
                                                graphicalViewDate: newGraphicalViewDate
                                            });
                                        }
                                    }
                                }
                                className="mx-2" style={{fontSize: '0.9rem', backgroundColor: '#55739E', color: 'white', width: '100px'}}
                            />
                        }
                    </div>
                </div>

                <div style={{width: SafeeEWDGraphicalView.width + 'px', margin: '0 auto', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', borderTop: '2px solid grey', padding: "10px 0px"}}>
                    <table style={{width: "100%", textAlign: "left"}}>
                        <thead>
                            <tr>
                                <td style={{width: "16.6%", color: "black"}}>
                                    Driver Name:
                                </td>
                                <td style={{width: "16.6%", color: "black"}}>
                                    License:
                                </td>
                                <td style={{width: "16.6%", color: "black"}}>
                                    Registration:
                                </td>
                                <td style={{width: "16.6%", color: "black"}}>
                                    Work Option:
                                </td>
                                <td style={{width: "16.6%", color: "black"}}>
                                    Date:
                                </td>
                                <td style={{color: "black"}}>
                                    Time Zone:
                                </td>
                            </tr>
                        </thead>
                        {this.getHeaderData()}
                    </table>
                </div>
                <svg width={SafeeEWDGraphicalView.width + 'px'} height={SafeeEWDGraphicalView.height + 'px'}>

                    {this.getAnnotationsAndComments()}

                    {this.getLocationsAndOdometersSquares(this.state.graphicalViewDate)}

                    {this.getHourNumbersAndScaleMarkers(this.state.graphicalViewDate)}

                    {this.getTwoupSquares(this.state.graphicalViewDate)}

                    {this.getWorkAndRestSquaresAndLines(this.state.graphicalViewDate)}

                    {this.getTotals(this.state.graphicalViewDate)}

                    {this.getPotentialNonComplianceGraphicalElements(this.state.potentialNonComplianceList)}
                </svg>
            </div>
        );
    }

    getRealTimezone(timezone, offset){
        if (timezone === null){
            return "GMT" + offset;
        }

        let tz = this.getTimezoneStr(timezone);
        if (tz === null){
            return "GMT" + offset;
        }

        return tz;
    }

    getTimezoneStr(timezone){
        if (timezone === 'ACT'){
            return "Australia/Canberra";
        }else if (timezone === 'NSW'){
            return "Australia/Sydney";
        }else if (timezone === 'NT'){
            return "Australia/Darwin";
        }else if (timezone === 'QLD'){
            return "Australia/Brisbane";
        }else if (timezone === 'SA'){
            return "Australia/Adelaide";
        }else if (timezone === 'TAS'){
            return "Australia/Hobart";
        }else if (timezone === 'VIC'){
            return "Australia/Melbourne";
        }else if (timezone === 'WA'){
            return "Australia/Perth";
        }
        return null;
    }

    buildDateObj(element, timeAttr, timezoneAttr, offsetAttr){

        if (element.attributes[timezoneAttr].value === null){
            return moment(element.attributes[timeAttr].value).utcOffset(element.attributes[offsetAttr].value);
        }else{
            return moment(element.attributes[timeAttr].value).tz(this.getRealTimezone(element.attributes[timezoneAttr].value));
        }
    }

    getDefaultSafeeTimezone(date){
        let timezoneDateTime = moment.tz(date.format('YYYY/MM/DD'), 'YYYY/MM/DD', "Australia/Melbourne");
//        tzDateTime.set('year', parseInt(dateWithNoTZOrOffset.format('YYYY')));
//        tzDateTime.set('month', parseInt(dateWithNoTZOrOffset.format('MM')));
//        tzDateTime.set('date', parseInt(dateWithNoTZOrOffset.format('DD')));
//        tzDateTime.set('hour', 0);
//        tzDateTime.set('minute', 0);
//        tzDateTime.set('second', 0);
//        tzDateTime.set('millisecond', 0);

        let defaultSafeeTimeZone = new SafeeTimeZone(timezoneDateTime, "VIC", this.getTimezoneOffset(timezoneDateTime.utcOffset()));

        return defaultSafeeTimeZone;
    }

    getTimezoneOffset(offset){
        if (offset >= 0){
            return '+' + Math.floor(offset / 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ':' + (offset % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        }else{
            return '-' + Math.floor(-1 * (offset / 60)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ':' + (-1 * offset % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
        }
    }

    logJson(object){
        console.log(JSON.parse(JSON.stringify(object)));
    }
}

class SafeeTimeZone {

    constructor(datetime, timezone, timeOffset){
        this.datetime = datetime;
        this.timezone = timezone;
        this.timeOffset = timeOffset;
    }

    getTransitionInSeconds(safeeTimeZone){
        if (this.timezone === null && safeeTimeZone.timezone === null){
            if (this.timeOffset !== safeeTimeZone.timeOffset){
                let timezoneDuration1 = this.getDurationInSeconds(this.timeOffset);
                let timezoneDuration2 = this.getDurationInSeconds(safeeTimeZone.timeOffset);
                return timezoneDuration1 - timezoneDuration2; // in seconds
            }
        }else if (this.timezone !== null && safeeTimeZone.timezone !== null){
            if (this.timezone.toLowerCase() === safeeTimeZone.timezone.toLowerCase()){
                if (this.timeOffset !== safeeTimeZone.timeOffset){
                    let timezoneDuration1 = this.getDurationInSeconds(this.timeOffset);
                    let timezoneDuration2 = this.getDurationInSeconds(safeeTimeZone.timeOffset);

                    return timezoneDuration1 - timezoneDuration2;
                }
            }
        }else{
            if (this.timeOffset !== safeeTimeZone.timeOffset){
                let timezoneDuration1 = this.getDurationInSeconds(this.timeOffset);
                let timezoneDuration2 = this.getDurationInSeconds(safeeTimeZone.timeOffset);

                return timezoneDuration1 - timezoneDuration2;
            }
        }

        return 0;
    }

    equalsTimeZone(safeeTimeZone) {
        if (this.timeOffset === safeeTimeZone.timeOffset){
            if (this.timezone === null && safeeTimeZone.timezone === null) {
                return true; // the case of external content imported
            }else if (this.timezone === null && safeeTimeZone.timezone !== null){
                return false; // the case of external content imported
            }else if (this.timezone !== null && safeeTimeZone.timezone === null){
                return false; // the case of external content imported
            }else{
                return this.timezone.toLowerCase() === safeeTimeZone.timezone.toLowerCase();
            }
        }

        return false;
    }

    equalsEntity(entity) {
        if (this.timeOffset === entity.attributes.timeOffset.value){
            if (this.timezone === null && entity.attributes.timezone.value === null) {
                return true; // the case of external content imported
            }else if (this.timezone === null && entity.attributes.timezone.value !== null){
                return false; // the case of external content imported
            }else if (this.timezone !== null && entity.attributes.timezone.value === null){
                return false; // the case of external content imported
            }else{
                return this.timezone.toLowerCase() === entity.attributes.timezone.value.toLowerCase();
            }
        }

        return false;
    }

    datetimeLessThenOrEqual(datetimeToCheck){
        return !(datetimeToCheck < this.datetime);
    }

    setDatetime(datetime) {
        this.datetime = datetime;
    }

    clone() {
        return new SafeeTimeZone(this.datetime, this.timezone, this.timeOffset);
    }

    getDurationInSeconds(timeOffset){
        if (timeOffset.length === 0){
            return 0;
        }

        let sign = timeOffset[0];
        if (sign === "+"){
            let value = timeOffset.substring(1);
            let parts = value.split(":");
            return ((parseInt(parts[0]) * 3600) + (parseInt(parts[1]) * 60));
        }else if (sign === "-"){
            let value = timeOffset.substring(1);
            let parts = value.split(":");
            return -1 * ((parseInt(parts[0]) * 3600) + (parseInt(parts[1]) * 60));
        }else {
            let value = timeOffset.substring(1);
            let parts = value.split(":");
            return ((parseInt(parts[0]) * 3600) + (parseInt(parts[1]) * 60));
        }
    }

    format(){
        if (this.timezone === null || this.timezone === ""){
            return this.timeOffset??"";
        }else if (this.timeOffset !== null){
            return this.timezone + "(" + this.timeOffset + ")";
        }else{
            return this.timezone;
        }
    }

    toString() {
        return "SafeeTimeZone{" +
            "datetime=" + ((this.datetime==null)?"":this.datetime.format() )+
            ", timezone='" + (this.timezone??"") + '\'' +
            ", timeOffset='" + (this.timeOffset??"") + '\'' +
            '}';
    }
    
    logJson(object){
        console.log(JSON.parse(JSON.stringify(object)));
    }
}

export default SafeeEWDGraphicalView;