import React from 'react';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import Table from '@material-ui/core/Table';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ON_TOGGLE_TABLE_FILTER} from 'constants/B2ActionTypes';

class B2Table extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleTableFilter: false
        }
    }

    parentCallback = (b2Event) => {
        if (b2Event.type === ON_TOGGLE_TABLE_FILTER){
            this.setState({
                        toggleTableFilter: !this.state.toggleTableFilter
                    }
            );
        }
    }

    render() {
        const newProps = {
            ...this.props,
            toggleTableFilter: this.state.toggleTableFilter,
            parentCallback: this.parentCallback,
            readOnlyTable: this.props.uiDefinition.readOnly
        }

        return (
            <div className="flex-auto" key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} style={{'backgroundColor': '#f6f6f6'}}>
                <div className="table-responsive-material">
                    {
                        this.props.appLoader ?
                            <div className="loader-view"
                                style={{height: this.props.width >= 1200 ? 'calc(100vh - 259px)' : 'calc(100vh - 238px)'}}>
                                <CircularProgress/>
                            </div>
                            :
                            <Table style={{tableLayout: 'auto'}} key={this.props.uiDefinition.id} id={this.props.uiDefinition.id}>
                                {getB2ChildrenComponents(newProps, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                            </Table>
                    }
                </div>
            </div>
        );
    }
}

export default B2Table;