import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withStyles } from '@material-ui/styles';
import {getB2ChildrenComponents} from 'util/B2Utils.js';
import {SHOW_RECORD_DETAILS, ON_LOOKUP_RECORD_SELECTED, ON_LIST_SORT} from 'constants/B2ActionTypes';
import B2TextField from '../B2TextField';
import B2Checkbox from '../B2Checkbox';
import moment from 'moment';

class B2TableCol extends React.Component {
    showRecordDetails(event){
        event.stopPropagation();
        const b2Event = {
            type: SHOW_RECORD_DETAILS,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: this.props.rowDatasource.attributes.id.value
            }
        }

        this.props.processB2Event(b2Event);
    }

    lookupRecordSelect(event){
        event.stopPropagation();
        const b2Event = {
            type: ON_LOOKUP_RECORD_SELECTED,
            eventDetails: {
                beanId: this.props.bean.id,
                recordId: this.props.rowDatasource.attributes.id.value
            }
        }

        this.props.processB2Event(b2Event);
    }


    onListSort() {
        const sortAttribute = this.getSortAttribute();

        let sortDirection;
        if (sortAttribute !== this.props.uiDefinition.attribute){
            sortDirection = "asc";
        }else{
            sortDirection = this.getSortDirection();
        }

        const b2Event = {
            type: ON_LIST_SORT,
            eventDetails: {
                beanId: this.props.bean.id,
                sortAttribute: this.props.uiDefinition.attribute,
                datasourceId: this.props.datasource.id,
                sortDirection: (sortDirection==='asc')?'desc':'asc'
            }
        }

        this.props.processB2Event(b2Event);
    }

    getSortAttribute(){
        if (this.props.bean.sortAttribute){
            return this.props.bean.sortAttribute;
        }

        if (this.props.datasource.entityList.orderBy){
            const sortString = this.props.datasource.entityList.orderBy;
            let sortAttribute = sortString.trim();
            if (sortAttribute.endsWith(" asc")){
                sortAttribute = sortAttribute.substring(0, sortAttribute.length -4);
            } else if (sortAttribute.endsWith(" desc")){
                sortAttribute = sortAttribute.substring(0, sortAttribute.length -5);
            }
            return sortAttribute.trim(); // assuming sort happens with one attribute only (one column)
        }
        return null;
    }

    getSortDirection(){
        if (this.props.bean.sortAttribute){
            return this.props.bean.sortDirection;
        }

        if (this.props.datasource.entityList.orderBy){
            const sortString = this.props.datasource.entityList.orderBy;

            if (sortString.trim().endsWith(" asc")){
                return "asc";
            }
            if (sortString.trim().endsWith(" desc")){
                return "desc";
            }
            return "asc";
        }
        return null;
    }

    getLabel(){
        if (this.props.uiDefinition.label){
            return this.props.uiDefinition.label;
        }else if (this.props.datasource.entityList && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute] && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].title){
            return this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].title;
        } else {
            return this.props.uiDefinition.attribute;
        }
    }

    render() {
        if (this.props.uiDefinition.attribute){
            if (this.props.isHeader){
                if (this.props.datasource.entityList && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute] && this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].isPersistent === "true"){
                    const sortAttribute = this.getSortAttribute();
                    let sortDirection;
                    if (sortAttribute !== this.props.uiDefinition.attribute){
                        sortDirection = "asc";
                    }else{
                        sortDirection = this.getSortDirection();
                    }
                    return <TableCell align={this.props.uiDefinition.align} padding="none" style={(this.props.uiDefinition.style)?this.props.uiDefinition.style:{}}>
                                {/* TODO: should display sorting label only if field supports it (we may come up with a case where columns can not be used for sorting, or sorting is irrelevant like boolean values where filter makes more sense than sorting)*/}
                                <Tooltip
                                    title="Sort"
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={sortAttribute === this.props.uiDefinition.attribute}
                                        direction={(sortDirection)?sortDirection:"asc"}
                                        onClick={() => this.onListSort()}
                                    >
                                        {this.getLabel()}
                                   </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                }else{
                    return <TableCell align={this.props.uiDefinition.align} padding="none" style={(this.props.uiDefinition.style)?this.props.uiDefinition.style:{}}>
                                {/* TODO: should display sorting label only if field supports it (we may come up with a case where columns can not be used for sorting, or sorting is irrelevant like boolean values where filter makes more sense than sorting)*/}
                                {this.getLabel()}
                            </TableCell>
                }
            } else if (this.props.isFilter){
                if (this.props.uiDefinition.filterable &&
                    this.props.uiDefinition.filterable.toLowerCase() === "true" &&
                    this.props.datasource.entityList &&
                    this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute] &&
                    this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].isPersistent === "true"){
                    if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type === 'BOOLEAN'){
                        return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(this.props.uiDefinition.style)?this.props.uiDefinition.style:{}}>
                                    <B2Checkbox {...this.props} style={this.props.uiDefinition.innerComponentStyle}></B2Checkbox>
                                </TableCell>
                                );
                    }else{
                        return <TableCell align={this.props.uiDefinition.align} padding="none" style={(this.props.uiDefinition.style)?this.props.uiDefinition.style:{}}>
                                    <B2TextField {...this.props}  style={this.props.uiDefinition.innerComponentStyle}></B2TextField>
                                </TableCell>
                    }
                }else{
                    return <TableCell align={this.props.uiDefinition.align} padding="none" style={(this.props.uiDefinition.style)?this.props.uiDefinition.style:{}}></TableCell>
                }
            } else if (this.props.uiDefinition.onClick === SHOW_RECORD_DETAILS && this.props.isLookup !== "true") {
                let style = this.props.uiDefinition.style;

                if (this.props.rowDatasource.toBeRemoved){
                    if (style){
                        style["textDecoration"] = "line-through";
                        style["fontStyle"] = "italic";
                        style["fontWeight"] = "bold";
                    }else{
                        style = {
                            "textDecoration": "line-through",
                            "fontStyle": "italic",
                            "fontWeight": "bold"
                        }
                    }
                }

                if (this.props.rowDatasource.attributes[this.props.uiDefinition.attribute]){
                    if (this.props.uiDefinition.readOnly ==="true" || this.props.readOnlyTable === "true" || this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].isReadOnly === "true"){
                        if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='BOOLEAN'){
                            return (<TableCell align={this.props.uiDefinition.align} onClick={event => this.showRecordDetails(event)} padding="none" style={(style)?style:{}}>
                                        <B2Checkbox {...this.props} style={this.props.uiDefinition.innerComponentStyle} id={this.props.uiDefinition.id+"_rowdatasource_"+this.props.rowDatasource.attributes.id.value}></B2Checkbox>
                                    </TableCell>
                            );
                        }else{
                            if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='DATE'){
                                return (<TableCell align={this.props.uiDefinition.align} classes={{ body: this.props.classes.link }} onClick={event => this.showRecordDetails(event)} padding="none" style={(style)?style:{}}>
                                            {
                                                (this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value && this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value.length > 0)?
                                                    moment(this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value).format("YYYY-MM-DD"):
                                                    this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value
                                            }
                                        </TableCell>
                                )
                            }else if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='DATETIME'){
                                return (<TableCell align={this.props.uiDefinition.align} classes={{ body: this.props.classes.link }} onClick={event => this.showRecordDetails(event)} padding="none" style={(style)?style:{}}>
                                            {
                                                (this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value && this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value.length > 0)?
                                                    moment(this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value).format("YYYY-MM-DD hh:mm A"):
                                                    this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value
                                            }
                                        </TableCell>
                                )
                            }else{
                                return (<TableCell align={this.props.uiDefinition.align} classes={{ body: this.props.classes.link }} onClick={event => this.showRecordDetails(event)} padding="none" style={(style)?style:{}}>
                                            {this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value}
                                        </TableCell>
                                )
                            }
                        }
                    } else {
                        if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='BOOLEAN'){
                            return (<TableCell align={this.props.uiDefinition.align} classes={{ body: this.props.classes.link }} onClick={event => this.showRecordDetails(event)} padding="none" style={(style)?style:{}}>
                                        <B2Checkbox {...this.props}  style={this.props.uiDefinition.innerComponentStyle} id={this.props.uiDefinition.id+"_rowdatasource_"+this.props.rowDatasource.attributes.id.value}></B2Checkbox>
                                    </TableCell>
                            );
                        }else{
                            return (<TableCell align={this.props.uiDefinition.align} classes={{ body: this.props.classes.link }} onClick={event => this.showRecordDetails(event)} padding="none" style={(style)?style:{}}>
                                        <B2TextField {...this.props}  style={this.props.uiDefinition.innerComponentStyle} id={this.props.uiDefinition.id+"_rowdatasource_"+this.props.rowDatasource.attributes.id.value}></B2TextField>
                                    </TableCell>
                            );
                        }
                    }
                }else{
                    return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                            </TableCell>
                    );
                }
            } else if (this.props.uiDefinition.onClick ===  ON_LOOKUP_RECORD_SELECTED && this.props.isLookup === "true"){
                let style = this.props.uiDefinition.style;

                if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='BOOLEAN'){
                    return (<TableCell align={this.props.uiDefinition.align} classes={{ body: this.props.classes.link }} onClick={event => this.lookupRecordSelect(event)} padding="none" style={(style)?style:{}}>
                                <B2Checkbox {...this.props}  style={this.props.uiDefinition.innerComponentStyle} id={this.props.uiDefinition.id+"_rowdatasource_"+this.props.rowDatasource.attributes.id.value}></B2Checkbox>
                            </TableCell>
                    );
                }else{
                    return (<TableCell align={this.props.uiDefinition.align} classes={{ body: this.props.classes.link }} onClick={event => this.lookupRecordSelect(event)} padding="none" style={(style)?style:{}}>
                                {this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value}
                            </TableCell>
                    );
                }
            } else {
                let style = this.props.uiDefinition.style;

                if (this.props.rowDatasource.toBeRemoved){
                                    if (style){
                                        style = {
                                            ...style,
                                            "textDecoration": "line-through",
                                            "fontStyle": "italic",
                                            "fontWeight": "bold"
                                        }
                                    }else{
                                        style = {
                                            "textDecoration": "line-through",
                                            "fontStyle": "italic",
                                            "fontWeight": "bold"
                                        }
                                    }
                                }

                if (this.props.rowDatasource.attributes[this.props.uiDefinition.attribute]){
                    if (this.props.uiDefinition.readOnly ==="true" || this.props.readOnlyTable === "true" || this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].isReadOnly === "true" || this.props.rowDatasource.isReadOnly === "true"){
                        if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='BOOLEAN'){
                            return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                                        <B2Checkbox {...this.props} style={this.props.uiDefinition.innerComponentStyle} id={this.props.uiDefinition.id+"_rowdatasource_"+this.props.rowDatasource.attributes.id.value}></B2Checkbox>
                                    </TableCell>
                            );
                        }else{
                            if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='DATE'){
                                return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                                            {
                                                (this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value && this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value.length > 0)?
                                                    moment(this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value).format("YYYY-MM-DD"):
                                                    this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value
                                            }
                                        </TableCell>
                                )
                            }else if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='DATETIME'){
                                return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                                            {
                                                (this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value && this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value.length > 0)?
                                                    moment(this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value).format("YYYY-MM-DD hh:mm A"):
                                                    this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value
                                            }
                                        </TableCell>
                                )
                            }else{
                                return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                                            {this.props.rowDatasource.attributes[this.props.uiDefinition.attribute].value}
                                        </TableCell>
                                );
                            }
                        }
                    } else {
                        if (this.props.datasource.entityList.meta.attributes[this.props.uiDefinition.attribute].type==='BOOLEAN'){
                            return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                                        <B2Checkbox {...this.props}  style={this.props.uiDefinition.innerComponentStyle} id={this.props.uiDefinition.id+"_rowdatasource_"+this.props.rowDatasource.attributes.id.value}></B2Checkbox>
                                    </TableCell>
                            );
                        }else{
                            return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                                        <B2TextField {...this.props}  style={this.props.uiDefinition.innerComponentStyle} id={this.props.uiDefinition.id+"_rowdatasource_"+this.props.rowDatasource.attributes.id.value}></B2TextField>
                                    </TableCell>
                            );
                        }
                    }
                }else{
                    return (<TableCell align={this.props.uiDefinition.align} padding="none" style={(style)?style:{}}>
                            </TableCell>
                    );
                }
            }
        } else if (this.props.isFilter){
            return  <TableCell align={this.props.uiDefinition.align} padding="none" style={(this.props.uiDefinition.style)?this.props.uiDefinition.style:{}}></TableCell>
        } else {
            return  <TableCell key={this.props.uiDefinition.id} id={this.props.uiDefinition.id} align="left" padding="none" style={(this.props.uiDefinition.style)?this.props.uiDefinition.style:{}}>
                        {
                            (this.props.isHeader) &&
                            this.getLabel()
                        }
                        {getB2ChildrenComponents({...this.props, style: this.props.uiDefinition.innerComponentStyle}, this.props.uiDefinition, this.props.datasource, this.props.bean)}
                    </TableCell>
        }
    };
}

const styles = theme => ({
  link: {
    cursor: 'pointer',
    'text-decoration': 'underline'
  },
});

export default withStyles(styles)(B2TableCol);
