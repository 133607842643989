import React from 'react';
import Button from '@material-ui/core/Button';
import {store} from 'MainApp';
import moment from 'moment';
import CardBox from 'components/CardBox';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link} from 'react-router-dom'
import config from './config.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import {connect} from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
    CLOSE_B2_MESSAGE
    } from 'constants/B2ActionTypes';

import {
    RESEND_ACTIVE_LINK
} from 'constants/SafeeActionTypes';
import {
    processB2SecurityEvent
} from 'actions/B2App';

class SafeeSubscription extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayInitSubscriptionChoosePeriod: null,
            displayInitSubscriptionChoosePackage: null,
            displayInitSubscriptionDigitalAssistantPackage: null,
            pkg: null,
            agreement: null,
            anyOngoingSubscription: null,
            subscriptionPeriod: null,
            subscriptionPeriodUnit: null,
            subscriptionRate: null,
            revPromoCode: null,
            autoRenew: null,
            promoCodeValue: null,
            acceptTermsAndConditions: null,
            acceptTermsAndConditionsError: null,
            loadPayment: null
        };
    }

    goButtonClicked(pkg, agreement, anyOngoingSubscription){
//        if (pkg.attributes.rate.value === 0){
//            return;
//        }

        let selectedOption = this.getSelectedOption(pkg, agreement);

        if (selectedOption == null){
            return;
        }

        this.setState({
                pkg: pkg,
                agreement: agreement,
                subscriptionPeriod: selectedOption.attributes.agreementPeriod.value,
                subscriptionPeriodUnit: selectedOption.attributes.agreementPeriodUnit.value,
                subscriptionRate: selectedOption.attributes.rate.value,
                anyOngoingSubscription: anyOngoingSubscription,
                revPromoCode: null,
                autoRenew: (agreement != null)?agreement.attributes.autoRenew.value:false,
                promoCodeValue: null,
                acceptTermsAndConditions: null,
                acceptTermsAndConditionsError: null,
                loadPayment: null,
                performingPayment: false
            }
        );
    }

    toggleAgreementAutoRenewal(agreement){
        if (!agreement){
            return
        }

        const b2Event = {
            type: 'toggleAgreementAutoRenewal',
            eventDetails: {
                beanId: this.props.bean.id,
                customerAgreementId: agreement.attributes.customerAgreementId.value
            }
        }

        this.props.processB2Event(b2Event);
    }

    fetchPromoCode(){
        if (this.state.promoCodeValue == null || this.state.promoCodeValue === ''){
            this.setState({
                ...this.state,
                revPromoCode: null
            });
            return;
        }

        const b2Event = {
            type: 'fetchPromoCode',
            callback: this.applyPromoCode.bind(this),
            eventDetails: {
                beanId: this.props.bean.id,
                promoCodeId: this.state.promoCodeValue,
                packageName: this.state.pkg.attributes.packageName.value
            }
        }

        this.props.processB2Event(b2Event);
    }

    applyPromoCode(eventResponse){
        if (eventResponse.actionResponse.revPromoCode != null){
            this.setState({
                ...this.state,
                promoCodeValue: null,
                revPromoCode: eventResponse.actionResponse.revPromoCode
            });
        }else{
            this.setState({
                ...this.state,
                promoCodeValue: null
            });
        }
    }

    updatePromoValue = event => {
        event.stopPropagation();

        if (event.target.value && event.target.value.includes("\\")){
            return;
        }

        this.setState(
            {
                ...this.state,
                promoCodeValue: event.target.value
            }
        );
    };


    getSubscriptionCard(){
        if (this.state.pkg== null){
            return this.getPackageCards();
        }

        return (<div key={this.props.uiDefinition.id+'_subscription'} id={this.props.uiDefinition.id+'_subscription'} className="col-md-12 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                   <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px"}}>
                       <div className="row" noValidate autoComplete="off">
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                <div style={{
                                       margin: "15px 7px 0px 7px",
                                       width: '295px',
                                       display: 'inline',
                                       height: '5rem', lineHeight: '5rem',
                                       verticalAlign: 'middle',
                                       textAlign: 'left',
                                       fontSize: '1.5rem',
                                        color: '#55739E',
                                        height: '2.1rem'
                                   }}>
                                    <span>{this.state.pkg.attributes.packageName.value}</span>
                                </div>
                            </div>
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                <div style={{
                                      margin : '7px 7px 0px 7px',
                                      fontWeight: 'bold',
                                      width: '295px',
                                      display: 'inline-grid',
                                      verticalAlign: 'middle',
                                      textAlign: 'left',
                                      paddingBottom : '10px',
                                      paddingTop : '10px'
                                      }}>
                                    <span>
                                        {
                                            (
                                            (this.state.agreement!=null)?
                                            ('Current expiry date will be extended by ' + this.periodToText(this.state.subscriptionPeriodUnit, this.state.subscriptionPeriod) + ((this.state.agreement.attributes.inTrial.value != null && this.state.agreement.attributes.inTrial.value )?'. You will not lose any trial period remaining':'')):
                                            (
                                                'New subscription to start today for ' + this.periodToText(this.state.subscriptionPeriodUnit, this.state.subscriptionPeriod) + (this.state.anyOngoingSubscription?". Existing subscription will cease and be replaced with the new one":'') + ((this.state.promotion != null)?". This is a one-time offer. It doesn't renew at same price if auto renewal is turned on":"")
                                            )
                                            )
                                        }
                                    </span>
                                </div>
                            </div>
                            {
                                (this.state.revPromoCode != null &&  this.state.revPromoCode.attributes && this.state.revPromoCode.attributes.userDescription && this.state.revPromoCode.attributes.userDescription.value && this.state.revPromoCode.attributes.userDescription.value !== '') &&
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{
                                      margin : '0px 7px 0px 7px',
                                      fontWeight: 'bold',
                                      width: '295px',
                                      display: 'inline-grid',
                                      verticalAlign: 'middle',
                                      textAlign: 'left',
                                      paddingBottom : '10px'
                                      }}>
                                        <span>
                                            {this.state.revPromoCode.attributes.userDescription.value}
                                        </span>
                                    </div>
                                </div>
                            }
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                <div style={{
                                  margin : '10px 7px 0px 7px',
                                  paddingBottom : '7px',
                                  paddingTop : '7px',
                                  fontWeight: 'bold',
                                  width: '295px',
                                  display: 'inline-flex',
                                  lineHeight: '20px',
                                  verticalAlign: 'top',
                                  textAlign: 'left',
                                  borderTop: '1px #D3D3D3 solid'
                                  }}>
                                  <div style={{ width: '140px', lineHeight: '20px', verticalAlign: 'top', paddingRight: '5px', color: '#55739E'}}>
                                      <span>Sale</span>
                                  </div>
                                  <div style={{ width: '155px', lineHeight: '20px', verticalAlign: 'top', paddingLeft: '5px'}}>
                                      <span>${(this.getRate() - this.getRate()/11).toFixed(2) + ' ' + this.state.pkg.attributes.currency.value}</span>
                                  </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                <div style={{
                                  margin : '10px 7px 0px 7px',
                                  paddingBottom : '7px',
                                  paddingTop : '7px',
                                  fontWeight: 'bold',
                                  width: '295px',
                                  display: 'inline-flex',
                                  lineHeight: '20px',
                                  verticalAlign: 'top',
                                  textAlign: 'left'
                                  }}>
                                  <div style={{ width: '140px', lineHeight: '20px', verticalAlign: 'top', paddingRight: '5px', color: '#55739E'}}>
                                      <span>GST 10%</span>
                                  </div>
                                  <div style={{ width: '155px', lineHeight: '20px', verticalAlign: 'top', paddingLeft: '5px'}}>
                                      <span>${(this.getRate()/11).toFixed(2) + ' ' + this.state.pkg.attributes.currency.value}</span>
                                  </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                <div style={{
                                  margin : '10px 7px 0px 7px',
                                  paddingBottom : '7px',
                                  paddingTop : '7px',
                                  fontWeight: 'bold',
                                  width: '295px',
                                  display: 'inline-flex',
                                  lineHeight: '20px',
                                  verticalAlign: 'top',
                                  textAlign: 'left',
                                   borderBottom: '1px #D3D3D3 solid'
                                  }}>
                                  <div style={{ width: '140px', lineHeight: '20px', verticalAlign: 'top', paddingRight: '5px', color: '#55739E'}}>
                                      <span>Total</span>
                                  </div>
                                  <div style={{ width: '155px', lineHeight: '20px', verticalAlign: 'top', paddingLeft: '5px'}}>
                                      <span>${this.getRate().toFixed(2) + ' ' + this.state.pkg.attributes.currency.value}</span>
                                  </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                <div style={{
                                    margin : '0px 7px 0px 7px',
                                    paddingBottom : '7px',
                                    paddingTop : '7px',
                                    fontWeight: 'bold',
                                    width: '295px',
                                    display: 'inline-flex',
                                    lineHeight: '20px',
                                    verticalAlign: 'top',
                                    textAlign: 'left'
                                    }}>
                                    <div style={{ width: '130px', lineHeight: '20px', verticalAlign: 'top', paddingRight: '5px', paddingTop: '10px', color: '#55739E'}}>
                                        <span>Auto Renewal</span>
                                    </div>
                                    <div style={{ width: '165px', lineHeight: '20px', verticalAlign: 'top', paddingLeft: '5px'}}>
                                        <Switch
                                          id={this.props.uiDefinition.id+'_subscription'+'_autorenewalswitch'}
                                          key={this.props.uiDefinition.id+'_subscription'+'_autorenewalswitch'}
                                          classes={{
                                              checked: 'text-primary',
                                              track:'bg-primary'
                                          }}
                                          disabled={this.state.performingPayment}
                                          checked={this.state.autoRenew}
                                          onChange={()=>this.setState(
                                                {
                                                    ...this.state,
                                                    autoRenew: !this.state.autoRenew
                                                }
                                            )
                                          }
                                         />
                                    </div>
                                </div>
                            </div>
                            {
                             (!this.state.revPromoCode && this.state.promotion == null) &&
                           <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                               <div style={{
                                   margin : '0px 7px 0px 7px',
                                   width: '295px',
                                   display: 'inline-flex',
                                   lineHeight: '20px',
                                   verticalAlign: 'top',
                                   textAlign: 'left'
                                   }}>
                                   <TextField
                                       InputProps={{
                                           endAdornment: <Chip label='Apply'
                                                onClick={(e) => this.fetchPromoCode(e)}
                                                 className="mx-2" style={{paddingRight: '0px', marginRight : '0px', backgroundColor: '#55739E', fontWeight: 'bold', color: 'white', width: '70px'}}/>,
                                           autoComplete:'off',
                                           form: {
                                               autocomplete: 'off',
                                           },
                                           style: {
                                            paddingRight: '0px',
                                            marginRight: '0px'
                                           }
                                       }}
                                       key={this.props.uiDefinition.id+"PhantomTextField"}
                                       style={
                                               {
                                                   "margin":"2px 2px 2px 0px"
                                               }
                                       }
                                       disabled={this.state.performingPayment}
                                       size="small"
                                       variant={"outlined"}
                                       margin="dense"
                                       placeholder="Enter Promo Code"
                                       fullWidth
                                       value={(this.state.promoCodeValue==null||this.state.promoCodeValue==='')?'':this.state.promoCodeValue}
                                       onChange={(e) => this.updatePromoValue(e)}
                                       onBlur={(e) => this.updatePromoValue(e)}
                                   />
                               </div>
                           </div>
                           }
                           {
                                (this.state.revPromoCode != null && this.state.promotion == null) &&
                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                      margin : '0px 7px 0px 7px',
                                      paddingBottom : '7px',
                                      paddingTop : '7px',
                                      fontWeight: 'bold',
                                      width: '295px',
                                      display: 'inline-flex',
                                      lineHeight: '20px',
                                      verticalAlign: 'top',
                                      textAlign: 'left'
                                      }}>
                                      <div style={{ width: '140px', lineHeight: '20px', verticalAlign: 'top', paddingRight: '5px', color: '#55739E'}}>
                                          <span>Promo Code</span>
                                      </div>
                                      <div style={{ width: '155px', lineHeight: '20px', verticalAlign: 'top', paddingLeft: '5px'}}>
                                          <span>{this.state.revPromoCode.attributes.code.value}</span>
                                      </div>
                                   </div>
                               </div>
                           }

                           <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                               <div style={{
                                   margin : '0px 7px 0px 7px',
                                   fontWeight: 'bold',
                                   width: '295px',
                                   display: 'inline-flex',
                                   lineHeight: '20px',
                                   verticalAlign: 'top',
                                   textAlign: 'left'
                                   }}>
                                   <div style={{ width: '30px', lineHeight: '20px', verticalAlign: 'top', paddingRight: '5px', paddingTop: '15px'}}>
                                       <Checkbox color="primary"

                                           style={{"margin": "0px", "padding": "0px"}}
                                           checked={this.state.acceptTermsAndConditions == true}
//                                           onChange={
//                                                (e) => {
//                                                    this.setState(
//                                                        {
//                                                            ...this.state,
//                                                            acceptTermsAndConditions: ((this.state.acceptTermsAndConditions)?!this.state.acceptTermsAndConditions:true),
//                                                            acceptTermsAndConditionsError: (this.state.acceptTermsAndConditionsError && !this.state.acceptTermsAndConditions)?null:this.state.acceptTermsAndConditionsError
//                                                        }
//                                                    )
//                                                }
//                                           }
                                            onClick={
                                               (e) => {
                                                   this.setState(
                                                       {
                                                           ...this.state,
                                                           acceptTermsAndConditions: ((this.state.acceptTermsAndConditions)?!this.state.acceptTermsAndConditions:true),
                                                           acceptTermsAndConditionsError: (this.state.acceptTermsAndConditionsError && !this.state.acceptTermsAndConditions)?null:this.state.acceptTermsAndConditionsError
                                                       }
                                                   )
                                               }
                                            }
//                                            onKeyPress={
//                                                 (e) => {
//                                                     this.setState(
//                                                         {
//                                                             ...this.state,
//                                                             acceptTermsAndConditions: ((this.state.acceptTermsAndConditions)?!this.state.acceptTermsAndConditions:true),
//                                                             acceptTermsAndConditionsError: (this.state.acceptTermsAndConditionsError && !this.state.acceptTermsAndConditions)?null:this.state.acceptTermsAndConditionsError
//                                                         }
//                                                     )
//                                                 }
//                                            }
                                           disabled={this.state.performingPayment}
                                           value={(this.state.acceptTermsAndConditions)?this.state.acceptTermsAndConditions:false}
                                       />
                                  </div>
                                  <div style={{ width: '265px', lineHeight: '20px', verticalAlign: 'middle', paddingTop: '17px'}}>
                                    <span onClick={
                                             (e) => {
                                                 this.setState(
                                                     {
                                                         ...this.state,
                                                         acceptTermsAndConditions: ((this.state.acceptTermsAndConditions)?!this.state.acceptTermsAndConditions:true),
                                                         acceptTermsAndConditionsError: (this.state.acceptTermsAndConditionsError && !this.state.acceptTermsAndConditions)?null:this.state.acceptTermsAndConditionsError
                                                     }
                                                 )
                                             }
                                          }>Accept Terms & Conditions</span> - <span><Link to={{ pathname: config.termsAndConditions/*"https://safee.com.au/mobile-app-terms-and-conditions/"*/ }} target="_blank">View</Link></span>
                                  </div>
                               </div>
                           </div>
                           {
                                (this.state.acceptTermsAndConditionsError) &&
                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '0px 7px 0px 7px',
                                       width: '295px',
                                       display: 'inline-flex',
                                       lineHeight: '20px',
                                       verticalAlign: 'top',
                                       textAlign: 'left',
                                       color: 'red'
                                       }}>
                                       <span>Please read and accept Terms & Conditions</span>
                                   </div>
                               </div>
                           }
                           <div key={this.props.uiDefinition.id+'_subscription_buttons'} id={this.props.uiDefinition.id+'_subscription_buttons'} className="col-md-12 col-12" style={{textAlign: "center"}}>
                               <div style={{
                                   margin : '15px 7px 30px 7px',
                                   fontWeight: 'bold',
                                   borderRadius: '100em 100em 100em 100em',
                                   width: '295px',
                                   display: 'inline-flex',
                                   height: '20px', lineHeight: '20px',
                                   verticalAlign: 'middle'
                               }}>
                                    <div style={{width: '50%', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingRight: '5px'}}>
                                        <Chip label='Cancel'
                                            onClick={this.cancelSubscription.bind(this)}
                                            className="mx-2" style={{fontSize: '0.9rem', margin : '12px 7px', backgroundColor: '#55739E', fontWeight: 'bold', color: 'white', width: '110px'}}/>
                                    </div>
                                    <div style={{width: '50%', textAlign: 'left', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                        {
                                            (this.state.performingPayment) &&
                                             <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                                    <CircularProgress />
                                            </div>
                                        }
                                        {
                                            (!this.state.performingPayment) &&
                                             <Chip label={((this.getRate()==0 && this.state.agreement!=null)?'Renew':((this.getRate()==0 && this.state.agreement==null)?'Subscribe':'Proceed'))}
                                                 onClick={
                                                     ()=>{
                                                         if (!this.state.acceptTermsAndConditions){
                                                             this.setState(
                                                                 {
                                                                     ...this.state,
                                                                     acceptTermsAndConditionsError: true
                                                                 }
                                                             );
                                                             return;
                                                         }

                                                         if (this.getRate().toFixed(2) > 0){
                                                             const existingScript = document.getElementById('squareScript');
                                                             if (!existingScript) {
                                                                 const script = document.createElement('script');
                                                                 script.src = config.squareScript;//'https://sandbox.web.squarecdn.com/v1/square.js';
                                                                 script.id = 'squareScript';
                                                                 document.body.appendChild(script);
                                                                 script.onload = () => {
                                                                   this.setState(
                                                                       {
                                                                           ...this.state,
                                                                           loadPayment: true
                                                                       }
                                                                   )
                                                                 };
                                                             }else{
                                                                 this.setState(
                                                                     {
                                                                         ...this.state,
                                                                         loadPayment: true
                                                                     }
                                                                 )
                                                             }
                                                         }else{
                                                             this.setState(
                                                                  {
                                                                      ...this.state,
                                                                      performingPayment: true
                                                                  }
                                                             );
                                                             this.completePayment(null);
                                                         }
                                                     }
                                                 }
                                                 className="mx-2" style={{fontSize: '0.9rem', margin : '12px 7px', backgroundColor: '#55739E', fontWeight: 'bold', color: 'white', width: '110px'}}/>
                                        }
                                    </div>
                               </div>
                           </div>
                       </div>
                   </CardBox>
               </div>);
    }

    cancelSubscription() {
        if (this.state.performingPayment){
            return;
        }
        this.setState(
            {
                displayInitSubscriptionChoosePeriod: null,
                displayInitSubscriptionChoosePackage: null,
                pkg: null,
                agreement: null,
                anyOngoingSubscription: null,
                subscriptionPeriod: null,
                subscriptionPeriodUnit: null,
                subscriptionRate: null,
                revPromoCode: null,
                autoRenew: null,
                promoCodeValue: null,
                acceptTermsAndConditions: null,
                acceptTermsAndConditionsError: null,
                loadPayment: null,
                performingPayment: false
            }
        );
    }

    completePayment(token){
            const b2Event = {
                type: 'performPayment',
                callback: this.finalisePayment.bind(this),
                eventDetails: {
                    beanId: this.props.bean.id,
                    promoCodeId: (this.state.revPromoCode==null)?null:this.state.revPromoCode.attributes.id.value,
                    packageName: this.state.pkg.attributes.packageName.value,
                    subscriptionPeriod: this.state.subscriptionPeriod,
                    subscriptionPeriodUnit: this.state.subscriptionPeriodUnit,
                    promotion: this.state.promotion,
                    action: (this.state.agreement!=null)?'RENEW':'CREATE',
                    paymentSecret: token,
                    autoRenew: this.state.autoRenew,
                    agreementId: (this.state.agreement == null)?null:this.state.agreement.attributes.customerAgreementId.value
                }
            }

            this.props.processB2Event(b2Event);
        }

    finalisePayment(){
        this.setState(
            {
                displayInitSubscriptionChoosePeriod: null,
                displayInitSubscriptionChoosePackage: null,
                pkg: null,
                agreement: null,
                anyOngoingSubscription: null,
                subscriptionPeriod: null,
                subscriptionPeriodUnit: null,
                subscriptionRate: null,
                revPromoCode: null,
                autoRenew: null,
                promoCodeValue: null,
                promotion: null,
                acceptTermsAndConditions: null,
                acceptTermsAndConditionsError: null,
                loadPayment: null,
                performingPayment: false
            }
        );
    }

    getRate() {
        let rate = 0.0;

        if (this.state.revPromoCode != null){
            if (this.state.revPromoCode.attributes.rate.value != null){
                if (this.state.revPromoCode.attributes.rateImpact.value === 'Amount'){
                    return this.state.subscriptionRate + this.state.revPromoCode.attributes.rate.value;
                }else{
                    return this.state.subscriptionRate + ((this.state.subscriptionRate * this.state.revPromoCode.attributes.rate.value)/100);
                }
            }
        }

        return this.state.subscriptionRate;
    }

    getNewExpiryDate() {
        let datetime;
        if (this.state.agreement != null){
          if (this.state.agreement.attributes.packageName.value === this.state.pkg.attributes.packageName.value){
            datetime = this.addPeriodToDate(this.state.agreement.attributes.endDate.value, this.state.pkg.attributes.agreementPeriodUnit.value, this.state.pkg.attributes.agreementPeriod.value); // should extend as per package or previous agreement? what if pricing change?
          }else{
            datetime = this.addPeriodToDate(new Date(), this.state.pkg.attributes.agreementPeriodUnit.value, this.state.pkg.attributes.agreementPeriod.value);
          }
        }else{
          datetime = this.addPeriodToDate(new Date(), this.state.pkg.attributes.agreementPeriodUnit.value, this.state.pkg.attributes.agreementPeriod.value);
        }

        if (this.state.revPromoCode != null){
          if (this.state.revPromoCode.attributes.period.value != null){
            if (this.state.revPromoCode.attributes.periodImpact.value === 'Amount'){
              datetime = this.addPeriodToDate(datetime, this.state.revPromoCode.attributes.periodUnit.value, this.state.revPromoCode.attributes.period.value);
            }else{
              if (this.state.agreement != null){
                if (this.state.agreement.attributes.packageName.value === this.state.pkg.attributes.packageName.value) {
                  datetime = this.addPeriodPercentageToDate(
                      datetime, this.state.pkg.attributes.agreementPeriodUnit.value,
                      this.state.pkg.attributes.agreementPeriod.value, this.state.revPromoCode.attributes.period.value);
                }else{
                  datetime = this.addPeriodPercentageToDate(
                      datetime, this.state.pkg.attributes.agreementPeriodUnit.value, this.state.pkg.attributes.agreementPeriod.value, this.state.revPromoCode.attributes.period.value);
                }
              }else{
                datetime = this.addPeriodPercentageToDate(datetime, this.state.pkg.attributes.agreementPeriodUnit.value, this.state.pkg.attributes.agreementPeriod.value, this.state.revPromoCode.attributes.period.value);
              }

            }
          }
        }

        return this.dateToYMD(datetime);
      }

    addPeriodToDate(date, periodUnit, period){
        let datetime = new Date(date);
        if (periodUnit== null || periodUnit === 'Day'){
          return new Date(datetime.setDate(datetime.getDate() + period));
        }else if (periodUnit === 'Month'){
          return new Date(datetime.setMonth(datetime.getMonth()+period));
        }else{
          return new Date(datetime.setFullYear(datetime.getFullYear() + period));
        }
    }

    addPeriodPercentageToDate(date, periodUnit, period, percentage){
        let datetime = new Date(date);

        if (periodUnit== null || periodUnit === 'Day'){
          let periodValue = period*percentage/100;

          return new Date(datetime.setDate(datetime.getDate() + periodValue));
        }else if (periodUnit === 'Month'){
          let periodValue = period*percentage/100;

          return new Date(datetime.setMonth(datetime.getMonth()+periodValue));
        }else{
          let periodValue = period*percentage/100;

          return new Date(datetime.setFullYear(datetime.getFullYear() + periodValue));
        }
      }

    periodToText(periodUnit, period){
        if (periodUnit === 'Day'){

          if (this.state.revPromoCode != null && this.state.revPromoCode.attributes.period.value != null){
            if (this.state.revPromoCode.attributes.periodImpact.value === 'Amount'){
              if (this.state.revPromoCode.attributes.periodUnit === periodUnit){
                let periodVal = period + this.state.revPromoCode.attributes.period;
                if (periodVal % 7 === 0){
                    if ((periodVal / 7) === 2){
                        return "a fortnight";
                    }else{
                        return (periodVal/7) + " week" + ((periodVal==1)?'':'s');
                    }
                }else{
                    return period + " day" + ((period==1)?'':'s');
                }
              }else{
                return period + " days (Promo " + (period + this.state.revPromoCode.attributes.period.value) + " "+ this.state.revPromoCode.attributes.periodUnit.value + (this.state.revPromoCode.attributes.periodUnit.value==1?'':'s') + ")";
              }
            }else{
              return (period + (period * this.state.revPromoCode.attributes.period.value)/100) + " days";
            }
          }else {
            if (period % 7 === 0){
                if ((period / 7) === 2){
                    return "a fortnight";
                }else{
                    return (period/7) + " week" + (((period/7)==1)?'':'s');
                }
            }else{
                return period + " day" + ((period==1)?'':'s');
            }
          }
        }else if (periodUnit === 'Month'){
          if (this.state.revPromoCode != null && this.state.revPromoCode.attributes.period.value != null){
            if (this.state.revPromoCode.attributes.periodImpact.value === 'Amount'){
              if (this.state.revPromoCode.attributes.periodUnit.value === periodUnit){
                return (period + this.state.revPromoCode.attributes.period.value) + " months";
              }else{
                return period + " months (Promo " + (period + this.state.revPromoCode.attributes.period.value) + " "+ this.state.revPromoCode.attributes.periodUnit.value + (this.state.revPromoCode.attributes.periodUnit.value==1?'':'s') + ")";
              }
            }else{
              return (period + (period * this.state.revPromoCode.attributes.period.value)/100) + " months";
            }
          }else {
            return period + " month" + ((period==1)?'':'s');
          }
        }else{
          if (this.state.revPromoCode != null && this.state.revPromoCode.attributes.period.value != null){
            if (this.state.revPromoCode.attributes.periodImpact.value === 'Amount'){
              if (this.state.revPromoCode.attributes.periodUnit.value === periodUnit){
                return (period + this.state.revPromoCode.attributes.period.value) + " years";
              }else{
                return period + " years (Promo " + (period + this.state.revPromoCode.attributes.period.value) + " "+ this.state.revPromoCode.attributes.periodUnit.value + (this.state.revPromoCode.attributes.periodUnit.value==1?'':'s') + ")";
              }
            }else{
              return (period + (period * this.state.revPromoCode.attributes.period.value)/100) + " years";
            }
          }else {
            return period + " year" + ((period==1)?'':'s');
          }
        }
      }

    resendActivationLink = () => {
        const b2SecEvent = {
            type: RESEND_ACTIVE_LINK,
            eventDetails: {
            }
        }

        this.props.processB2SecurityEvent(b2SecEvent);
    }

    getNotActivatedCard() {
        return <React.Fragment>
                    <div key={this.props.uiDefinition.id+'_accountnotactivated_dev1'} id={this.props.uiDefinition.id+'_accountnotactivated_dev'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                    </div>
                    <div key={this.props.uiDefinition.id+'_accountnotactivated_dev2'} id={this.props.uiDefinition.id+'_accountnotactivated_dev2'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                        <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px"}}>
                            <div className="row" noValidate autoComplete="off">

                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin : '30px 0px 5px 3px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      height: '1.0rem'
                                      }}>
                                        <div style={{fontWeight:'bold', fontStyle:'normal', width: '295px', textAlign: 'center', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '1.1rem', color: "red"}}>
                                          <span>Email not verified</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                      <div style={{
                                          margin : '30px 0px 5px 3px',
                                          width: '295px',
                                          display: 'inline-flex',
                                          verticalAlign: 'bottom',
                                          height: '6.0rem'
                                          }}>

                                             <div style={{fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '1.1rem', color: "#55739E"}}>
                                              <span>We need to make sure your email address is correct because payment receipts will be sent to you via email</span>
                                              <br/>
                                            </div>
                                        </div>
                                    </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <Chip label='Send Verification Message'
                                      onClick={
                                          ()=>{this.resendActivationLink()}
                                      }
                                      className="mx-2" style={{fontSize: '0.9rem', margin : '7px 7px', backgroundColor: '#55739E', fontWeight: 'bold', color: 'white',}}/>
                                </div>
                            </div>
                       </CardBox>
                    </div>
                <div key={this.props.uiDefinition.id+'_accountnotactivated_dev3'} id={this.props.uiDefinition.id+'_accountnotactivated_dev3'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
           </div>
        </React.Fragment>
    }

    getTrialExtendedSuccessfullyCard() {
        if (this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length < 2){
            return <React.Fragment/>
        }


        let agreement = this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[0];
        if (agreement.attributes.status.value.toLowerCase() !== 'active'){
            agreement = this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[1];
            if (agreement.attributes.status.value.toLowerCase() !== 'active'){
                return <React.Fragment/>
            }
        }

        let endDate = new Date(agreement.attributes.endDate.value);
        let expiryDate = this.dateToYMD(endDate);

        return <React.Fragment>
                <div key={this.props.uiDefinition.id+'_trialextendedsuccessfully_dev1'} id={this.props.uiDefinition.id+'_trialextendedsuccessfully_dev1'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                </div>
                <div key={this.props.uiDefinition.id+'_trialextendedsuccessfully_dev2'} id={this.props.uiDefinition.id+'_trialextendedsuccessfully_dev2'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                    <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px"}}>
                        <div className="row" noValidate autoComplete="off">

                           <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                              <div style={{
                                  margin : '30px 0px 5px 3px',
                                  width: '295px',
                                  display: 'inline-flex',
                                  verticalAlign: 'bottom',
                                  height: '1.0rem'
                                  }}>
                                    <div style={{fontWeight:'bold', fontStyle:'normal', width: '295px', textAlign: 'center', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '1.1rem', color: "#55739E"}}>
                                      <span>Trial Period Extended</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin : '30px 0px 5px 3px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      height: '4.0rem'
                                      }}>

                                         <div style={{fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'center', lineHeight: '1.8rem', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '1.1rem', color: "#55739E"}}>
                                          <span>{'Your trial period has been extended until ' + expiryDate}</span>
                                          <br/>
                                        </div>
                                    </div>
                                </div>
                        </div>
                   </CardBox>
                </div>
                <div key={this.props.uiDefinition.id+'_trialextendedsuccessfully_dev3'} id={this.props.uiDefinition.id+'_trialextendedsuccessfully_dev3'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
           </div>
        </React.Fragment>
    }

    getOfferCard() {

            return <React.Fragment>
                        <div key={this.props.uiDefinition.id+'_offercard_dev1'} id={this.props.uiDefinition.id+'_offercard_dev'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                        </div>
                        <div key={this.props.uiDefinition.id+'_offercard_dev2'} id={this.props.uiDefinition.id+'_offercard_dev2'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                            {
                                this.state.performingTrialExtension &&
                                   <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                           <CircularProgress />
                                   </div>
                            }
                            {
                                !this.state.performingTrialExtension &&
                                <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px", backgroundColor: "#F9F9F9" }}>
                                    <div className="row" noValidate autoComplete="off">

                                       <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                          <div style={{
                                              margin : '30px 0px 5px 3px',
                                              width: '295px',
                                              display: 'inline-flex',
                                              verticalAlign: 'bottom',
                                              height: '3.0rem'
                                              }}>
                                                <div style={{fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '3rem', color: "#003077"}}>
                                                  <span>Subscription Offers:</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                            <div style={{
                                              margin: '5px',
                                              padding: '2px',
                                              width: '295px',
                                              display: 'inline-flex',
                                              verticalAlign: 'bottom',
                                              borderRadius: '10px',
                                              backgroundImage: 'linear-gradient(#55739E, black)',
                                              height: '6.6rem'
                                              }}>


                                                 <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #55739E)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.4rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                                    onClick={
                                                        (e) => {
                                                             this.setState(
                                                                 {
                                                                      ...this.state,
                                                                      displayInitSubscriptionChoosePeriod: true
                                                                 }
                                                             )
                                                        }
                                                    }
                                                 >
                                                  <span>Purchase a subscription at a special price choosing from different periods</span>

                                                  <div style={{borderRadius: '10px 0px 10px 10px', backgroundColor: '#C6EFCE', color: '#006100', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', marginTop: '15px', fontSize: "0.9rem", fontWeight: 'bold', width: '180px', float: 'right', textAlign: 'center', height: '1.4rem'}}>
                                                    <span>Our Recommendation</span>
                                                  </div>
                                                </div>

                                                </div>
                                        </div>
                                        <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                        <div style={{
                                              margin : '15px 5px 5px 5px',
                                              padding: '2px',
                                              width: '295px',
                                              display: 'inline-flex',
                                              verticalAlign: 'bottom',
                                              borderRadius: '10px',
                                              backgroundImage: 'linear-gradient(#55739E, black)',
                                              height: '6.6rem'
                                              }}>


                                                 <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #405778, #55739E, #E4A722)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.35rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}

                                                      onClick={
                                                           (e) => {
                                                               this.setState(
                                                                   {
                                                                       ...this.state,
                                                                       displayInitSubscriptionChoosePeriod: null,
                                                                       displayInitSubscriptionChoosePackage: null,
                                                                       pkg: this.getPackage('Accredited EWD+'),
                                                                       promotion: 'THREE_MONTHS_FOR_SEVEN',
                                                                       subscriptionPeriod: 3,
                                                                       subscriptionPeriodUnit: 'Month',
                                                                       subscriptionRate: 23.1
                                                                   }
                                                               )
                                                           }
                                                          }
                                                      >

                                                      <span>Purchase a 3 months subscription for $7 a month ex GST</span>

                                                      <div style={{borderRadius: '10px 10px 10px 0px', backgroundColor: '#C6EFCE', color: '#006100', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', marginTop: '15px', fontSize: "0.9rem", fontWeight: 'normal', fontStyle: 'italic', width: '180px', float: 'right', textAlign: 'center', height: '1.4rem'}}>
                                                        <span>A great start</span>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (
                                                this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 0 &&
                                                this.props.datasources[this.props.beans[this.props.uiDefinition.activeAgreementsDatasource].datasource].entityList.data.length === 0
                                            ) &&
                                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                                <div style={{
                                                    margin : '15px 5px 5px 5px',
                                                    width: '295px',
                                                    display: 'inline-flex',
                                                    verticalAlign: 'bottom',
                                                    height: '6.6rem'
                                                    }}>

                                                       <div style={{borderRadius: '10px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.6rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                                       >
                                                        <span>You haven't activated your free 7-day trial yet. To activate it, get back to the app timeline screen and press the trial activation button.</span>

                                                      </div>
                                                  </div>
                                              </div>

                                        }
                                        {
                                            (
                                                this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length > 0 ||
                                                this.props.datasources[this.props.beans[this.props.uiDefinition.activeAgreementsDatasource].datasource].entityList.data.length > 0
                                            ) &&
                                         <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                            <div style={{
                                                margin : '15px 5px 5px 5px',
                                                width: '295px',
                                                display: 'inline-flex',
                                                verticalAlign: 'bottom',
                                                height: '6.6rem'
                                                }}>

                                                   <div style={{cursor: 'pointer', borderRadius: '10px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.6rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                                     onClick={
                                                         (e) => {
                                                            this.setState(
                                                                {
                                                                    ...this.state,
                                                                    performingTrialExtension: true
                                                                }
                                                            );
                                                            const b2Event = {
                                                                type: 'extendTrial',
                                                                callback: function (eventResponse) {
                                                                    if (eventResponse.errors != null && eventResponse.errors.length>0){
                                                                        this.setState(
                                                                            {
                                                                                ...this.state,
                                                                                performingTrialExtension: null
                                                                            }
                                                                        );
                                                                    }else if (eventResponse.infos != null && eventResponse.infos.length > 0){
                                                                        this.setState(
                                                                            {
                                                                                ...this.state,
                                                                                performingTrialExtension: null,
                                                                                displayTrialExtensionConfirmation:true
                                                                            }
                                                                        );
                                                                    }
                                                                }.bind(this),
                                                                eventDetails: {
                                                                    beanId: this.props.bean.id,
                                                                }
                                                            }

                                                            this.props.processB2Event(b2Event);
                                                         }
                                                      }


                                                   >
                                                    <span>Have trial period extension for another 7 days</span>

                                                    <div style={{borderRadius: '10px 10px 0px 0px', backgroundColor: '#FFEB9C', color: '#9C5700', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '5px', marginTop: '15px', fontSize: "0.9rem", fontWeight: 'normal', width: '100%', textAlign: 'center', height: '1.6rem', lineHeight: '1.4rem'}}>
                                                      <span>3 months offer will not avail next time</span>
                                                    </div>
                                                  </div>
                                              </div>
                                          </div>
                                      }
                                        <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                            <Link to="/app/b2/driversdashboard" title="Back">
                                                <div style={{cursor: 'pointer', backgroundImage: 'linear-gradient(#55739E, black)', marginTop: '20px', display: 'inline-flex', borderRadius: '1.75rem', backgroundColor: "rgba(126, 170, 235, 1.0)", width: '3.5rem', height: '3.5rem'}} className=" align-items-center justify-content-center">
                                                    <img src={require("assets/images/close.png")} style={{width:"25px"}} alt="Back" title="Back"/>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </CardBox>
                            }
                        </div>
                    <div key={this.props.uiDefinition.id+'_offercard_dev3'} id={this.props.uiDefinition.id+'_offercard_dev3'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
               </div>
            </React.Fragment>
        }

    getNoMoreTrialCard() {
        return <React.Fragment>
                    <div key={this.props.uiDefinition.id+'_offercard_dev1'} id={this.props.uiDefinition.id+'_offercard_dev'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                    </div>
                    <div key={this.props.uiDefinition.id+'_offercard_dev2'} id={this.props.uiDefinition.id+'_offercard_dev2'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                        <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px", backgroundColor: "#F9F9F9" }}>
                            <div className="row" noValidate autoComplete="off">

                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin : '30px 0px 5px 3px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      height: '2.0rem'
                                      }}>
                                        <div style={{fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '1.1rem', color: "#003077"}}>
                                          <span>Subscription Offers:</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                    margin: '5px',
                                    padding: '2px',
                                    width: '295px',
                                    display: 'inline-flex',
                                    verticalAlign: 'bottom',
                                    borderRadius: '10px',
                                    backgroundImage: 'linear-gradient(#55739E, black)',
                                    height: '7.9rem'
                                    }}>


                                       <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #55739E)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '7.65rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                  ...this.state,
                                                                  displayInitSubscriptionChoosePeriod: true
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span>Purchase a subscription at a special price choosing from different periods</span>

                                          <div style={{borderRadius: '10px 0px 10px 10px', backgroundColor: '#C6EFCE', color: '#006100', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', marginTop: '38px', fontSize: "1.0rem", fontWeight: 'bold', width: '180px', float: 'right', textAlign: 'center', height: '1.4rem'}}>
                                            <span>Our Recommendation</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{
                                        margin : '15px 5px 5px 5px',
                                        width: '295px',
                                        display: 'inline-flex',
                                        verticalAlign: 'bottom',
                                        height: '7.9rem'
                                        }}>

                                       <div style={{cursor: 'pointer', borderRadius: '10px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '7.9rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                             onClick={
                                                (e) => {
                                                    this.setState(
                                                        {
                                                            ...this.state,
                                                            displayInitSubscriptionChoosePeriod: null,
                                                            displayInitSubscriptionChoosePackage: null,
                                                            displayInitSubscriptionDigitalAssistantPackage: true
                                                        }
                                                    )
                                                }
                                             }

                                           >
                                            <span>Use Safee as a digital assistant only and keep your paper logbook as the legal record</span>

                                            <div style={{borderRadius: '10px 10px 0px 0px', backgroundColor: '#FFEB9C', color: '#9C5700', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '5px', marginTop: '15px', fontSize: "0.9rem", fontWeight: 'normal', width: '100%', textAlign: 'center', height: '1.6rem', lineHeight: '1.4rem'}}>
                                              <span>It only costs $2 a week ex GST</span>
                                            </div>
                                          </div>
                                      </div>
                                  </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <Link to="/app/b2/driversdashboard" title="Back">
                                        <div style={{cursor: 'pointer',  backgroundImage: 'linear-gradient(#55739E, black)', marginTop: '20px', display: 'inline-flex', borderRadius: '1.75rem', backgroundColor: "rgba(126, 170, 235, 1.0)", width: '3.5rem', height: '3.5rem'}} className=" align-items-center justify-content-center">
                                            <img src={require("assets/images/close.png")} style={{width:"25px"}} alt="Back" title="Back"/>
                                        </div>
                                    </Link>
                                </div>

                            </div>

                       </CardBox>
                    </div>
                <div key={this.props.uiDefinition.id+'_offercard_dev3'} id={this.props.uiDefinition.id+'_offercard_dev3'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
           </div>
        </React.Fragment>
    }

    getInitSubscriptionChoosePeriodCard(){
        return <React.Fragment>
                    <div key={this.props.uiDefinition.id+'_InitSubscriptionChoosePeriod_dev1'} id={this.props.uiDefinition.id+'_InitSubscriptionChoosePeriod_dev'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                    </div>
                    <div key={this.props.uiDefinition.id+'_InitSubscriptionChoosePeriod_dev2'} id={this.props.uiDefinition.id+'_InitSubscriptionChoosePeriod_dev2'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                        <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px", backgroundColor: "#F9F9F9" }}>
                            <div className="row" noValidate autoComplete="off">

                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin : '30px 0px 5px 3px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      height: '3.0rem'
                                      }}>
                                        <div style={{fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '3rem', color: "#003077"}}>
                                          <span>Choose the period you’d like:</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{
                                      margin: '5px',
                                      padding: '2px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      borderRadius: '10px',
                                      backgroundImage: 'linear-gradient(#55739E, black)',
                                      height: '7.05rem'
                                      }}>


                                        <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #405778, #55739E, #E4A722)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.8rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}

                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 12,
                                                                 subscriptionPeriodUnit: 'Month'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>12 Months</span><br/><br/><span>from $8.64 a month</span>

                                          <div style={{borderRadius: '10px 0px 10px 10px', backgroundColor: '#C6EFCE', color: '#006100', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "1.0rem", fontWeight: 'bold', width: '180px', float: 'right', textAlign: 'center', height: '1.4rem'}}>
                                            <span>Set & Forget</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                        margin: '15px 5px 5px 5px',
                                        padding: '1px',
                                        width: '295px',
                                        display: 'inline-flex',
                                        verticalAlign: 'bottom',
                                        borderRadius: '10px',
                                        backgroundImage: 'linear-gradient(#55739E, black)',
                                        height: '7.05rem'
                                        }}>


                                      <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #405778, #55739E, #E4A722)', borderRadius: '9px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.95rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 9,
                                                                 subscriptionPeriodUnit: 'Month'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>9 Months</span><br/><br/><span>from $09.55 a month</span>

                                          <div style={{borderRadius: '10px 10px 10px 0px', backgroundColor: '#C6EFCE', color: '#006100', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "1.0rem", fontWeight: 'normal', width: '180px', float: 'right', textAlign: 'center', height: '1.4rem'}}>
                                            <span>Good Value</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                        margin: '15px 5px 5px 5px',
                                        padding: '1px',
                                        width: '295px',
                                        display: 'inline-flex',
                                        verticalAlign: 'bottom',
                                        borderRadius: '10px',
                                        backgroundImage: 'linear-gradient(#55739E, black)',
                                        height: '7.05rem'
                                        }}>


                                          <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #405778, #55739E, #E4A722)', borderRadius: '9px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.90rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 6,
                                                                 subscriptionPeriodUnit: 'Month'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>6 Months</span><br/><br/><span>from $10.45 a month</span>

                                          <div style={{borderRadius: '10px 10px 10px 0px', backgroundColor: '#C6EFCE', color: '#006100', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "1.0rem", fontWeight: 'normal', width: '180px', float: 'right', textAlign: 'center', height: '1.4rem'}}>
                                            <span>Good Value</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                    margin: '15px 5px 5px 5px',
                                    padding: '0px',
                                    width: '295px',
                                    display: 'inline-flex',
                                    verticalAlign: 'bottom',
                                    borderRadius: '10px',
                                    height: '7.05rem'
                                    }}>

                                      <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #55739E)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '7.05rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 3,
                                                                 subscriptionPeriodUnit: 'Month'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>3 Months</span><br/><br/><span>from $11.36 a month</span>

                                          <div style={{borderRadius: '0px 0px 10px 10px', backgroundColor: '#FFEB9C', color: '#9C5700', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "0.8rem", fontWeight: 'normal', width: '100%', textAlign: 'center', height: '1.4rem', lineHeight: '1.4rem'}}>
                                            <span>We’re sorry you passed it when offered at $7</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin: '15px 5px 5px 5px',
                                      padding: '0px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      borderRadius: '10px',
                                      height: '7.05rem'
                                      }}>

                                        <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #55739E)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '7.05rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 1,
                                                                 subscriptionPeriodUnit: 'Month'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>1 Month</span><br/><br/><span>from $11.82 a month</span>

                                          <div style={{borderRadius: '0px 0px 10px 10px', backgroundColor: '#FFEB9C', color: '#9C5700', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "0.8rem", fontWeight: 'normal', width: '100%', textAlign: 'center', height: '1.4rem', lineHeight: '1.4rem'}}>
                                            <span>Not the best use of the bank</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{
                                        margin: '15px 5px 5px 5px',
                                        padding: '0px',
                                        width: '295px',
                                        display: 'inline-flex',
                                        verticalAlign: 'bottom',
                                        borderRadius: '10px',
                                        height: '7.05rem'
                                    }}>

                                      <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #55739E)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '7.05rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 14,
                                                                 subscriptionPeriodUnit: 'Day'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>2 Weeks</span><br/><br/><span>from $2.73 a week</span>

                                          <div style={{borderRadius: '0px 0px 10px 10px', backgroundColor: '#FFEB9C', color: '#9C5700', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "0.8rem", fontWeight: 'normal', width: '100%', textAlign: 'center', height: '1.4rem', lineHeight: '1.4rem'}}>
                                            <span>For those who do FIFO</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{
                                        margin: '15px 5px 5px 5px',
                                        padding: '0px',
                                        width: '295px',
                                        display: 'inline-flex',
                                        verticalAlign: 'bottom',
                                        borderRadius: '10px',
                                        height: '7.05rem'
                                    }}>

                                      <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #55739E)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '7.05rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 7,
                                                                 subscriptionPeriodUnit: 'Day'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>1 Week</span><br/><br/><span>from $3.18 a week</span>

                                          <div style={{borderRadius: '0px 0px 10px 10px', backgroundColor: '#FFEB9C', color: '#9C5700', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "0.8rem", fontWeight: 'normal', width: '100%', textAlign: 'center', height: '1.4rem', lineHeight: '1.4rem'}}>
                                            <span>For your occasional 1-week run</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{
                                        margin: '15px 5px 5px 5px',
                                        padding: '0px',
                                        width: '295px',
                                        display: 'inline-flex',
                                        verticalAlign: 'bottom',
                                        borderRadius: '10px',
                                        height: '7.05rem'
                                    }}>

                                      <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(black, #55739E)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '7.05rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}
                                            onClick={
                                                     (e) => {
                                                         this.setState(
                                                             {
                                                                 ...this.state,
                                                                 displayInitSubscriptionChoosePeriod: null,
                                                                 displayInitSubscriptionChoosePackage: true,
                                                                 displayInitSubscriptionDigitalAssistantPackage: null,
                                                                 subscriptionPeriod: 2,
                                                                 subscriptionPeriodUnit: 'Day'
                                                             }
                                                         )
                                                     }
                                                  }
                                         >
                                          <span style={{fontWeight: 'bold'}}>2 Days</span><br/><br/><span>from $0.50 a day</span>

                                          <div style={{borderRadius: '0px 0px 10px 10px', backgroundColor: '#FFEB9C', color: '#9C5700', lineHeight: '1.4rem', verticalAlign: 'middle', padding: '0px 5px', fontSize: "0.8rem", fontWeight: 'normal', width: '100%', textAlign: 'center', height: '1.4rem', lineHeight: '1.4rem'}}>
                                            <span>For your occasional regional run</span>
                                          </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{cursor: 'pointer',  backgroundImage: 'linear-gradient(#55739E, black)', marginTop: '20px', display: 'inline-flex', borderRadius: '1.75rem', backgroundColor: "rgba(126, 170, 235, 1.0)", width: '3.5rem', height: '3.5rem'}} className=" align-items-center justify-content-center"
                                        onClick={
                                                (e) => {
                                                     this.setState(
                                                         {
                                                            ...this.state,
                                                            displayInitSubscriptionChoosePeriod: null,
                                                            displayInitSubscriptionChoosePackage: null,
                                                            displayInitSubscriptionDigitalAssistantPackage: null,
                                                            subscriptionPeriod: null,
                                                            subscriptionPeriodUnit: null
                                                         }
                                                     )
                                                 }
                                            }
                                    >
                                        <img src={require("assets/images/backArrow.png")} style={{width:"25px"}} alt="Back" title="Back"/>
                                    </div>
                                </div>
                            </div>

                       </CardBox>
                    </div>
                <div key={this.props.uiDefinition.id+'_InitSubscriptionChoosePeriod_dev3'} id={this.props.uiDefinition.id+'_InitSubscriptionChoosePeriod_dev3'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
           </div>
        </React.Fragment>
    }

    getInitSubscriptionPrice(pkg){
        if (pkg === 'Accredited EWD'){
            if (this.state.subscriptionPeriod === 12 && this.state.subscriptionPeriodUnit==='Month'){
                return 9.5;
            } else if (this.state.subscriptionPeriod === 9 && this.state.subscriptionPeriodUnit==='Month'){
                return 10.5;
            } else if (this.state.subscriptionPeriod === 6 && this.state.subscriptionPeriodUnit==='Month'){
                return 11.5;
            } else if (this.state.subscriptionPeriod === 3 && this.state.subscriptionPeriodUnit==='Month'){
                return 12.5;
            } else if (this.state.subscriptionPeriod === 1 && this.state.subscriptionPeriodUnit==='Month'){
                return 13.0;
            } else if (this.state.subscriptionPeriod === 14 && this.state.subscriptionPeriodUnit==='Day'){
                return 6.0;
            } else if (this.state.subscriptionPeriod === 7 && this.state.subscriptionPeriodUnit==='Day'){
                return 3.5;
            } else if (this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit==='Day'){
                return '1.10';
            }
        }else if (pkg === 'Accredited EWD+'){
            if (this.state.subscriptionPeriod === 12 && this.state.subscriptionPeriodUnit==='Month'){
                return 11.5;
            } else if (this.state.subscriptionPeriod === 9 && this.state.subscriptionPeriodUnit==='Month'){
                return 12.5;
            } else if (this.state.subscriptionPeriod === 6 && this.state.subscriptionPeriodUnit==='Month'){
                return 13.5;
            } else if (this.state.subscriptionPeriod === 3 && this.state.subscriptionPeriodUnit==='Month'){
                return 14.5;
            } else if (this.state.subscriptionPeriod === 1 && this.state.subscriptionPeriodUnit==='Month'){
                return 16.0;
            } else if (this.state.subscriptionPeriod === 14 && this.state.subscriptionPeriodUnit==='Day'){
                return 8.00;
            } else if (this.state.subscriptionPeriod === 7 && this.state.subscriptionPeriodUnit==='Day'){
                return 4.50;
            } else if (this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit==='Day'){
                return 1.65;
            }
        }

        return 0.0;
    }

    getPackage(pkgName){
        let packages = [...this.props.datasources[this.props.beans[this.props.uiDefinition.offeringsDatasource].datasource].entityList.data[0].relationships['RevPackageNP'].data];

        for (var pkg of packages) {
            if (pkg.attributes.packageName.value === pkgName){
                return pkg;
            }
        }

        return null;
    }

    getSubscriptionPeriodString(){
        if (this.state.subscriptionPeriod === 12 && this.state.subscriptionPeriodUnit==='Month'){
            return '12 Months';
        } else if (this.state.subscriptionPeriod === 9 && this.state.subscriptionPeriodUnit==='Month'){
            return '9 Months';
        } else if (this.state.subscriptionPeriod === 6 && this.state.subscriptionPeriodUnit==='Month'){
            return '6 Months';
        } else if (this.state.subscriptionPeriod === 3 && this.state.subscriptionPeriodUnit==='Month'){
            return '3 Months';
        } else if (this.state.subscriptionPeriod === 1 && this.state.subscriptionPeriodUnit==='Month'){
            return '1 Month';
        } else if (this.state.subscriptionPeriod === 14 && this.state.subscriptionPeriodUnit==='Day'){
            return '2 Weeks';
        } else if (this.state.subscriptionPeriod === 7 && this.state.subscriptionPeriodUnit==='Day'){
            return '1 Week';
        }else if (this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit==='Day'){
            return '2 Days';
        }

        return '';
    }

    getSubscriptionRate(packageName){
        // to be updated to read option rate for period and unit instead of hard codeing it
        if (packageName === 'Accredited EWD'){
            if (this.state.subscriptionPeriod === 12 && this.state.subscriptionPeriodUnit==='Month'){
                return 12 * 9.5;
            } else if (this.state.subscriptionPeriod === 9 && this.state.subscriptionPeriodUnit==='Month'){
                return 9 * 10.5;
            } else if (this.state.subscriptionPeriod === 6 && this.state.subscriptionPeriodUnit==='Month'){
                return 6 * 11.5;
            } else if (this.state.subscriptionPeriod === 3 && this.state.subscriptionPeriodUnit==='Month'){
                return 3 * 12.5;
            } else if (this.state.subscriptionPeriod === 1 && this.state.subscriptionPeriodUnit==='Month'){
                return 13.0;
            } else if (this.state.subscriptionPeriod === 14 && this.state.subscriptionPeriodUnit==='Day'){
                return 6.00;
            } else if (this.state.subscriptionPeriod === 7 && this.state.subscriptionPeriodUnit==='Day'){
                return 3.50;
            } else if (this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit==='Day'){
                return 1.10;
            }
        }else if (packageName === 'Accredited EWD+'){
            if (this.state.subscriptionPeriod === 12 && this.state.subscriptionPeriodUnit==='Month'){
                return 12 * 11.5;
            } else if (this.state.subscriptionPeriod === 9 && this.state.subscriptionPeriodUnit==='Month'){
                return 9 * 12.5;
            } else if (this.state.subscriptionPeriod === 6 && this.state.subscriptionPeriodUnit==='Month'){
                return 6 * 13.5;
            } else if (this.state.subscriptionPeriod === 3 && this.state.subscriptionPeriodUnit==='Month'){
                return 3 * 14.5;
            } else if (this.state.subscriptionPeriod === 1 && this.state.subscriptionPeriodUnit==='Month'){
                return 16;
            } else if (this.state.subscriptionPeriod === 14 && this.state.subscriptionPeriodUnit==='Day'){
                return 8.00;
            } else if (this.state.subscriptionPeriod === 7 && this.state.subscriptionPeriodUnit==='Day'){
                return 4.50;
            } else if (this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit==='Day'){
                return 1.65;
            }
        }else if (packageName === 'Digital Assistant'){
            return 2.2;
        }

        return 0.0;
    }

    getInitSubscriptionChoosePackageCard(){
        return <React.Fragment>
                    <div key={this.props.uiDefinition.id+'_InitSubscriptionChoosePackage_dev1'} id={this.props.uiDefinition.id+'_InitSubscriptionChoosePackage_dev'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                    </div>
                    <div key={this.props.uiDefinition.id+'_InitSubscriptionChoosePackage_dev2'} id={this.props.uiDefinition.id+'_InitSubscriptionChoosePackage_dev2'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                        <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px", backgroundColor: "#F9F9F9" }}>
                            <div className="row" noValidate autoComplete="off">

                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin : '30px 0px 5px 3px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      height: '3.0rem'
                                      }}>
                                        <div style={{fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '3rem', color: "#003077"}}>
                                          <span>Choose the package:</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                    margin: '5px',
                                    padding: '2px',
                                    width: '295px',
                                    display: 'inline-flex',
                                    verticalAlign: 'bottom',
                                    borderRadius: '10px',
                                    backgroundImage: 'linear-gradient(to right, black, #F5A600)',
                                    height: '6.6rem'
                                    }}>


                                       <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(#55739E, black)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'right', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.35rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}>

                                          <span style={{fontWeight: 'bold'}}>{this.getSubscriptionPeriodString()}</span><br/><br/>

                                          <div style={{borderRadius: '1.151rem', textShadow: '2px 2px 8px black', backgroundImage: 'radial-gradient(ellipse at top, rgba(256, 256, 256, 0.75), rgb(126, 170, 235))', color: 'white', lineHeight: '1.15rem', verticalAlign: 'middle', padding: '0px 12px', fontSize: "0.9rem", fontWeight: 'bold', width: '125px', float: 'left', textAlign: 'right', height: '2.3rem'}}

                                            onClick={
                                                (e) => {
                                                     this.setState(
                                                         {
                                                            ...this.state,
                                                            displayInitSubscriptionChoosePeriod: null,
                                                            displayInitSubscriptionChoosePackage: null,
                                                            displayInitSubscriptionDigitalAssistantPackage: null,
                                                            pkg: this.getPackage('Accredited EWD'),
                                                            subscriptionRate: this.getSubscriptionRate('Accredited EWD')
                                                         }
                                                     )
                                                 }
                                            }
                                          >
                                            <div><span>EWD</span></div>
                                            <div><span style={{fontWeight: 'normal'}}>${(this.getInitSubscriptionPrice('Accredited EWD')-this.getInitSubscriptionPrice('Accredited EWD')/11).toFixed(2)}
                                                {
                                                    (this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit === 'Day') ||
                                                    (this.state.subscriptionPeriod === 7 && this.state.subscriptionPeriodUnit === 'Day') ||
                                                    (this.state.subscriptionPeriod === 14 && this.state.subscriptionPeriodUnit === 'Day')
                                                    ?
                                                    '':
                                                    ' a month'
                                                }
                                                </span></div>
                                          </div>
                                          <div style={{borderRadius: '1.151rem', textShadow: '2px 2px 8px black', backgroundImage: 'radial-gradient(ellipse at top, rgba(256, 256, 256, 0.75), rgb(126, 170, 235))', color: 'white', lineHeight: '1.15rem', verticalAlign: 'middle', padding: '0px 12px', fontSize: "0.9rem", fontWeight: 'bold', width: '125px', float: 'right', textAlign: 'right', height: '2.3rem'}}
                                            onClick={
                                                (e) => {
                                                    this.setState(
                                                         {
                                                            ...this.state,
                                                            displayInitSubscriptionChoosePeriod: null,
                                                            displayInitSubscriptionChoosePackage: null,
                                                            displayInitSubscriptionDigitalAssistantPackage: null,
                                                            pkg: this.getPackage('Accredited EWD+'),
                                                            subscriptionRate: this.getSubscriptionRate('Accredited EWD+')
                                                         }
                                                    )
                                                }
                                            }
                                          >
                                            <div><span>EWD</span><span style={{verticalAlign: 'super', lineHeight: '0.6rem', fontSize: '0.6rem'}}>+</span></div>
                                            <div><span style={{fontWeight: 'normal'}}>${(this.getInitSubscriptionPrice('Accredited EWD+')-this.getInitSubscriptionPrice('Accredited EWD+')/11).toFixed(2)}
                                                {
                                                    (this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit === 'Day') ||
                                                    (this.state.subscriptionPeriod === 7 && this.state.subscriptionPeriodUnit === 'Day') ||
                                                    (this.state.subscriptionPeriod === 14 && this.state.subscriptionPeriodUnit === 'Day')
                                                    ?
                                                    '':
                                                    ' a month'
                                                }</span></div>
                                          </div>
                                      </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       borderRadius: '10px 10px 0px 0px',
                                       margin : '30px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <span></span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <span>EWD</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <span>EWD</span><span style={{verticalAlign: 'super', lineHeight: '0.6rem', fontSize: '0.6rem'}}>+</span>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Legal Logbook, No Paper Needed</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Work & Rest Recording</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Future Rest Notifications</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Activity Monitor</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>EWD Fatigue Management Rules</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>WWD Fatigue Management Rules</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Record Keeper Appointment</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.78rem'}}>
                                           <span>Accreditation Docs logging & upload</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin : '4px 7px 0px 7px',
                                      backgroundColor: '#C6EFCE',
                                      color: '#006100',
                                      width: '295px',
                                      display: 'inline-flex',
                                      height: '20px', lineHeight: '20px',
                                      verticalAlign: 'middle'
                                      }}>
                                      <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                          <span>Vehicle Rego logging</span>
                                      </div>
                                      <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                      <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                         <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                     </div>
                                  </div>
                              </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>1-Minute Input Style</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>15-Minute Input Style</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Email Work sheets</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Record Keeper Web Access</span>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                       </div>
                                       <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                          <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                      </div>
                                   </div>
                               </div>

                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                      <div style={{
                                          margin : '4px 7px 0px 7px',
                                          backgroundColor: '#C6EFCE',
                                          color: '#006100',
                                          width: '295px',
                                          display: 'inline-flex',
                                          height: '20px', lineHeight: '20px',
                                          verticalAlign: 'middle'
                                          }}>
                                          <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                              <span>Interception Compliance View</span>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                              <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                             <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                         </div>
                                      </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                      <div style={{
                                          margin : '4px 7px 0px 7px',
                                          backgroundColor: '#C6EFCE',
                                          color: '#006100',
                                          width: '295px',
                                          display: 'inline-flex',
                                          height: '20px', lineHeight: '20px',
                                          verticalAlign: 'middle'
                                          }}>
                                          <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                              <span>Create Comments on Timeline</span>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                              <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                             <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                         </div>
                                      </div>
                                </div>
                                 <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                      <div style={{
                                          margin : '4px 7px 0px 7px',
                                          backgroundColor: '#C6EFCE',
                                          color: '#006100',
                                          width: '295px',
                                          display: 'inline-flex',
                                          height: '20px', lineHeight: '20px',
                                          verticalAlign: 'middle'
                                          }}>
                                          <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                              <span>CoR Photo Notes</span>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                              <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                             <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                         </div>
                                      </div>
                                </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                      <div style={{
                                          margin : '4px 7px 0px 7px',
                                          borderRadius: '0px 0px 10px 10px',
                                          backgroundColor: '#C6EFCE',
                                          color: '#006100',
                                          width: '295px',
                                          display: 'inline-flex',
                                          height: '20px', lineHeight: '20px',
                                          verticalAlign: 'middle'
                                          }}>
                                          <div style={{width: '205px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                              <span>Event Submission</span>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                              <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                          </div>
                                          <div style={{width: '45px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                             <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                         </div>
                                      </div>
                                </div>



                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{cursor: 'pointer', backgroundImage: 'linear-gradient(#55739E, black)', marginTop: '20px', display: 'inline-flex', borderRadius: '1.75rem', backgroundColor: "rgba(126, 170, 235, 1.0)", width: '3.5rem', height: '3.5rem'}} className=" align-items-center justify-content-center"
                                        onClick={
                                                (e) => {
                                                     this.setState(
                                                         {
                                                            ...this.state,
                                                            displayInitSubscriptionChoosePeriod: true,
                                                            displayInitSubscriptionChoosePackage: null,
                                                            displayInitSubscriptionDigitalAssistantPackage: null,
                                                            subscriptionPeriod: null,
                                                            subscriptionPeriodUnit: null
                                                         }
                                                     )
                                                 }
                                            }
                                    >
                                        <img src={require("assets/images/backArrow.png")} style={{width:"25px"}} alt="Back" title="Back"/>
                                    </div>
                                </div>
                            </div>

                       </CardBox>
                    </div>
                <div key={this.props.uiDefinition.id+'_InitSubscriptionChoosePackage_dev3'} id={this.props.uiDefinition.id+'_InitSubscriptionChoosePackage_dev3'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
           </div>
        </React.Fragment>
    }

    getInitSubscriptionDigitalAssistantPackageCard(){
        return <React.Fragment>
                    <div key={this.props.uiDefinition.id+'_InitSubscriptionDigitalAssistantPackage_dev1'} id={this.props.uiDefinition.id+'_InitSubscriptionDigitalAssistantPackage_dev'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                    </div>
                    <div key={this.props.uiDefinition.id+'_InitSubscriptionDigitalAssistantPackage_dev2'} id={this.props.uiDefinition.id+'_InitSubscriptionDigitalAssistantPackage_dev2'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                        <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px", backgroundColor: "#F9F9F9" }}>
                            <div className="row" noValidate autoComplete="off">

                               <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                  <div style={{
                                      margin : '30px 0px 5px 3px',
                                      width: '295px',
                                      display: 'inline-flex',
                                      verticalAlign: 'bottom',
                                      height: '1.0rem'
                                      }}>
                                        {/*<div style={{fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'left', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '3rem', color: "#003077"}}>
                                          <span>Choose the package:</span>
                                        </div>*/}
                                    </div>
                                </div>

                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>

                                  <div style={{
                                    margin: '5px',
                                    padding: '2px',
                                    width: '295px',
                                    display: 'inline-flex',
                                    verticalAlign: 'bottom',
                                    borderRadius: '10px',
                                    backgroundImage: 'linear-gradient(to right, black, #F5A600)',
                                    height: '6.6rem'
                                    }}>

                                       <div style={{cursor: "pointer", backgroundImage: 'linear-gradient(#55739E, black)', borderRadius: '8px', fontWeight:'normal', fontStyle:'normal', width: '295px', textAlign: 'center', lineHeight: '1.4rem', verticalAlign: 'bottom', padding: '10px', fontSize: "1.0rem", height: '6.35rem', backgroundColor: "rgba(126, 170, 235, 1.0)", color: "white"}}>

                                          <span style={{fontWeight: 'bold'}}>7 Days</span><br/><br/>
                                            <div style={{textAlign: 'center', width: '100%'}}>
                                                  <div style={{margin: '0 auto', position: 'relative', borderRadius: '1.151rem', textShadow: '2px 2px 8px black', backgroundImage: 'radial-gradient(ellipse at top, rgba(256, 256, 256, 0.75), rgb(126, 170, 235))', color: 'white', lineHeight: '1.15rem', verticalAlign: 'middle', padding: '0px 12px', fontSize: "0.9rem", fontWeight: 'bold', width: '160px', textAlign: 'center', height: '2.3rem'}}

                                                    onClick={
                                                        (e) => {
                                                             this.setState(
                                                                 {
                                                                    ...this.state,
                                                                    displayInitSubscriptionChoosePeriod: null,
                                                                    displayInitSubscriptionChoosePackage: null,
                                                                    displayInitSubscriptionDigitalAssistantPackage: null,
                                                                    pkg: this.getPackage('Digital Assistant'),
                                                                    subscriptionPeriod: 7,
                                                                    subscriptionPeriodUnit: 'Day',
                                                                    subscriptionRate: this.getSubscriptionRate('Digital Assistant')
                                                                 }
                                                             )
                                                         }
                                                    }
                                                  >
                                                    <div><span>Digital Assistant</span></div>
                                                    <div><span style={{fontWeight: 'normal'}}>$2.20 a week</span></div>
                                                  </div>
                                              </div>

                                          {/*
                                          <div style={{borderRadius: '1.151rem', textShadow: '2px 2px 8px black', backgroundImage: 'radial-gradient(ellipse at top, rgba(256, 256, 256, 0.75), rgb(126, 170, 235))', color: 'white', lineHeight: '1.15rem', verticalAlign: 'middle', padding: '0px 12px', fontSize: "0.9rem", fontWeight: 'bold', width: '125px', float: 'right', textAlign: 'right', height: '2.3rem'}}
                                            onClick={
                                                (e) => {
                                                    this.setState(
                                                         {
                                                            ...this.state,
                                                            displayInitSubscriptionChoosePeriod: null,
                                                            displayInitSubscriptionChoosePackage: null,
                                                            displayInitSubscriptionDigitalAssistantPackage: null,
                                                            pkg: this.getPackage('Accredited EWD+'),
                                                            subscriptionRate: this.getSubscriptionRate('Accredited EWD+')
                                                         }
                                                    )
                                                }
                                            }
                                          >
                                            <div><span>EWD</span><span style={{verticalAlign: 'super', lineHeight: '0.6rem', fontSize: '0.6rem'}}>+</span></div>
                                            <div><span style={{fontWeight: 'normal'}}>${this.getInitSubscriptionPrice('Accredited EWD+')}{(this.state.subscriptionPeriod === 2 && this.state.subscriptionPeriodUnit === 'Day')?'':' a month'}</span></div>
                                          </div>
                                          */}
                                      </div>
                                    </div>
                                </div>
                                {
                                /*
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       borderRadius: '10px 10px 0px 0px',
                                       margin : '30px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '180px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingRight: '5px'}}>
                                           <span></span>
                                       </div>
                                       <div style={{width: '115px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px', fontSize: '0.7rem', fontWeight: 'bold', paddingRight: '5px'}}>
                                           <span></span>
                                       </div>
                                   </div>
                               </div>
                               */}
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       borderRadius: '10px 10px 0px 0px',
                                       margin : '10px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Legal Logbook, No Paper Needed</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Work & Rest Recording</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Future Rest Notifications</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Activity Monitor</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>EWD Fatigue Management Rules</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>WWD Fatigue Management Rules</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Record Keeper Appointment</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Accreditation Docs logging & upload</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Vehicle Rego logging</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>1-Minute Input Style</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>15-Minute Input Style</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Email 28-day Work sheets to self</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Record Keeper Web Access</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Interception Compliance View</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Create Comments on Timeline</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/tick.png")} style={{width:"20px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>CoR Photo Notes</span>
                                       </div>
                                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>
                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                   <div style={{
                                       margin : '4px 7px 0px 7px',
                                       borderRadius: '0px 0px 10px 10px',
                                       backgroundColor: '#C6EFCE',
                                       color: '#006100',
                                       width: '295px',
                                       display: 'inline-flex',
                                       height: '20px', lineHeight: '20px',
                                       verticalAlign: 'middle'
                                       }}>
                                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                                           <span>Event Submission</span>
                                       </div>
                                       <div style={{borderRadius: '0px 0px 10px 0px', width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                                           <img src={require("assets/images/hyphen.png")} style={{width:"8px", paddingBottom: '3px'}} alt="Not Supported" title="Supported"/>
                                       </div>
                                   </div>
                               </div>

                                <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                    <div style={{cursor: 'pointer', backgroundImage: 'linear-gradient(#55739E, black)', marginTop: '20px', display: 'inline-flex', borderRadius: '1.75rem', backgroundColor: "rgba(126, 170, 235, 1.0)", width: '3.5rem', height: '3.5rem'}} className=" align-items-center justify-content-center"
                                        onClick={
                                                (e) => {
                                                     this.setState(
                                                         {
                                                            ...this.state,
                                                            displayInitSubscriptionChoosePeriod: null,
                                                            displayInitSubscriptionChoosePackage: null,
                                                            displayInitSubscriptionDigitalAssistantPackage: null,
                                                            subscriptionPeriod: null,
                                                            subscriptionPeriodUnit: null
                                                         }
                                                     )
                                                 }
                                            }
                                    >
                                        <img src={require("assets/images/backArrow.png")} style={{width:"25px"}} alt="Back" title="Back"/>
                                    </div>
                                </div>
                            </div>

                       </CardBox>
                    </div>
                <div key={this.props.uiDefinition.id+'_InitSubscriptionDigitalAssistantPackage_dev3'} id={this.props.uiDefinition.id+'_InitSubscriptionDigitalAssistantPackage_dev3'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
           </div>
        </React.Fragment>
    }

    getPackageCards() {
        let packages = [...this.props.datasources[this.props.beans[this.props.uiDefinition.offeringsDatasource].datasource].entityList.data[0].relationships['RevPackageNP'].data];
        let agreements = this.props.datasources[this.props.beans[this.props.uiDefinition.activeAgreementsDatasource].datasource].entityList.data;

        if (packages !=null && packages.length>0) {
            packages.sort(
                function (p1,p2) {

                    if (agreements != null && agreements.length>0){
                        for (let agreement1 of agreements){

                            if (this.validateAgreement(agreement1) === true){

                                if (agreement1.attributes.packageName.value === p1.attributes.packageName.value){

                                    for (let agreement2 of agreements){
                                        if (agreement1.attributes.customerAgreementId.value !== agreement2.attributes.customerAgreementId.value){
                                            if (agreement2.attributes.packageName.value === p2.attributes.packageName.value) {
                                                return ((p1.attributes.packageWeight.value) > (p2.attributes.packageWeight.value)) ? -1 : 1;
//                                                if ((agreement1.attributes.rate.value) >= (agreement2.attributes.rate.value)) {
//                                                    return -1;
//                                                }else{
//                                                    return 1;
//                                                }
                                            }
                                        }
                                    }

                                    return -1;
                                }
                            }
                        }
                    }

                    return ((p1.attributes.packageWeight.value) > (p2.attributes.packageWeight.value)) ? -1 : 1;
                }.bind(this)
            );
        }

        let cards = [];
        for (let pkg of packages){
            let agreement = null;
            let anyOngoingSubscription=false;

            for (let custAgreement of agreements){
                if (this.validateAgreement(custAgreement)) {
                    anyOngoingSubscription = true;
                    if (custAgreement.attributes.packageName.value === pkg.attributes.packageName.value) {
                        agreement = custAgreement;
                        break
                    }
                }else{
                    if (custAgreement.attributes.packageName.value === pkg.attributes.packageName.value) {
                        break;
                    }
                }
            }

            cards.push(this. getPackageCard(pkg, anyOngoingSubscription, agreement));
        }

        return cards;
    }

    validateAgreement(agreement){
        if (!agreement){
            return false;
        }

        let endDate = new Date(agreement.attributes.endDate.value);

        if (endDate== null){
          return false;
        }

        let today = Date.now();

        if (endDate > today){ // today
          return true;
        }

        endDate = new Date(endDate.setDate(endDate.getDate() + 1));
        endDate.setHours(0,0,0,0);

        if (today < endDate){
            return true;
        }

        return false;
    }

    dateToYMD(date) {

        const monthNames = ["Jan", "Feb", "Mar", "Apr",
                                    "May", "Jun", "Jul", "Aug",
                                    "Sep", "Oct", "Nov", "Dec"];

        const day = date.getDate();

        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];

        const year = date.getFullYear();

        return `${day} ${monthName} ${year}`;
    }

    getOptionString(revPackageSubscriptionOption, agreement){
        if (revPackageSubscriptionOption == null){
            return ""
        }

        let period = revPackageSubscriptionOption.attributes.agreementPeriod.value;

        let periodUnit = revPackageSubscriptionOption.attributes.agreementPeriodUnit.value;

        let rate = revPackageSubscriptionOption.attributes.rate.value - (revPackageSubscriptionOption.attributes.rate.value / 11);

        if (periodUnit.toLowerCase () === 'day' && period === 14){
            return "1 Fortnight for $" + rate.toFixed(2);//  + " a fortnight";
        }else if (periodUnit.toLowerCase () === 'day' && period === 7){
            return "1 Week for $" + + rate.toFixed(2);// + " a week";
        }else if (periodUnit.toLowerCase () === 'day' && (period % 7) == 0){
            return (period/7) + " Week"+ (((period/7) === 1)?'':'s') + " for $" + (Math.ceil((   (rate/(period/7)) * 100).toFixed(3))/100).toFixed(2)  + " a week";
        }else if (periodUnit.toLowerCase() === 'day'){
             return period + " " + periodUnit + ((period===1)?'':'s') + " for $" + rate.toFixed(2);// + " a " + periodUnit.toLowerCase();
         }else if (period === 1){
            return period + " " + periodUnit + " for $" + rate.toFixed(2);// + " a " + periodUnit.toLowerCase();
        }else{
            return period + " " + periodUnit + "s for $" + (Math.ceil((   (rate/period) * 100).toFixed(3))/100).toFixed(2)  + " a " + periodUnit.toLowerCase();
        }
    }

    roundNumber(num, scale) {
      if(!("" + num).includes("e")) {
        return +(Math.round(num + "e+" + scale)  + "e-" + scale);
      } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if(+arr[1] + scale > 0) {
          sig = "+";
        }
        return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
      }
    }

    getSelectItems(pkg){

        pkg.relationships['RevPackageSubscriptionOption'].data.sort(
            (a, b) => {
                if (this.getOptionWeight(a) > this.getOptionWeight(b)) {
                    return -1;
                }

                return 1;
            }
        );

        return pkg.relationships['RevPackageSubscriptionOption'].data.map((option, index) =>
                        <MenuItem optionid={option.attributes.id.value} value={this.getOptionString(option)} key={pkg.attributes.packageName.value+'-option-'+option.attributes.id.value+'-'}>{this.getOptionString(option)}</MenuItem>
                    );
    }

    getOptionWeight(revPackageSubscriptionOption){
        if (revPackageSubscriptionOption.attributes.agreementPeriodUnit.value === 'Day'){
            return revPackageSubscriptionOption.attributes.agreementPeriod.value;
        }else if (revPackageSubscriptionOption.attributes.agreementPeriodUnit.value === 'Month'){
            return revPackageSubscriptionOption.attributes.agreementPeriod.value * 30.41;    //     365/12 so year would be after month
        }else{
            return revPackageSubscriptionOption.attributes.agreementPeriod.value * 365.25;
        }
    }

    getHighestWeightedOption(pkg){
        let options = pkg.relationships['RevPackageSubscriptionOption'].data;

        let highestWeight = null;
        let highestWeightedOption = null;

        for (let i = 0; i < options.length; i++) {
          if (highestWeight == null){
            highestWeight = this.getOptionWeight(options[i]);
            highestWeightedOption = options[i];
          }else{
            let newOptionWeight = this.getOptionWeight(options[i]);
            if (newOptionWeight > highestWeight){
                highestWeight = newOptionWeight;
                highestWeightedOption = options[i];
            }
          }
        }

        return highestWeightedOption;
    }

    getOption(pkg, agreementPeriod, agreementPeriodUnit){
        let options = pkg.relationships['RevPackageSubscriptionOption'].data;

        let highestWeight = null;
        let highestWeightedOption = null;

        for (let i = 0; i < options.length; i++) {
            if (options[i].attributes.agreementPeriod.value == agreementPeriod && options[i].attributes.agreementPeriodUnit.value == agreementPeriodUnit){
                return options[i];
            }
        }

        return null;
    }

    getOptionById(pkg, optionid){
        let options = pkg.relationships['RevPackageSubscriptionOption'].data;

        for (let i = 0; i < options.length; i++) {
            if (options[i].attributes.id.value === optionid){
                return options[i];
            }
        }

        return null;
    }

    getSelectedOption(pkg, agreement){
        if (this.state.selectedOptions != null && this.state.selectedOptions[pkg.attributes.packageName.value] != null){
            return this.state.selectedOptions[pkg.attributes.packageName.value];
        }else{
            if (agreement == null){
                return this.getHighestWeightedOption(pkg);
            }else{
                let option = this.getOption(pkg, agreement.attributes.agreementPeriod.value, agreement.attributes.agreementPeriodUnit.value);
                if (option == null){
                    return this.getHighestWeightedOption(pkg);
                }
                return option;
            }
        }
    }

    getPackageCard(pkg, anyOngoingSubscription, agreement){
        //let agreementPeriod = (pkg==null)?null:parseInt(pkg.attributes.agreementPeriod.value);

        //let periodUnit = (pkg==null)?null:pkg.attributes.agreementPeriodUnit.value;

        //if (agreementPeriod != null && agreementPeriod > 1 && periodUnit != null){
        //    periodUnit += 's';
        //}

        let selectedOption = this.getSelectedOption(pkg, agreement);

        let selectedOptionString = this.getOptionString(selectedOption);

        let rate = (pkg==null)?0:parseFloat(selectedOption.attributes.rate.value);

        let ratePeriod = '';

     /*   if (rate !== 0 && periodUnit != null){

          ratePeriod = '(\$' +
              rate.toStringAsFixed(rate.truncateToDouble() == rate ? 0 : 2) + '/' +
              agreementPeriod.toString() + ' ' + periodUnit + ')';

        }*/

        let buttonText;
        let activeInactiveString="";
        let autoRenew = false;

        if (agreement != null && this.validateAgreement(agreement)){

            activeInactiveString = "Active";

            let endDate = new Date(agreement.attributes.endDate.value);

            let expiryDate = this.dateToYMD(endDate);

            if (agreement.attributes.autoRenew.value != null && agreement.attributes.autoRenew.value){
                buttonText = 'Next Renewal ' + expiryDate;
                autoRenew = true;
            }else if (agreement.attributes.inTrial.value != null && agreement.attributes.inTrial.value) {
                buttonText = 'Free Trial Until ' + expiryDate + ', Subscribe Now';
            }else{
                buttonText = 'Valid through ' + expiryDate;
            }
        }else{
          if (pkg== null){
            buttonText = null;
          }else if (rate === 0){
            //buttonText = 'Stay ' + (package!.packageName??'');
            buttonText = null;//'Stay ' + package.packageName;
          }else {
            buttonText = 'Go ' + (pkg.attributes.packageName.value);
            activeInactiveString = "Inactive";
          }
        }

        return <div key={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_dev'} id={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_dev'} className="col-lg-4 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
                   <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px"}}>
                       <div className="row" noValidate autoComplete="off">
                          {/* <div className="col-md-12 col-12" style={{fontSize: "1.5rem", height: '2.1rem', margin: "15px 7px 0px 7px", color: "#55739E", width: "300px"}}>
                                <div style={{width: "259px"}}>
                                    <span>{pkg.attributes.packageName.value}</span>
                                </div>
                           </div>*/}

                           <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                              <div style={{
                                  margin : '30px 0px 5px 3px',
                                  width: '295px',
                                  display: 'inline-flex',
                                  verticalAlign: 'bottom',
                                  height: '1.1rem'
                                  }}>
                                  <div style={{fontWeight:((pkg.attributes.rate.value === 0)?'normal':'bold'), fontStyle:((pkg.attributes.rate.value === 0)?'normal':'italic'), textAlign: 'left', lineHeight: '20px', verticalAlign: 'bottom', paddingRight: '5px', fontSize: "1.2rem", height: '2.4rem', color: "#55739E"}}>
                                      <span>{pkg.attributes.packageName.value}</span>
                                  </div>
                                  {
                                                                          (
                                                                              (this.validateAgreement(agreement)) ||
                                                                              ((buttonText==null || buttonText==='' || rate === 0) && !anyOngoingSubscription)
                                                                          ) &&
                                  <div style={{width: '120px', textAlign: 'center', height: '1.1rem', lineHeight: '20px', verticalAlign: 'bottom'}}>
                                        <Chip label='Subscribed' className="mx-2" style={{fontSize: "0.9rem", height: '1.1rem', padding: '0px !important', backgroundColor: '#55739E', color: 'white', fontWeight: 'bold', width: '120px'}}/>
                                  </div>
                                  }
                              </div>
                          </div>

                            <div className="col-md-12 col-12" style={{margin: "0px", padding: "0px", lineHeight: "0px", textAlign: "center"}}>
                                 <Chip className="mx-2" style={{height: '3px', margin : '0px 12px 7px 12px', backgroundColor: '#55739E', width: '290px'}}/>
                            </div>
                           {this.getFeatures(pkg)}
                           <div className="col-md-12 col-12" style={{margin: "0px", padding: "0px", lineHeight: "0px", textAlign: "center"}}>

                               <div style={{
                                 margin : '10px 0px 10px 3px',
                                 width: '295px',
                                 display: 'inline-flex',
                                 verticalAlign: 'bottom',
                                 height: '2.4rem'
                                 }}>
                                      <FormControl
                                          margin="dense"
                                          variant="outlined"
                                          required={false}
                                          disabled={false}
                                          //style={style}
                                          size="small"
                                          fullWidth
                                          autoComplete='new-password'
                                          >
                                          <InputLabel>Subscription Period</InputLabel>
                                          <Select
                                              //onOpen={/*this.onSelectOpen*/}
                                              value={selectedOptionString}
                                              onChange={
                                                (event, menuItem) => {
                                                    let selectedOptions = this.state.selectedOptions;
                                                    if (selectedOptions == null){
                                                        selectedOptions = [];
                                                    }

                                                    selectedOptions[pkg.attributes.packageName.value] = this.getOptionById(pkg, menuItem.props.optionid);

                                                    this.setState(
                                                        {
                                                            ...this.state,
                                                            selectedOptions: selectedOptions
                                                        }
                                                    );
                                                }
                                              }
                                              label={'Subscription Period'}
                                              inputProps={{ readOnly: false }}
                                          >
                                              {this.getSelectItems(pkg)}
                                          </Select>
                                      </FormControl>
                                 </div>
                           </div>
                           {
                               (anyOngoingSubscription) &&
                              <div className="col-md-12 col-12" style={{textAlign: "center", height: "40px"}}>
                                  <div className="col-md-12 col-12" style={{width: '295px', display: 'inline-flex', padding: "0px", height: "40px"}}>
                                       {
                                           (buttonText != null && rate > 0 && agreement) &&  [<Switch
                                               id={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_autorenewalswitch'}
                                               key={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_autorenewalswitch'}
                                               classes={{
                                                   checked: 'text-primary',
                                                   track:'bg-primary'
                                               }}
                                               checked={autoRenew}
                                               onChange={()=>this.toggleAgreementAutoRenewal(agreement)}

                                          />, <div id={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_autorenewalswitch_dev'}
                                                   key={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_autorenewalswitch_dev'}
                                                   style={{fontWeight: 'bold', textAlign: 'left', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px', paddingTop: '10px'}}>
                                                 <span>Auto Renewal</span>
                                             </div>]
                                      }
                                  </div>
                              </div>
                           }
                           <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                             <Chip label={
                              (buttonText!=null && rate > 0)?
                                  buttonText:
                                  (
                                      (anyOngoingSubscription != null && anyOngoingSubscription)?
                                      (pkg.attributes.packageName.value) + ' activates if no subscription':
                                      ("You are on " + pkg.attributes.packageName.value)
                                  )

                                  }
                              onClick={
                                  ()=>this.goButtonClicked(pkg, agreement, anyOngoingSubscription)
                              }
                              className="mx-2" style={{fontSize: '0.9rem', margin : '12px 7px', backgroundColor: ((buttonText!=null && rate > 0)?'#55739E':'#FFCD00'), fontWeight: 'bold', color: ((buttonText!=null && rate > 0)?'white':'black'), width: '300px'}}/>
                           </div>
                       </div>
                   </CardBox>
               </div>
    }

    getFeatures(pkg){
        let pkgFeatures = pkg.relationships['RevPackageFeatureUserFriendly'].data;
        pkgFeatures.sort(function(p1, p2){
            if (parseInt(p1.attributes.order.value) > parseInt(p2.attributes.order.value)){
                return -1;
            }else{
                return 1;
            }
        });

        let features = [];

        //for (let feature of pkgFeatures){
        for (var i = 0; i < pkgFeatures.length; i++) {
            let icon = (pkgFeatures[i].attributes.description.value === "Yes")?"tick.png":"hyphen.png";
            let iconWidth = (pkgFeatures[i].attributes.description.value === "Yes")?"20px":"8px";
            let alt = (pkgFeatures[i].attributes.description.value === "Yes")?"supported":"not supported";
            let borderRadius = '';
            if (i===0){
                borderRadius = '10px 10px 0px 0px';
            }else if (i===(pkgFeatures.length-1)){
                borderRadius = '0px 0px 10px 10px';
            }else{
                borderRadius = '0px';
            }

            features.push(
                <div className="col-md-12 col-12" style={{textAlign: "center"}} key={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_'+pkgFeatures[i].attributes.id.value+'_dev'} id={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_'+pkgFeatures[i].attributes.id.value+'_dev'}>
                   <div style={{
                       borderRadius: borderRadius,
                       margin : '4px 7px 0px 7px',
                       backgroundColor: '#C6EFCE',
                       color: '#006100',
                       width: '295px',
                       display: 'inline-flex',
                       height: '20px', lineHeight: '20px',
                       verticalAlign: 'middle'
                       }}>
                       <div style={{width: '240px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', fontSize: '0.875rem'}}>
                           <span>{pkgFeatures[i].attributes.featureName.value}</span>
                       </div>
                       <div style={{width: '55px', textAlign: 'center', height: '20px', lineHeight: '20px', verticalAlign: 'middle'}}>
                           <img src={require("assets/images/"+icon)} style={{width: iconWidth, paddingBottom: '3px'}} alt={alt} title={alt}/>
                       </div>
                   </div>
               </div>
            /*
            <div key={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_'+pkgFeatures[i].attributes.id.value+'_dev'} id={this.props.uiDefinition.id+'_'+pkg.attributes.id.value+'_'+pkgFeatures[i].attributes.id.value+'_dev'} className="col-md-12 col-12" style={{textAlign: "center"}}>
                               <div style={{
                                   margin : '7px 7px 0px 7px',
                                   backgroundColor: '#'+feature.attributes.additionalInfo1.value,
                                   color: '#'+feature.attributes.additionalInfo2.value,
                                   borderRadius: '100em 100em 100em 100em',
                                   width: '295px',
                                   display: 'inline-flex',
                                   height: '20px', lineHeight: '20px',
                                   verticalAlign: 'middle'
                                   }}>
                                   <div style={{width: '185px', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingRight: '5px'}}>
                                       <span>{feature.attributes.featureName.value}</span>
                                   </div>
                                   <div style={{width: '110px', textAlign: 'left', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                                       <span>{feature.attributes.description.value}</span>
                                   </div>
                               </div>
                           </div>
                           */


                           );
        }
        return features;
    }

    render() {

        let agreements = this.props.datasources[this.props.beans[this.props.uiDefinition.activeAgreementsDatasource].datasource].entityList.data;

        if (this.props.user.attributes.status.value.toLowerCase() === 'new'){
                return this.getNotActivatedCard();
        }else{
            if (this.state.displayTrialExtensionConfirmation){
                return this.getTrialExtendedSuccessfullyCard();
            }else if (
                (agreements == null || agreements.length==0) &&
                (
                    (
                        this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 1 &&
                        !this.state.displayInitSubscriptionChoosePeriod && !this.state.displayInitSubscriptionChoosePackage && !this.state.displayInitSubscriptionDigitalAssistantPackage && !this.state.pkg
                    ) ||
                    (
                        this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 0 &&
                        (agreements == null || agreements.length === 0)
                    )
                )
            ){
                return this.getOfferCard();
            }else if (
                (agreements == null || agreements.length==0) &&
                this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 2 &&
                (
                    this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[0].attributes.status.value==='Active' ||
                    this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[1].attributes.status.value==='Active'
                ) &&
                !this.state.displayInitSubscriptionChoosePeriod &&
                !this.state.displayInitSubscriptionChoosePackage &&
                !this.state.displayInitSubscriptionDigitalAssistantPackage &&
                !this.state.pkg
            ){
                return this.getNoMoreTrialCard();
            }else if (
                (agreements == null || agreements.length==0) &&
                (
                    this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 1 ||
                    (
                        this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 2 &&
                        (
                            this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[0].attributes.status.value==='Active' ||
                            this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[1].attributes.status.value==='Active'
                        )
                     )
                ) &&
                 this.state.displayInitSubscriptionChoosePeriod && !this.state.pkg
             ){
                 return this.getInitSubscriptionChoosePeriodCard();
             }else if (
                 (agreements == null || agreements.length==0) &&
                 (
                     this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 1 ||
                     (
                         this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 2 &&
                         (
                             this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[0].attributes.status.value==='Active' ||
                             this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[1].attributes.status.value==='Active'
                         )
                      )
                 ) &&
                 this.state.displayInitSubscriptionChoosePackage && !this.state.pkg
             ){
                 return this.getInitSubscriptionChoosePackageCard();
             }else if (
                (agreements == null || agreements.length==0) &&
                (
                    this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 1 ||
                    (
                        this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data.length === 2 &&
                        (
                            this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[0].attributes.status.value==='Active' ||
                            this.props.datasources[this.props.beans[this.props.uiDefinition.trialAgreementsDatasource].datasource].entityList.data[1].attributes.status.value==='Active'
                        )
                     )
                ) &&
                this.state.displayInitSubscriptionDigitalAssistantPackage && !this.state.pkg
            ){
                return this.getInitSubscriptionDigitalAssistantPackageCard();
             } else if (this.state.pkg == null){
                return(
                <React.Fragment>
                    {this.getPackageCards()}
                </React.Fragment>
                );
            }else if (this.state.loadPayment){
                return (
                    <SquarePayment
                        rate={this.getRate().toFixed(2)}
                        currency={this.state.pkg.attributes.currency.value}
                        packageName={this.state.pkg.attributes.packageName.value}
                        cancelSubscriptionFn={this.cancelSubscription.bind(this)}
                        completePaymentFn={this.completePayment.bind(this)}
                        finalisePaymentFn={this.finalisePayment.bind(this)}
                    ></SquarePayment>);
            }else{
                return(
                    <React.Fragment>
                        {this.getSubscriptionCard()}
                    </React.Fragment>
                );
            }
        }
    }
}

class SquarePayment extends React.Component {

    constructor(props) {
      super(props);
      // Don't call this.setState() here!
      this.state = { performingPayment: false };

    }

    static appId = config.appId;
    static locationId = config.locationId;

    async componentDidMount () {
        await this.initialiseSquare();
    }

    componentWillUnmount () {
        let allsuspects=document.getElementsByTagName("script");
        for (let i=allsuspects.length; i>=0; i--){
            if (allsuspects[i] && allsuspects[i].getAttribute("src")!==null && allsuspects[i].getAttribute("src").indexOf(this.scriptPath) !== -1 ){
                   allsuspects[i].parentNode.removeChild(allsuspects[i])
            }
        }
    }

    async initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container');

        return card;
    }

//    async createPayment(token) {
//        const body = JSON.stringify({
//          locationId: this.locationId,
//          sourceId: token,
//        });
//
//        const paymentResponse = await fetch('/payment', {
//          method: 'POST',
//          headers: {
//            'Content-Type': 'application/json',
//          },
//          body,
//        });
//
//        if (paymentResponse.ok) {
//          return paymentResponse.json();
//        }
//
//        const errorBody = await paymentResponse.text();
//        throw new Error(errorBody);
//    }

    async tokenize(paymentMethod) {
        const tokenResult = await paymentMethod.tokenize();
            if (tokenResult.status === 'OK') {
                return tokenResult.token;
            } else {
                let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                if (tokenResult.errors) {
                    errorMessage += ` and errors: ${JSON.stringify(
                        tokenResult.errors
                    )}`;
            }

            throw new Error(errorMessage);
        }
    }

    displayLoadingError(error) {
        const statusContainer = document.getElementById(
          'payment-status-container'
        );

        if (statusContainer == null || typeof statusContainer === "undefined"){
            return;
        }

        statusContainer.style="margin: 7px 7px 0px 7px; font-weight: bold; width: 295px; display: inline; vertical-align: middle; text-align: center; font-size: 1.3rem; color: red";
        statusContainer.innerHTML = '<span>Unable to load payment form</span>';
    }

    displayPaymentError() {
        const statusContainer = document.getElementById(
          'payment-status-container'
        );

        if (statusContainer == null || typeof statusContainer === "undefined"){
            return;
        }

        statusContainer.style="margin: 7px 7px 0px 7px; font-weight: bold; width: 295px; display: inline; vertical-align: middle; text-align: center; font-size: 1.3rem; color: red";
        statusContainer.innerHTML = '<span>Unable to complete payment</span>';
    }

    async initialiseSquare () {
        if (!window.Square) {

            const cardButton = document.getElementById('card-container');

            this.displayLoadingError(new Error('Unable to build Square object'));
            return;

            //throw new Error('Square.js failed to load properly');
        }

        let payments;
        try {
            payments = window.Square.payments(SquarePayment.appId, SquarePayment.locationId);
        } catch (e) {
            this.displayLoadingError(e);
//            const statusContainer = document.getElementById(
//                'payment-status-container'
//            );
//            statusContainer.className = 'missing-credentials';
//            statusContainer.style.visibility = 'visible';
            return;
        }

        let card;
        try {
            card = await this.initializeCard(payments);
        } catch (e) {
            this.displayLoadingError(e);
            return;
        }

        // Checkpoint 2.

        const cardButton = document.getElementById('card-button');

        if (cardButton == null || typeof cardButton === "undefined"){
            return;
        }

        cardButton.addEventListener('click', async function (event) {
            event.preventDefault();
            if (!cardButton.disabled){
                cardButton.disabled = true;
                await this.handlePaymentMethodSubmission(event, card);
            }
        }.bind(this));
    }

    async handlePaymentMethodSubmission(event, paymentMethod) {

        try {
            // disable the submit button as we await tokenization and make a payment request.
            const token = await this.tokenize(paymentMethod);
            //const paymentResults = await this.createPayment(token);

            this.setState(
                {
                    ...this.state,
                    performingPayment: true
                }
            );

            this.props.completePaymentFn(token);
            //this.displayPaymentResults('SUCCESS');

        } catch (e) {
            const cardButton = document.getElementById('card-button');
            cardButton.disabled = false;
            //this.displayPaymentError();
            //this.displayPaymentResults('FAILURE');

        }
    }


    render() {
        return(
            <div className="col-md-12 col-12" style={{ padding: "0px 0px", minWidth: "305px"}}>
               <CardBox styleName="col-ls-12" cardInlineStyle={{paddingTop: "10px", paddingLeft: "0px", paddingRight: "0px", minWidth: "330px", margin: "5px"}}>
                   <div className="row" noValidate autoComplete="off">
                        <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                            <div style={{
                                   margin: "15px 7px 0px 7px",
                                   width: '295px',
                                   display: 'inline',
                                   height: '5rem', lineHeight: '5rem',
                                   verticalAlign: 'middle',
                                   textAlign: 'left',
                                   fontSize: '1.5rem',
                                    color: '#55739E',
                                    height: '2.1rem'
                               }}>
                                <span>{this.props.packageName}</span>
                            </div>
                        </div>
                        <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                            <div style={{
                                  margin : '7px 7px 0px 7px',
                                  fontWeight: 'bold',
                                  width: '295px',
                                  display: 'inline-grid',
                                  verticalAlign: 'middle',
                                  textAlign: 'left',
                                  paddingBottom : '20px',
                                  paddingTop : '10px'
                                  }}>
                                <span>
                                    {
                                        (!this.state.performingPayment)?
                                        'Please provide payment details in the below form':
                                        'Processing payment, please wait ...'
                                    }
                                </span>
                            </div>
                        </div>

                        {
                            (this.state.performingPayment) &&
                             <div className="col-md-12 col-12" style={{textAlign: "center", height: "100px"}}>
                                    <CircularProgress />
                            </div>
                        }
                        {
                            (!this.state.performingPayment) &&
                            <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                                <div style={{
                                      margin : '7px 7px 0px 7px',

                                      width: '295px',
                                      display: 'inline',

                                      verticalAlign: 'middle',
                                      textAlign: 'center'
                                      }}>
                                    <div id="card-container" style={{textAlign: "center", width: "330px", display: "inline-flex"}}></div>
                                </div>
                            </div>
                        }
                        <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                            <div>
                                <div id="payment-status-container"></div>
                            </div>
                        </div>
                      <div className="col-md-12 col-12" style={{textAlign: "center"}}>
                         <div style={{
                             margin : '5px 7px 30px 7px',
                             fontWeight: 'bold',
                             borderRadius: '100em 100em 100em 100em',
                             width: '295px',
                             display: 'inline-flex',
                             height: '32px', lineHeight: '32px',
                             verticalAlign: 'middle'
                         }}>
                              <div style={{width: '110px', fontSize: '0.9rem', width: '50%', textAlign: 'right', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingRight: '5px'}}>
                              {
                                  (!this.state.performingPayment) &&
                                  <Chip label='Cancel'
                                      onClick={
                                        () => {
                                            if (this.state.performingPayment){
                                                return;
                                            }
                                            this.props.cancelSubscriptionFn();
                                        }
                                    }
                                      className="mx-2" style={{borderRadius: '100em', height: '32px', lineHeight: '32px', width: '110px', fontSize: '0.9rem', paddingLeft: '15px',  paddingRight: '15px', backgroundColor: '#55739E', fontWeight: 'bold', color: 'white', border: '0px'}}/>
                              }
                              </div>

                              <div style={{width: '50%', textAlign: 'left', height: '20px', lineHeight: '20px', verticalAlign: 'middle', paddingLeft: '5px'}}>
                              {
                                (!this.state.performingPayment) &&
                                  <button
                                      id="card-button"
                                      type="button"
                                      className="mx-2"
                                      style={{borderRadius: '100em', height: '32px', lineHeight: '32px', width: '110px', fontSize: '0.9rem', paddingLeft: '15px',  paddingRight: '15px', backgroundColor: '#55739E', fontWeight: 'bold', color: 'white', border: '0px'}}
                                      >Pay{/*' ' + this.props.rate + ' ' + this.props.currency*/}</button>
                              }
                              </div>

                         </div>
                     </div>
                    </div>
                </CardBox>
            </div>
        );
    }
}

export default SafeeSubscription;